import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'

import Header from '../Header/Header';

import ornateImg from './../../../../../assets/images/ornate.svg';

import stories from "../../../../../data/stories";

import 'keen-slider/keen-slider.min.css'
import { useKeenSlider } from 'keen-slider/react'

const TalesWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;

  .content {
    pointer-events: auto;
    width: 100%;
    height: calc(100% - 100px);
    display: flex;
    justify-content: center;
    align-items: center;

    .divider {
      width: 40px;
      margin-bottom: 32px;
    }

    .questions {
      width: 90%;

      .question {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        margin-bottom: 40px;

        h1 {
          font-family: var(--mainFont);
          font-size: 32px;
          margin: 0;
        }

        h2 {
          font-size: 19px;
          font-style: italic;
          margin: 15px 0 15px 0;
        }

        p {
          font-size: 16px;
        }

        video {
          width: 100%;
          height: 56%;
        }
      }
    }
  }

  .footer {
    position: absolute;
    width: 100%;
    height: 60px;
    bottom: 0px;
    justify-content: space-around;
    align-items: center;

    .ornate {
      width: 60px;
    }
  }

  .copy {
    max-height: 250px;
    overflow-y: scroll;
  }
`;

function Tales({ onBackClick }) {
  const [currentSlide, setCurrentSlide] = useState(0);

  const [sliderRef, slider] = useKeenSlider({
    initial: 0,
    slideChanged(s) {
      setCurrentSlide(s.details().relativeSlide)
    },
  });

  useEffect(() => {
    return () => setCurrentSlide(0); 
  }, []);

  return (
    <TalesWrapper
      className="fullscreen"
      as={motion.div}
      initial={{ x: "100%" }}
      animate={{ x: "0%" }}
      exit={{ x: "100%" }}
      transition={{ type: "Tween" }}
    >

      <Header onBackClick={onBackClick} title="Whitlock's Story" />

      <div className="content">
        <div className="questions">
          <div ref={sliderRef} className="keen-slider faq-container">
            {stories.map((data, index) => (
              <div key={index} className="keen-slider__slide question">
                <video controls>
                  <source src={data.videoSrc} type="video/mp4" />
                  Sorry, your browser doesn't support embedded videos.
                </video>

                {slider && (
                  <div className="dots">
                    {[...Array(slider.details().size).keys()].map((idx) => {
                      return (
                        <button
                          key={idx}
                          onClick={() => {
                            slider.moveToSlideRelative(idx)
                          }}
                          className={`dot ${currentSlide === idx ? " active" : ""}`}
                        />
                      )
                    })}
                  </div>
                )}

                <div className='copy'>
                  <h1>{data.title}</h1>
                  <h2>{data.subtitle}</h2>
                  {data.description.map((item, index) => (
                    <p key={index}>
                      {item}
                    </p>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="footer f-col">
        <img className="ornate" src={ornateImg} />
      </div>
    </TalesWrapper>
  )
}

export default Tales
