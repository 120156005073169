import React, { Fragment } from 'react'
import { useForm } from '@unbnd-operations/react-hooks'
import styled from 'styled-components'

import InputField from './InputField'
import CircularButton from '../buttons/CircularButton'

import TickIcon from '../../assets/images/circular-btn-icons/tick.svg'

const FormWrapper = styled.div`
  .inner-form-wrapper {
    padding: 0 2rem;
    position: relative;
  }

  .form-group:nth-last-child(1) {
    margin-top: 2rem;
  }

  .submit {
    margin-top: 14px;
  }
`

function Form({ data, submit, validation, heading, subheading }) {
  const { handleChange, handleSubmit, values, errors } = useForm(
    data.filter(item => item.type !== 'submit')
        .map(item => ({ [item.name]: typeof item.value !== 'undefined' ? item.value : '' }))
        .reduce((prev, curr) => ({ ...prev, ...curr }), {}),
    submit,
    validation
  );

  return (
    <FormWrapper onSubmit={handleSubmit} className="form fullscreen" noValidate>
      <div className='heading-section f-col f-center'>
        <h2 className='heading'>{heading}</h2>
        {subheading && (
          <p className='sub-heading'>{subheading}</p>
        )}
      </div>
      <div className='inner-form-wrapper'>
        {data.map((field, index) => (
          <Fragment key={index}>
            {field.type !== 'dropdown' && field.type !== 'submit' && field.type !== 'textarea' && (
              <InputField
                type={field.type}
                placeholder={field.placeholder}
                name={field.name}
                title={field.title}
                handleChange={handleChange}
                error={errors[field.name]}
                currentValue={values[field.name]}
                className={`${field.className} ${field.borderBottom ? 'border-bottom' : ''}`}
              />
            )}

            {field.type === 'submit' && (
              <CircularButton id={field.id} onClick={handleSubmit} className="submit" type={field.type} variant='red' size={40}>
                <img src={TickIcon} alt="Tick Icon" />
              </CircularButton>
            )}
          </Fragment>
        ))}
      </div>
    </FormWrapper>
  )
}

export default Form
