import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import { AnimatePresence, motion } from 'framer-motion';
import { usePC, useTimeout } from '@unbnd-operations/react-hooks';

import { ConfigContext } from '../../../global/ConfigState'
import { UserContext } from '../../../global/UserState';
import { loaderAnimation } from '../../../animation';

import { validatePhone } from '../../../components/form/Validator';
import { slideInOut } from '../../../animation';

import TFA from '../Experience/SignUp/TFA';

import OverlayWrapper from '../../../components/layout/OverlayWrapper';
import CardTitle from '../../../components/typography/CardTitle';
import CircularOutlineButton from '../../../components/buttons/CircularOutlineButton';
import PaperWrapper from '../../../components/layout/PaperWrapper';
import Form from '../../../components/form/Form';

import ChestIcon from '../../../assets/images/signup/chest.png'
import IsLoading from '../../../components/helpers/IsLoading';
import axios from 'axios';

const LandingWrapper = styled.div`
  background: #ffffff;
  pointer-events: auto;

  .circular-outline-btn {
    font-family: var(--mainFont);
  }
  .content {
    text-align: center;
  }
`;

const PrizeScreen = styled.div`
  .pre-title {
    text-transform: uppercase;
    font-family: var(--mainCondFont);
    font-size: 1.5em;
    margin-bottom: 0;
  }

  .title {
    text-transform: uppercase;
    font-family: var(--altFont);
    font-size: 3.125em;
    margin-bottom: 0;
  }

  .card-title-wrapper {
    padding-top: 72px;
    margin-bottom: 24px;
  }

  .prize-icon {
    margin-bottom: 36px;
    width: 200px;
  }

`;

const StartWrapper = styled.div`
  text-align: center;
  pointer-events: auto;

  .circular-outline-btn {
    margin-top: 5em;
    margin-bottom: 2em;
  }

  .content {
    font-style: italic;
    font-weight: 700;


    p {
      margin-bottom: .25em;
    }

    .continue-btn {
      color: var(--primaryColour);
      font-weight: 600;
    }
  }
`;

const SignUpPrompt = styled.div`

  .sign-in-prompt-wrapper {
    position: relative;
    .paper-body {
      overflow: visible;
      text-align: center;
    }
  }

  .tfa-wrapper {
    .paper-inner-wrapper {
      text-align: center;
    }
  }

  .error-message-auth, .error-message-code {
      font-size: 0.675rem;
      color: var(--primaryColour);
      font-style: italic;
      background-image: none!important;
      font-family: var(--mainFont);
      text-align: center;
    }

  .back-btn {
    position: absolute;
    bottom: -60px;
    left: 50%;
    transform: translateX(-50%);
    font-family: var(--copyFont);
    font-size: 10px;
    font-style: italic;
    text-decoration: underline;
    padding: 1rem;
    background: transparent;
    font-weight: 600;
  }
`;

const WelcomeBack = styled.div`
  align-items: center;
  justify-content: space-around;
  text-align: center;
  padding: 140px 0 70px 0;

  .pre-title,
  .post-title {
    text-transform: uppercase;
    margin: 0;
    font-family: var(--mainFont);
    letter-spacing: 1px;
  }

  .pre-title {
    font-size: 2em;
  }

  .post-title {
    font-size: 1.625em;
  }

  .title {
    text-transform: uppercase;
    margin: 0;
    font-family: var(--altFont);
    font-size: 3.125em;
    color: var(--primaryColour);
  }
`;

const formData = [{
  title: 'Mobile',
  placeholder: "Mobile",
  name: 'mobile',
  type: 'tel',
  value: '',
  borderBottom: true
}, {
  title: 'Submit',
  type: 'submit',
  id: 'signup-submit',
  borderBottom: false
}]

function Landing() {
  const { initialiseAR } = useContext(ConfigContext);
  const { currentUser, showTFA, confirmSignIn, signIn } = useContext(UserContext);

  const [showPrizePrompt, setShowPrizePrompt] = useState(true);
  const [showSignInPrompt, setShowSignInPrompt] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [authMobileErr, setAuthMobileErr] = useState(null);
  const [authCodeErr, setAuthCodeErr] = useState(null)
  const [progress, setProgress] = useState(0)

  usePC({
    event: 'preload:progress',
    callback: progress => {
      setProgress(progress);
    }
  });

  useTimeout(() => {
    setShowPrizePrompt(false);
  }, 4500)

  const handleStartClick = () => {
    initialiseAR();
  }

  const formSubmission = async (data) => {
    setAuthMobileErr(null);
    setIsSubmitting(true);
    try {
      await axios.get(`/auth/phone/${data.mobile}`);
      const error = await signIn(String(data.mobile));

      if (error) {
        setAuthMobileErr(error)
      }

    } catch (error) {
      console.log(error?.response);
      const message = error?.response?.data?.message;
      setAuthMobileErr(message);
    }

    setIsSubmitting(false);
  }

  const submitTFA = async (data) => {
    setIsSubmitting(true);
    const error = await confirmSignIn(data.code);
    if (error) {
      setAuthCodeErr(error)
    }

    setIsSubmitting(false);
  }

  return (
    <LandingWrapper
      as={motion.div}
      initial={false}
      animate={loaderAnimation.visible}
      exit={loaderAnimation.hidden}
      className="fullscreen"
    >
      <OverlayWrapper opacity={0.5}>

        <AnimatePresence>
          {showPrizePrompt && (
            <PrizeScreen
              className="fullscreen f-col f-center"
              as={motion.div}
              initial={loaderAnimation.hidden}
              animate={loaderAnimation.visible}
              exit={loaderAnimation.hidden}
            >
              <CardTitle>
                <h3 className="pre-title">Feast your eyes on</h3>
                <h2 className="title">The Prize</h2>
              </CardTitle>
              <img className="prize-icon" src={ChestIcon} alt="Chest icon" />
              <div className="content">
                <p>Be in with the chance to win thousands of Whitlocks-worthy prizes along the way</p>
              </div>
            </PrizeScreen>
          )}
        </AnimatePresence>

        <AnimatePresence>
          {!showPrizePrompt && (
            <motion.div
              className="fullscreen"
              initial={loaderAnimation.hidden}
              animate={loaderAnimation.visible}
              exit={loaderAnimation.hidden}
            >
            <>
              {/* {currentUser ? (
                <WelcomeBack
                  className="fullscreen f-col f-center"
                >
                  <div>
                    <h3 className="pre-title">Welcome back</h3>
                    <h2 className="title">{currentUser.displayName.split('_')[0]}!</h2>
                    <h3 className="post-title">We knew you couldn't resist us</h3>
                  </div>

                  {progress === 1 && (
                    <CircularOutlineButton
                      size={100}
                      onClick={handleStartClick}
                      variant="black"
                    >
                      Start
                    </CircularOutlineButton>
                  )}
                </WelcomeBack>
              ) : ( */}
                {/* <> */}
                  {/* {showSignInPrompt ? (
                    <SignUpPrompt
                      className="fullscreen"
                    >
                      {isSubmitting && (
                        <IsLoading />
                      )}

                      <AnimatePresence>
                        {showSignInPrompt && !showTFA && (
                          <motion.div
                            className="form-wrapper fullscreen"
                            initial={slideInOut.initial}
                            animate={slideInOut.animateIn}
                            exit={slideInOut.exit}
                          >
                            <PaperWrapper className='sign-in-prompt-wrapper'>
                              <Form
                                heading="Enter your phone number to continue"
                                data={formData}
                                validation={validatePhone}
                                submit={formSubmission}
                              />
                              {authMobileErr && (
                                <span className="error-message-auth">{authMobileErr}</span>
                              )}
                              <button className="back-btn" onClick={() => setShowSignInPrompt(false)}>Back</button>
                            </PaperWrapper>
                          </motion.div>
                        )}
                      </AnimatePresence>

                      <AnimatePresence>
                        {showTFA && (
                          <TFA
                            initial={slideInOut.initial}
                            animate={slideInOut.animateIn}
                            exit={slideInOut.exit}
                            submit={submitTFA}
                            authCodeErr={authCodeErr}
                          />
                        )}
                      </AnimatePresence>

                    </SignUpPrompt>
                  ) : ( */}
                    <StartWrapper
                      className="fullscreen f-col f-center"
                    >
                      <CircularOutlineButton
                        size={100}
                        onClick={handleStartClick}
                        variant="black"
                      >
                        Start
                      </CircularOutlineButton>

                      {/* <div className="content">
                        <p>Aleady started?</p>
                        <p onClick={() => setShowSignInPrompt(true)} className="continue-btn">Click here to continue where you left off</p>
                      </div> */}
                    </StartWrapper>
                  {/* )}
                </>
              )} */}
              </>
            </motion.div>
          )}
        </AnimatePresence>
      </OverlayWrapper>
    </LandingWrapper>
  )
}

export default Landing
