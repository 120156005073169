import React, { useState, useContext } from 'react'

import CircularButton from '../../../../components/buttons/CircularButton'
import { GameContext } from '../../../../global/GameState';

import VolumeFull from './../../../../assets/images/circular-btn-icons/volume-full.svg';
import VolumeMuted from './../../../../assets/images/circular-btn-icons/volume-muted.svg';

function MuteButton() {
  const {toggleMute, audioMuted} = useContext(GameContext)

  return (
    <CircularButton size={30} onClick={toggleMute} className="volume">
      {audioMuted 
        ? <img src={VolumeMuted} alt="Muted" />
        : <img src={VolumeFull} alt="Full" />
      }
    </CircularButton>
  )
}

export default MuteButton
