import React from 'react'
import styled from 'styled-components'

const StyledItem = styled.li`
  font-family: var(--mainFont);
  text-transform: uppercase;
  font-size: 1.125em;
  color: #4D4D4D;
  display: flex;

  svg {
    width: 16px;
    height: 16px;
    margin-right: 1em;
  }

  path {
    fill: #4D4D4D;
  }

  &.completed {
    color: var(--white);

    path {
      fill: var(--primaryColour);
    }
  }
`;

function ChallengeItem({text, completed}) {

  return (
    <StyledItem className={completed ? "completed" : ""}>
      <svg viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M3.53543 0.00195808C5.36753 -0.053027 7.12434 1.05405 7.63824 2.81346C8.115 4.44568 6.97552 5.99093 5.55995 6.93305C4.33546 7.74798 2.76504 7.70925 1.5695 6.85242C0.34816 5.97709 -0.288023 4.48323 0.126466 3.03891C0.590577 1.42167 1.85368 0.0524309 3.53543 0.00195808Z" />
      </svg>
      <span>{text}</span>
    </StyledItem>
  )
}

export default ChallengeItem
