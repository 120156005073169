import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { AnimatePresence, motion } from 'framer-motion'

import { ConfigContext } from '../../global/ConfigState'
import { loaderAnimation } from '../../animation'

import Loading from './Loading/Loading'
import Landing from './Landing/Landing'
import Experience from './Experience/Experience'
import Error from './Error/Error'

import WhitlockLogoWrapper from '../../components/layout/WhitlockLogoWrapper'
import DelicousLogoWrapper from '../../components/layout/DelicousLogoWrapper'

const LogoWrapper = styled.div`
  .whitlocks-wrapper {
    position: absolute;
    left: 0;
    bottom: 1.5em;
    z-index: 5;
  }

  .delicous-wrapper {
    position: absolute;
    left: 0;
    top: 3em;
    z-index: 5;
  }
`;

function Game() {
  const { 
    sceneLoaded, 
    deviceInfo,
    error,
    arStarted,
    setDesktopError
  } = useContext(ConfigContext);
  const [logoDone, setLogoDone] = useState(false);

  
  useEffect(() => {
    if(!deviceInfo) return;

    if(deviceInfo.type === 'desktop') {
      return setDesktopError();
    }

    const urlParams = new URLSearchParams(window.location.search);
    const noAR = urlParams.get('noAR');

    if (noAR !== true) {
      const startScript = document.createElement('script');
      startScript.src = '/scene/__start__.js';
      document.body.appendChild(startScript);
    }    
  }, [deviceInfo]);

  return (
    <>
      {deviceInfo && (
        <>
          {error && <Error />}

          {!arStarted && logoDone && (
            <LogoWrapper
              as={motion.div}
              initial={loaderAnimation.hidden}
              animate={loaderAnimation.visible}
              exit={loaderAnimation.hidden}
            >
              <DelicousLogoWrapper />
              <WhitlockLogoWrapper />
            </LogoWrapper>
          )}

          <AnimatePresence>
            {!sceneLoaded 
              ? ( 
                <Loading 
                  logoDone={logoDone} 
                  completeLogoAnim={() => setLogoDone(true)} 
                /> 
              ) : ( <>
                {arStarted ? (
                  <Experience /> 
                ) : (
                  <Landing />
                )}
              </> )
            }
          </AnimatePresence>
        </>
      )}
    </>
  )
}

export default Game
