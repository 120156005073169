export default [
  { 
    title: "How do I play?", 
    description: "The game will feature two portals, you must follow the stories of Whitlock’s by completing challenges to help the protagonist in each story reach their Delicious Demise. Portal one must be completed before accessing portal two. Instant win prizes will be hidden behind interactions within the game e.g. you can win an instant win prize by clicking on a key in the game."
  },
  { 
    title: "How do I launch the game?", 
    description: "To launch the ‘Portals of Delicious Demise’ game, you must scan the promotional QR code or by entering the url below into your web browser via your mobile phone device to be directed to launch the web AR game.<br/><br/>Deliciousdemise.com"
  },
  {
    title: "What if my camera is not working?", 
    description: "If the camera on your phone does not work, or you do not have a phone with a camera you can access the game by entering the url below into your web browser.<br/><br/>Deliciousdemise.com" 
  },
  {
    title: "Providing access to your camera?", 
    description: "If you have a camera on your phone you will be automatically prompted to give access to allow the Portals of Delicious Demise access to your camera in order to play in AR. If you do not give permission, you will not be able to continue with the experience and will not be able play. "
  },
  { 
    title: "How do I scan the QR code?", 
    description: "<b>On an iPhone:</b><br/>To scan a QR code, open your camera and hold your phone over the QR code so all four corners are visible in the frame. The camera app will automatically recognise the QR code and prompt you to open a web-page at the top of the screen. Click this prompt and the web-page will open. There is a URL below your QR code that you can type directly into your browser to play the experience. <br/><br/><b>On an Android:</b><br/> To scan a QR code, open your camera and hold your phone over the QR code so all four corners are visible in the frame. The camera app will automatically recognise the QR code and prompt you to open a web-page at the top of the screen. Click this prompt and the web-page will open.<br/>If nothing happens, you may have to go to your Settings app and enable QR Code scanning. If QR Code Scanning isn’t an option in your settings, you may not be able to scan the QR code. However, there is a URL below your QR code that you can type directly into your browser to enter the experience."
  },
  { 
    title: "What is a QR code?", 
    description: "A QR code is that black and white patchy square. This acts as a unique barcode that allows you to play the experience."
  },
  { 
    title: "What is the Treasure Chest?", 
    description: "At the beginning of the experience, you will see a treasure chest box. Inside, holds the grand prize that you can win by completing both portals. There will be four numbers hidden within the portals to pick up along the way. Once you have completed both Portals one and two a treasure chest will be revealed, and you must input a 4-digit code to attempt to unlock the treasure chest. The four hidden numbers found will need to be rearranged in order to attempt to unlock the treasure chest, you only have five chances to try and unlock it so use them wisely. The first entrant who successfully unlocks the treasure chest each week, will win the Grand Prize for that week. A different lot of four numbers will be available to find in the game each week and the 4-digit code to unlock the treasure chest will also change each week. If a Grand Prize is not won in a specific week of the promotional period, the prize will roll over to the subsequent week’s Grand Prize. E.g. if week 1 Grand Prize is not won, but the Grand Prize is won in week 2, the week 2 Grand Prize winner will receive $200 rather than $100. If you have already won the Grand Prize you will not be able to win again."
  },
  { 
    title: "What is included in the Treasure Chest Box?", 
    description: "The prize is a larger cash prize, Whitlock’s merchandise and product."
  },
  { 
    title: "What prizes can I win?", 
    description: "Prizes to be won include cash, Whitlock’s merchandise, product and recipe cards."
  },
  { 
    title: "How do I redeem my prize?", 
    description: "If you win, you will be notified by SMS with a link to direct you to a form to fill out your details. Please allow 30 days after submitting your details to receive the prize."
  },
  { 
    title: "How do I receive my cash prize?", 
    description: "If you win a cash prize, you will be notified by SMS with a link to direct you to a form where you will need to fill out your relevant bank details. A cash deposit will be sent directly to your bank account, please allow 30 days after submitting your details to receive the deposit."
  },
  { 
    title: "How many times can I win?", 
    description: "There is no limit to the instant prizes, however you can only register your mobile and email once. <br/> <br/>Only one entrant can win the grand prize each week. "
  },
  { 
    title: "When will I receive my prize?", 
    description: "Please allow 30 days after submitting your redemption form to receive your prize."
  },
  { 
    title: "How do I save the game?", 
    description: "Once you reach your first checkpoint within the game, you will be required to complete a sign-up form for the Promotion with all requested personal details. Signup will allow your game results to be retained next time you access the game via your mobile phone device."
  },
  { 
    title: "How do I get back to the experience?", 
    description: "To return to the experience and continue playing, simply revisit DeliciousDemise.com on the same device you started on."
  },
  { 
    title: "What devices and browsers are supported?",
    description: "WebAR is a new technology and can be processor intensive. It performs best on newer smartphones with more processing power and memory. Prolonged sessions can heat the phone up and drain the battery. For Apple users, we recommend using an iPhone 8 and up running iOS 11+. The experience may perform poorly on some iPhone 8s. For users with Android phones, we recommend Samsung S9 up or other Android equivalent devices. Please note that older phone models will experience a slower frame rate during the experience. Recommended browsers include; Safari, Chrome, Firefox, Samsung Internet and Microsoft Edge.<br/> For detailed compatibility information read more <a href=\"https://www.8thwall.com/docs/web/#web-browser-requirements\" target=\"_blank\" >here</a>."
  },
  { 
    title: "What is Heinz privacy policy?",
    description: "The policy is located <a href=\"https://www.heinz.com.au/privacy#:~:text=We%20only%20use%20or%20disclose,purpose%20and%20where%20you%20have\" target=\"_blank\">here</a>." 
  },
  { 
    title: "What are the T&Cs?",
    description: "The full T&Cs are located <a href=\"#\" target=\"_blank\">here</a>."
  },
  { 
    title: "How do I share with friends?", 
    description: "To share this experience with your friends and family from within the game, navigate to the pause menu screen accessible on the top right of your screen. Once on the menu, tap the share button on the bottom of the screen to activate your device’s native share tray. This allows you to select the app you wish to share to, e.g. to your social media accounts or to your device’s messenger app. Alternatively, you can also copy the url deliciousdemise.com from your browser and share it manually."
  },
  { 
    title: "My question hasn’t been answered in these FAQs", 
    description: "Contact our customer service at 1800 037 058 (AUS) or 0800 653 050 (NZ)."
  }
]