import React, { useContext, useState, useEffect, useCallback, useRef } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import styled from 'styled-components';
import { usePC } from '@unbnd-operations/react-hooks';

import { ConfigContext } from '../../../global/ConfigState'
import { GameContext } from '../../../global/GameState'
import { UserContext } from '../../../global/UserState';

import Setup from './Setup/Setup';
import ItemFound from './ItemFound/ItemFound'
import UI from './UI/UI';
import Inventory from './Inventory/Inventory';
import Pause from './Pause/Pause';
import Code from './Code/Code';
import SignUp from './SignUp/SignUp';
import Rotate from '../../../components/helpers/Rotate'
import CircularButton from '../../../components/buttons/CircularButton';
import TutorialModal from '../../../components/modals/TutorialModal';
import Completion from './Completion/Completion';
import CutsceneVideo from './Video/CutsceneVideo';
import Backstory from './Setup/Backstory';
import Welcome from './Welcome/Welcome';

import PrizeModal from '../../../components/modals/PrizeModal';

import PauseIcon from '../../../assets/images/circular-btn-icons/pause.svg'
import CloseIcon from '../../../assets/images/circular-btn-icons/x-icon.svg'

const PauseButton = styled.div`
  position: absolute;
  right: 1.5em;
  top: 1.5em;
  pointer-events: none;
  opacity: 0;
  transition: opacity 300ms;
  z-index: 98;

  &.active {
    pointer-events: auto;
    opacity: 1;
  }

  .close-icon {
    width: 12px;
  }
`;

function Experience() {
  const { setupDone } = useContext(ConfigContext);

  const {
    showSignUpPrompt
  } = useContext(UserContext);

  const {
    currentPortal,
    portals,
    tutorialStep,
    currTutorial,
    triggerNextTutorial,
    tutorialCompleted,
    setInventoryTutorial,
    showZoomBtn,
    showHighlightCrosshair,
    moveToScene,
    setShowZoomBtn,
    showPrizeScreen,
    currTutorialSection
  } = useContext(GameContext);

  const [newItem, setNewItem] = useState(null);
  const [isInventoryOpen, setIsInventoryOpen] = useState(false);
  const [showInventorybtn, setShowInventorybtn] = useState(false)
  const [isPaused, setIsPaused] = useState(false);
  const [isCodeVisible, setIsCodeVisible] = useState(false);
  const [showBackStory, setShowBackstory] = useState(true)
  const [showArrowPrompt, setShowArrowPrompt] = useState(false)
  const [isEndScreenVisible, setIsEndScreenVisible] = useState(false);
  const [fadeToBlack, setFadeToBlack] = useState(false);
  const [showVideo, setShowVideo] = useState(false);
  const [showPortalPrompt, setShowPortalPrompt] = useState(false)

  const currentSceneData = currentPortal !== null
    ? portals[currentPortal]?.scenes[portals[currentPortal]?.currentScene]
    : null;

  const handlePortalPrompt = ({id}) => {
    console.log(`id`, id)
    if (id === 1) {
      setShowPortalPrompt(true)
    }
  }

  usePC({
    event: 'portal:switch',
    callback: handlePortalPrompt
  });

  const handleSceneCompleted = useCallback(({ name }) => {
    setFadeToBlack(true)
    if (!currentSceneData) return;

    if (currentSceneData.video) {
      setShowVideo(true)
    } else {
      moveToScene({ name });
    }

  }, [currentSceneData]);

  usePC({
    event: 'scene:change',
    callback: handleSceneCompleted
  });

  const handleVideoCompleted = useCallback(() => {
    const nextScene = portals[currentPortal]?.scenes[portals[currentPortal]?.currentScene + 1];
    moveToScene({ name: nextScene.name });
    setShowVideo(false)
    setFadeToBlack(false)
  }, [currentSceneData]);

  // Every scene change
  useEffect(() => {
    if (!currentSceneData) return;

    setFadeToBlack(false);
  }, [currentSceneData]);

  const handlePickup = useCallback(({ id }) => {
    const pickupItem = portals[currentPortal].items.find(item => item.id === id);
    setNewItem(pickupItem);
  }, [currentPortal]);

  usePC({
    event: 'pickup',
    callback: handlePickup
  });

  useEffect(() => {
    if (currentPortal === 0 && !tutorialCompleted && isInventoryOpen) {
      setInventoryTutorial()
    }
  }, [isInventoryOpen, currentPortal, tutorialCompleted]);

  useEffect(() => {
    if (!newItem) return
    if (newItem.id === 'keys') {
      setShowInventorybtn(true)
    }
  }, [newItem]);

  useEffect(() => {
    if (currentPortal === 1) {
      setShowInventorybtn(true)
      setShowZoomBtn(true)
    }
  }, [currentPortal]);

  const closeNewItem = () => {
    setNewItem(null);

    if (!tutorialCompleted && currentPortal === 0) {
      setTimeout(() => {
        setIsInventoryOpen(true)
      }, 1000);
    }
  }

  const closeBackstoryAndPause = () => {
    setShowBackstory(false)
    setIsPaused(false);
  }

  /**
   * Code
   */
  const triggerCode = () => {
    setIsCodeVisible(true);
  }

  const closeCode = () => {
    setIsCodeVisible(false);
  }

  usePC({
    event: 'chest:trigger',
    callback: triggerCode
  });

  /**
   * End screen video
   */

  usePC({
    event: 'ending:animate',
    callback: () => setIsEndScreenVisible(true),
  });

  usePC({
    event: 'portal:complete',
    callback: () => setIsEndScreenVisible(false),
  });

  /**
   * Show welcome overlay after setup
   * is completed
   */
  useEffect(() => {
    if (!setupDone) return;

    setShowArrowPrompt(true);

  }, [setupDone]);

  return (
    <>
      {/* Rotate */}
      <Rotate />

      {/* Pause menu */}
      <AnimatePresence>
        {isPaused && <Pause />}
      </AnimatePresence>

      {/* Pause button */}
      <PauseButton className={true ? 'active' : ''}>
        <CircularButton
          size={30}
          onClick={() => setIsPaused(!isPaused)}
        >
          {isPaused
            ? <img className='close-icon' src={CloseIcon} alt="Close icon" />
            : <img src={PauseIcon} alt="Pause icon" />
          }
        </CircularButton>
      </PauseButton>

      {/* Sign up */}
      {showSignUpPrompt && <SignUp />}

      {currentPortal !== null ? (
        <>
          {/* Backstory */}
          <AnimatePresence>
            {showBackStory && (
              <motion.div
                className="back-story-wrapper fullscreen"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
              >
                <Backstory
                  portalName={portals[currentPortal].name}
                  portalBackstory={portals[currentPortal].backstory}
                  closeBackstory={() => closeBackstoryAndPause()}
                />
              </motion.div>
            )}
          </AnimatePresence>
          {/* Inside a portal */}

          {/* Completion */}
          {portals[currentPortal].completed && (
            <Completion
              productImg={portals[currentPortal].product_image}
              active={portals[currentPortal].completed}
              portalName={portals[currentPortal].title}
            />
          )}

          {/* Prize Modal */}
          <AnimatePresence>
            {showPrizeScreen && (
              <PrizeModal />
            )}
          </AnimatePresence>

          {/* Inventory */}
          <AnimatePresence>
            {isInventoryOpen && (
              <Inventory />
            )}
          </AnimatePresence>

          {/* New Item */}
          {newItem && (
            <ItemFound
              item={newItem}
              close={closeNewItem}
            />
          )}

          {/* UI */}
          {!showPrizeScreen && 
            !newItem && 
            !isPaused && 
            !showSignUpPrompt &&
            !isEndScreenVisible &&
          (
            <UI
              toggleInventory={() => setIsInventoryOpen(!isInventoryOpen)}
              inventoryOpen={isInventoryOpen}
              showZoomBtn={(showZoomBtn || tutorialCompleted || currentPortal !== 0)}
              showInventorybtn={(showInventorybtn || tutorialCompleted || currentPortal !== 0)}
              showHighlightCrosshair={showHighlightCrosshair}
            />
          )}

          {/* Tutorial */}
          <AnimatePresence>
            {currTutorial &&
              currTutorialSection >= 0 &&
              tutorialStep < currTutorial.length &&
              !tutorialCompleted && (
                <TutorialModal
                  onClick={triggerNextTutorial}
                  isClose={tutorialStep === (currTutorial.length - 1)}
                >
                  {currTutorial[tutorialStep]}
                </TutorialModal>
            )}
          </AnimatePresence>

          {/* Fade to black & video cutscene */}
          <AnimatePresence>
            {fadeToBlack && (
              <motion.div
                className='fade-to-black fullscreen'
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
              >
                {showVideo && currentSceneData && (
                  <CutsceneVideo
                    src={currentSceneData.video}
                    videoCompleted={handleVideoCompleted}
                  />
                )}
              </motion.div>
            )}
          </AnimatePresence>
        </>
      ) : (
        <>
          {!setupDone ? (
            <Setup />
          ) : (
            <>
              <AnimatePresence>
                {showArrowPrompt && (
                  <Welcome 
                    handleClose={() => setShowArrowPrompt(false)} 
                    prompt="Use the arrows to select a portal then walk through to begin"
                  />
                )}
              </AnimatePresence>

              {isCodeVisible && (
                <Code 
                  close={closeCode}
                />
              )}

              {/* Prompt user to finish portal 1 */}
              <AnimatePresence>
                {showPortalPrompt && !tutorialCompleted && (
                  <Welcome 
                    handleClose={() => setShowPortalPrompt(false)}
                    prompt="Please come back here after you've completed Chipotle BBQ portal."
                  />
                )}
              </AnimatePresence>
            </>
          )}
        </>
      )}

    </>
  )
}

export default Experience
