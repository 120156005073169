import chipotleVid from '../assets/videos/tales/whitlocks_15sec_chipotle_c.mp4';
import beansVid from '../assets/videos/tales/whitlocks_15sec_beans_c.mp4';
import allVid from '../assets/videos/tales/whitlocks_60sec_all_c.mp4';

export default [
  {
    title: 'Tales of Delicious Demise',
    description: [
      "This is an unordinary tale about the time in every veggie, fruit or herb's life when they ask themselves; 'Am I destined to be boring and bland... Or do I have a grander plan?'",
      "You see, only the boldest, most adventurous ingredients rustle up deliciousness of the same ilk.",
      "Like Tomasz, Alba and El Capo who were keen as mustard to claim the bounty on a rather prickly-pariah. But let's just say, they didn't quite cut it.",
      "And Chip, who loved life on the open road but soon discovered; you live fast, you die young...and leave a delicious-looking corpse.",
      "And the swashbuckling-swindlers, Rojo & Oscar, whose shenanigans saw them in a bit of a bind.",
      "We can't forget Pim, Pearl & Thymon, who searched the bayou for the terroriser of locals. And, as fate would have it, out-of-towners too.",
      "These are Whitlock’s Tales of Delicious Demise; stories of Whitlock-worthy adventurers.",
      "And although they have not-so-happy-endings, we know you’ll enjoy every one of them.",
    ],
    videoSrc: allVid,
  },
  {
    title: 'Chipotle BBQ Sauce',
    description: [
      "Chip is one hot-headed Chipotle. The kind that ventures far and wide and ends up in some fiery scenarios.",
      "And this time was no different. With the wind blowing in his stalk, Chip hits the open road he sets out to become a member of the Grim Reapers of Scoville; the infamous chilli outlaw motorcycle club. But unbeknownst to him, their precarious initiation ritual is not for the faint of heart.",
      "So when Chip comes knocking on their door, he soon realises he’s probably bitten off more than he can chew. In fact, this is one delicious demise that would make any chilli blush."
    ],
    videoSrc: chipotleVid,
  },
  {
    title: 'Killer Beans Fuego Chilli',
    description: [
      "Rojo & Oscar are swashbuckling outlaw bean-pirates who spend their days sailing up and down the Mexican coast from port to port, taverna to taverna, being generally rambunctious.",
      "And as fate would have it, their downfall was their penchant for the sauce and lady-beans. Including the sister of the infamous Capitán La Haba, who was, understandably, not pleased when word got back to him.",
      "Capitán La Haba and his not-so-merry pirates track down Rojo & Oscar up to their usual shenanigans and corner them after a brief pursuit. Forget walking the plank, these swindlers find themselves headed for a delicious demise unlike any other. Looks like they’re stuffed. Literally.",
    ],
    videoSrc: beansVid,
  }
];
