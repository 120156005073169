import React, { useEffect, useState } from 'react'
import ReactGA from 'react-ga'
import styled from 'styled-components'
import { useTimeout } from '@unbnd-operations/react-hooks'

import DelicousLogoWrapper from '../../../../components/layout/DelicousLogoWrapper'
import CircularButton from '../../../../components/buttons/CircularButton'
import OverlayWrapper from '../../../../components/layout/OverlayWrapper'
import SuccessTitle from '../../../../components/typography/SuccessTitle'

import DesertLook from '../../../../assets/images/completion/desert-look.png'
// import DoorIcon from '../../../../assets/images/completion/home-icon.svg'
import ShareIcon from '../../../../assets/images/completion/share-icon.svg'
// import HomeIcon from '../../../../assets/images/completion/house-icon.svg'
import RestartIcon from '../../../../assets/images/pause-menu/restart.svg'

import SplashSeq from '../../../../assets/images/completion/completion-bg-animation-final.png'

const CompletetionWrapper = styled.div`
  width: 100%;
  height: 100%; 
  background-color: #fff;
  background-image: url(${DesertLook});
  background-size: cover;
  text-align: center;

  opacity: 0;
  position: absolute;
  z-index: 95;

  .screen-title {
    font-family: var(--altFont);
    font-size: 2.375rem;
    color: var(--primaryColour);
    margin-top: 18px;
    ${'' /* transform: scale(0); */}
    transition: transform 250ms;
  }

  .screen-sub-title {
    font-family: var(--mainFont);
    font-size: 1rem;
    text-transform: uppercase;
  }

  &.active {
    opacity: 1;
    ${'' /* .screen-title {
      transform: scale(1);
    } */}
  }

  .character-one {
    width: 104px;
  }

  .character-two {
    width: 48px;
  }

  .sauce-bottle {
    width: 85px;
    margin-left: 20px;
    z-index: 1;
  }

  .image-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 52px;
    .character-one-wrapper {
      align-self: flex-end;
      position: relative;
      .speech-bubble-wrapper {
        position: absolute;
        top: -90%;
        left: -10%;
        opacity: 0;
        transition: opacity 250ms;
        &.active {
          opacity: 1;
        }
      }
      .character-one-shadow {
        width: 53px;
        height: 4px;
        position: absolute;
        left: 50%;
        bottom: -5%;
        transform: translateX(-50%);
      }
    }

    .character-two-wrapper {
      align-self: flex-end;
      position: relative;
      .character-two-shadow {
        width: 34px;
        height: 4px;
        position: absolute;
        left: 50%;
        bottom: -5%;
        transform: translateX(-50%);
      }
    }
  }

  .image-wrap {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    img {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .button-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    .circular-btn-wrapper {
      margin: 0 12px;
    }
  }

  .apng-seq {
    width: 100%;
    height: 100%;

    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    pointer-events: none;
  }

  .sauce-bottle-wrapper {
    position: relative;
    
    .product-shadow {
      width: 253px;
      position: absolute;
      bottom: -50%;
      right: -20%;
      max-width: none;
    }
  }

  .success-title-wrapper {
    position: absolute;
    z-index: 3;
    opacity: 0;
    transition: opacity 250ms;
    pointer-events: none;
    &.active {
      opacity: 1;
    }
  }

`

function Completion({ productImg, active, portalName }) {
  const [showSuccessTitle, setShowSuccessTitle] = useState(true)

  useTimeout(() => {
    setShowSuccessTitle(false);
  }, 2500)

  const onRestartClick = () => {
    window.location.reload();
  }

  const onShareClick = () => {
    if (!navigator.share) return;

    ReactGA.event({
      category: 'User',
      action: 'Share',
      label: 'Completion Screen'
    });

    navigator.share({
      url: 'https://deliciousdemise.com?utm_medium=native_share&utm_source=referral&utm_campaign=ar',
      title: 'F. Whitlocks & Sons',
    });
  }

  useEffect(() => {
    ReactGA.event({
      category: 'Game',
      action: 'Portal Completed',
      label: portalName
    });
  }, []);

  return (
    <CompletetionWrapper className={`completion-wrapper ${active ? 'active' : ''}`}>
      <img className='apng-seq' src={SplashSeq} alt="Splash Sequence Animation" />
      <SuccessTitle className={`${showSuccessTitle ? 'active' : ''}`} title='Finito!' isInverted={true} />

      <OverlayWrapper opacity={0.7}>
        <DelicousLogoWrapper small={true} />
        <h2 className='screen-title'>Congratulations!</h2>
        <p className='screen-sub-title'>You have completed the {portalName} portal</p>

        <div className="fullscreen image-wrap">
          <img src={productImg} alt="Completion" />
        </div>

        <div className='button-wrapper'>
          <CircularButton onClick={onShareClick} className='share-btn' size={45} label='Share'>
            <img src={ShareIcon} alt="Share" />
          </CircularButton>
          {/* <CircularButton onClick={backToHome} className='home-btn' size={45} label='home'>
            <img src={HomeIcon} alt="Door Icon" />
          </CircularButton>
          <CircularButton onClick={enterPortal} className='next-btn' size={45} label='Swap Portals'>
            <img src={DoorIcon} alt="Door Icon" />
          </CircularButton> */}
          <CircularButton onClick={onRestartClick} className='reset-btn' size={45} label='Restart'>
            <img src={RestartIcon} alt="Restart" />
          </CircularButton>
        </div>
      </OverlayWrapper>
    </CompletetionWrapper>
  )
}

export default Completion
