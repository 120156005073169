export const RedeemFormPages = [
  [
    {
      title: "First Name*",
      placeholder: "First Name",
      name: "firstName",
      type: "text",
      value: "",
      borderBottom: true,
    },
    {
      title: "Last Name*",
      placeholder: "Last Name",
      name: "lastName",
      type: "text",
      value: "",
      borderBottom: true,
    },
    {
      title: "Mobile Number*",
      placeholder: "Mobile",
      name: "mobile",
      type: "tel",
      value: "",
      borderBottom: true,
    },
    {
      title: "Email*",
      placeholder: "email",
      name: "email",
      type: "email",
      borderBottom: true,
    },    
  ],
  [
    {
      name: "type",
      type: "hidden",
      value: "",
    },
    {
      name: "additionals",
      type: "hidden",
      value: "",
    },
    {
      title: "BSB Number",
      placeholder: "BSB Number",
      name: "bsb",
      type: "number",
      value: "",
      borderBottom: true,
      conditions: ["cash", "grand"],
    },
    {
      title: "Account Number",
      placeholder: "Account Number",
      name: "accountNumber",
      type: "number",
      value: "",
      borderBottom: true,
      conditions: ["cash", "grand"],
    },
    {
      title: "Address*",
      placeholder: "Address",
      name: "address",
      type: "text",
      value: "",
      borderBottom: true,
      conditions: ["product", "shirt", "cap", "grand"],
    },
    {
      title: "City*",
      placeholder: "City",
      name: "city",
      type: "text",
      value: "",
      condition: "postageDetails",
      borderBottom: true,
      conditions: ["product", "shirt", "cap", "grand"],
    },
    {
      title: "State*",
      placeholder: "State",
      name: "state",
      type: "text",
      value: "",
      condition: "postageDetails",
      borderBottom: true,
      conditions: ["product", "shirt", "cap", "grand"],
    },
    {
      title: "Postcode*",
      placeholder: "Postcode",
      name: "postcode",
      type: "number",
      condition: "postageDetails",
      borderBottom: true,
      conditions: ["product", "shirt", "cap", "grand"],
    },
    {
      title: "Shirt Size*",
      placeholder: "Shirt Size",
      name: "size",
      type: "radio",
      condition: "postageDetails",
      borderBottom: false,
      conditions: ["shirt", "grand"],
    },
  ],
];
