import React from 'react';
import styled from 'styled-components';

import { useHistory } from 'react-router-dom';

const info = {
  name: 'Unbnd Group',
  website: 'unbnd.com.au',
};

const CookiePolicyWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 1rem 1.5rem;
  background-color: var(--baseLight);

  h1,
  h2,
  h3 {
    margin-bottom: 0.5rem;
  }

  p {
    font-size: 0.875rem;
    margin-bottom: 2rem;
  }
`;

const ArrowSvg = styled.svg`
  height: 30px;
  width: 30px;
  pointer-events: auto;
  margin-bottom: 0.5rem;
`;

function CookiePolicy() {
  let history = useHistory();

  const handleBack = () => {
    history.goBack();
  };

  return (
    <CookiePolicyWrapper id='cookie-policy'>
      <ArrowSvg
        arrowSize={30}
        onClick={handleBack}
        xmlns='http://www.w3.org/2000/svg'
        className='arrow'
        fill='none'
        viewBox='0 0 24 24'
        stroke='#000000'
      >
        <path
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth={2}
          d='M10 19l-7-7m0 0l7-7m-7 7h18'
        />
      </ArrowSvg>
      <h1>Cookie Policy</h1>
      <p>
        Please read this cookie policy (“cookie policy”, "policy") carefully
        before using {info.website} website(“website”, "service") operated by{' '}
        {info.name} ("us", 'we", "our").
      </p>
      <h2>What are cookies?</h2>
      <p>
        Cookies are simple text files that are stored on your computer or mobile
        device by a website’s server. Each cookie is unique to your web browser.
        It will contain some anonymous information such as a unique identifier,
        website’s domain name, and some digits and numbers.
      </p>
      <h2>What types of cookies do we use?</h2>
      <h3>Necessary cookies</h3>
      <p>
        Necessary cookies allow us to offer you the best possible experience
        when accessing and navigating through our website and using its
        features. For example, these cookies let us recognize that you have
        created an account and have logged into that account.
      </p>
      <h3>Functionality cookies</h3>
      <p>
        Functionality cookies let us operate the site in accordance with the
        choices you make. For example, we will recognize your username and
        remember how you customized the site during future visits.
      </p>
      <h3>Analytical cookies</h3>
      <p>
        These cookies enable us and third-party services to collect aggregated
        data for statistical purposes on how our visitors use the website. These
        cookies do not contain personal information such as names and email
        addresses and are used to help us improve your user experience of the
        website.
      </p>
      <h3>How to delete cookies?</h3>
      <p>
        If you want to restrict or block the cookies that are set by our
        website, you can do so through your browser setting. Alternatively, you
        can visit www.internetcookies.org, which contains comprehensive
        information on how to do this on a wide variety of browsers and devices.
        You will find general information about cookies and details on how to
        delete cookies from your device.
      </p>
      <h3>Contacting us</h3>
      <p>
        If you have any questions about this policy or our use of cookies,
        please contact us.
      </p>
    </CookiePolicyWrapper>
  );
}

export default CookiePolicy;
