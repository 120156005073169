import React from 'react'
import styled from 'styled-components'
import OverlayWrapper from '../../../../components/layout/OverlayWrapper'
import Ornate from '../../../../assets/images/backstory/ornate.svg'
import SquareButton from '../../../../components/buttons/SquareButton'

const BackstoryWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background-color: #fff;
  text-align: center;
  pointer-events: auto;

  .title {
    font-family: var(--altFont);
    font-size: 3.125rem;
  }

  .square-btn {
    margin: 0 auto;
  }

  .content {
    font-size: 1rem;
    line-height: 1.2;
  }

  .about {
    font-family: var(--mainFont);
    font-size: 1.5rem;
    text-transform: uppercase;
    margin: 0;
  }

  .overlay-wrapper .inner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 44px 14px;
  }

`

function Backstory({ portalName, portalBackstory, closeBackstory }) {
  return (
    <BackstoryWrapper>
      <OverlayWrapper className='backstory-overlay-wrapper'>
        <div>
          <img src={Ornate} alt='ornate' className='ornate' />
          <p className='about'>About the portal</p>
          <h2 className='title'>{portalName}</h2>
        </div>
        <div className='content'>
          {portalBackstory.map((item, index) => (
            <p key={index}>{item}</p>
          ))}
        </div>
        <SquareButton onClick={closeBackstory}>
          Let's Go
        </SquareButton>
      </OverlayWrapper>
    </BackstoryWrapper>
  )
}

export default Backstory
