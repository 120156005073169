import React, { createContext, useEffect, useState, useRef } from 'react'
import axios from 'axios'
import { signInWithPhoneNumber, onAuthStateChanged, RecaptchaVerifier, signOut } from 'firebase/auth';
import { auth } from '../firebase';
import ReactGA from 'react-ga';

import { normalizeNumber } from '../helpers';

export const UserContext = createContext();

function UserState(props) {
  const [currentUser, setCurrentUser] = useState(null);
  const [isUserReady, setIsUserReady] = useState(false);
  const [hasUserBeenPrompt, setHasUserBeenPrompt] = useState(false);
  const [showSignUpPrompt, setShowSignUpPrompt] = useState(false);
  const [showTFA, setShowTFA] = useState(false);
  // const [TFAError, setTFAError] = useState(null);
  const TFAConfirmation = useRef(null);

  const triggerSignUp = () => {
    if (hasUserBeenPrompt) return;
    if (currentUser) return;

    setShowSignUpPrompt(true);
  }

  const closeSignUp = () => {
    setShowSignUpPrompt(false);
    setHasUserBeenPrompt(true);
  }

  const configureCaptcha = () => {
    window.recaptchaVerifier = new RecaptchaVerifier('signup-submit', {
      'size': 'invisible',
      'callback': (response) => {
        // reCAPTCHA solved, allow signInWithPhoneNumber.
        signIn();
        console.log("Recaptcha verified");
      }
    }, auth);
  }

  const signIn = (mobile) => {
    const phoneNumber = normalizeNumber(mobile);
    configureCaptcha();

    const appVerifier = window.recaptchaVerifier;

    return signInWithPhoneNumber(auth, phoneNumber, appVerifier)
      .then((confirmationResult) => {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        TFAConfirmation.current = confirmationResult;
        setShowTFA(true)

      })
      .catch((error) => {
        console.log(error);
        return "Something went wrong";
      });
  }

  const confirmSignIn = (code) => {
    return TFAConfirmation.current.confirm(code)
      .then((result) => {
        // User signed in successfully.
        // console.log("Signed in");
        setShowTFA(false);
      })
      .catch((error) => {
        // console.log("Could not sign in");
        // console.log(error);
        return "Code is incorrect, please try again";
      });
  }

  // const signOutUser = () => {
  //   signOut(auth).then(() => {
  //     setCurrentUser(null);
  //   }).catch((err) => {
  //     // console.log(err);

  //     setCurrentUser(null);
  //   });
  // }

  useEffect(() => {
    if (currentUser) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${currentUser.accessToken}`;
      setIsUserReady(true);
      ReactGA.event({
        category: "User",
        action: "Signed in"
      });
  
    } else {
      delete axios.defaults.headers.common['Authorization'];
      setIsUserReady(false);
    }
  }, [currentUser]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, user => {
      setCurrentUser(user);
    });

    return unsubscribe;
  }, []);

  return (
    <UserContext.Provider value={{
      currentUser,
      hasUserBeenPrompt,
      signIn,
      confirmSignIn,
      showTFA,
      closeSignUp,
      triggerSignUp,
      showSignUpPrompt,
      isUserReady
    }}>
      {props.children}
    </UserContext.Provider>
  )
}

export default UserState
