import React from 'react'
import styled from 'styled-components'

import LabelBG from '../../assets/images/button-label/label-background.svg'
import greenBG from '../../assets/images/button-label/green-label-bg.svg'

const ButtonLabelWrapper = styled.div`
  .button-label-inner {
    width: auto;
    height: auto;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    .label-background {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
      width: 100%;
      height: 100%;
      z-index: -1;
    }
    
    .label-body {
      font-family: var(--mainFont);
      font-size: 18px;
      color: var(--white);
      padding: 8px 14px;
      text-transform: uppercase;
      ${'' /* position: relative; */}
    }
  }
`

function ButtonLabel({ children, variant }) {
  return (
    <ButtonLabelWrapper className="button-label-wrapper">
      <div className="button-label-inner">
        <div className='label-body'>
          <img className='label-background' src={variant === 'green' ? greenBG : LabelBG} alt="Label BG" />
          {children}
        </div>
      </div>
    </ButtonLabelWrapper>
  )
}

export default ButtonLabel
