export const validateValues = values => {
  let errors = {};

  if (!values.firstName) {
    errors.firstName = 'First Name is required';
  } else if (!/^[^±!@£$%^&*_+§¡€#¢§¶•ªº«\\/<>?:;|=.,]+$/.test(values.firstName)) {
    errors.firstName = 'Enter a valid first name';
  }

  if (!values.lastName) {
    errors.lastName = 'Last Name is required';
  } else if (!/^[^±!@£$%^&*_+§¡€#¢§¶•ªº«\\/<>?:;|=.,]+$/.test(values.lastName)) {
    errors.lastName = 'Enter a valid last name';
  }

  if (!values.postcode) {
    errors.postcode = 'Postcode is required';
  }

  if (!values.email) {
    errors.email = 'Email is required';
  } else if (!/\S+@\S+\.\S+/.test(values.email)) {
    errors.email = 'Email address is invalid';
  }

  if (!values.mobile) {
    errors.mobile = 'Mobile number is required';
  } else if (!/^(\+\d{1,3}[- ]?)?\d{10}$/.test(values.mobile)) {
    errors.mobile = 'Mobile needs to be 10 numbers long';
  }

  if (!values.terms) {
    errors.terms = 'Please accept the T&Cs';
  }
  // console.log(errors);
  return errors;
};

export const validatePhone = values => {
  let errors = {};

  if (!values.mobile) {
    errors.mobile = 'Mobile number is required';
  } else if (!/^(\+\d{1,3}[- ]?)?\d{10}$/.test(values.mobile)) {
    errors.mobile = 'Mobile needs to be 10 numbers long';
  }

  return errors;
}