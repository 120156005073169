import React, { useState } from 'react'
import styled from 'styled-components'

import { useKeenSlider } from 'keen-slider/react'
import 'keen-slider/keen-slider.min.css'
import { AnimatePresence, motion } from 'framer-motion'
import { apiPost } from '../../../../api/axios'
import { usePC } from '@unbnd-operations/react-hooks'

import CircularButton from '../../../../components/buttons/CircularButton';

import nextArrowImg from '../../../../assets/images/circular-btn-icons/next-arrow.svg';
import ornateImg from './../../../../assets/images/ornate.svg';
import SquareButton from '../../../../components/buttons/SquareButton';

import unlockedBodyImg from './../../../../assets/images/code/unlocked-body.png';
import lockedBodyImg from './../../../../assets/images/code/locked-body.png';
import keyImg from './../../../../assets/images/code/key.png';
import lockImg from './../../../../assets/images/code/lock.png';
import OverlayWrapper from '../../../../components/layout/OverlayWrapper'

const CombinationLockWrapper = styled.div `
  display: flex;
  flex-direction: column;

  pointer-events: auto;

  height: 100%;

  &.hide {
    opacity: 0;
    pointer-events: none !important;
  }

  .header {
    width: 100%;
    height: 40px;

    margin-bottom: 30px;

    display: grid;
    grid-template-columns: 1fr 3fr;

    .back {
      grid-column: 1 / 2;
      grid-row: 1 / 2;

      .arrow-icon {
        transform: rotate(180deg);
      }
    }
  }

  @keyframes wrong {
    0% {transform: translateX(0)}
    20% {transform: translateX(10%)}
    40% {transform: translateX(-10%)}
    60% {transform: translateX(10%)}
    80% {transform: translateX(-10%)}
    100% {transform: translateX(0)}
  }

  .main.wrong {
    animation-name: wrong;
    animation-duration: 0.5s;

    color: #C32033;
  }

  .main {
    transition: all 0.2s ease-in-out;

    height: calc(100% - 200px - 2em);
    width: 100%;

    display: flex;
    flex-direction: column;

    justify-content: space-around;
    align-items: center;

    h2 {
      font-family: var(--mainFont);
      font-size: 42px;
      margin: 0;
    }

    .lock {
      width: 80%;

      display: flex;
      flex-direction: column;
      align-items: center;
      
      .lockImages {
        width: 100%;
        height: 73.8vw;

        img {
          position: absolute;
          width: 80%;
        }

        .key {
          z-index: 1;
        }
      }

      .lockSliders {
        display: flex;
        align-items: center;
        justify-content: center;

        z-index: 1;

        width: 66%;

        transform: translateY(calc(-15px - 4.5vw));

        .lockSlider {
          width: calc(100% / 4);
          height: 11vw;

          pointer-events: auto;

          display: flex;
          align-items: center;
          justify-content: center;

          p {
            margin: 0;
            font-family: var(--altFont);
            font-size: clamp(22px, 7vw, 28px);

            line-height: clamp(38px, 12vw, 50px);
            text-align: center;
          }
        }
      }
    }
  }

  .footer {
    position: absolute;

    width: 100%;
    height: 150px;
    bottom: 20px;

    display: flex;
    flex-direction: column;

    justify-content: space-around;
    align-items: center;

    .square-btn {
      margin-bottom: 20px;
      pointer-events: auto;
    }

    p {
      font-family: var(--altFont);
      font-style: italic;
      letter-spacing: 1px;
      font-size: 16px;
    }

    .ornate {
      width: 60px;
    }
  }
`;

function CombinationLock({close}) {

  const [sliderRef1, slider1] = useKeenSlider({
    vertical: true,
    loop: true,
    mode: "free-snap",
    centered: true,
    initial: 0,
    spacing: 10,
  })

  const [sliderRef2, slider2] = useKeenSlider({
    vertical: true,
    loop: true,
    mode: "free-snap",
    centered: true,
    initial: 0,
    spacing: 10,
  })

  const [sliderRef3, slider3] = useKeenSlider({
    vertical: true,
    loop: true,
    mode: "free-snap",
    centered: true,
    initial: 0,
    spacing: 10,
  })

  const [sliderRef4, slider4] = useKeenSlider({
    vertical: true,
    loop: true,
    mode: "free-snap",
    centered: true,
    initial: 0,
    spacing: 10,
  })

  const [isWrong, setIsWrong] = useState(false);
  const [isCorrect, setIsCorrect] = useState(false);
  const [attemptsLeft, setAttemptsLeft] = useState(null);
  const [hideUI, setHideUi] = useState(false);

  const keyVariants = {
    locked: { y: 0 },
    unlocked: { y: '60vh', x: -110, rotate: -40, transition: { delay: 0.75,  duration: 0.7 } }
  }

  const lockVariants = {
    locked: { y: 0},
    unlocked: { y: '60vh', x: 110, rotate: 30, transition: { duration: 0.7 } }
  }

  const slidersVariants = {
    locked: { y: 'calc(-25px - 3.5vw)' },
    unlocked: { y: 'calc(-25px - 3.5vw)', opacity: 0, transition: { duration: 0.7 } }
  }

  const lockedBodyVariants = {
    locked: { opacity: 1 },
    unlocked: { opacity: 0, transition: { delay: 1.5 } },
  }

  const unlockedBodyVariants = {
    locked: { y: -18, opacity: 0 },
    unlocked: { y: -18, opacity: 1, transition: { delay: 1.5 } },
  }

  const [triggerChest] = usePC({
    event: 'chest:open'
  });

  const onUnlock = () => {
    setIsCorrect (true);

    const chestTimout = setTimeout(() => {
      triggerChest();
      
      // setHideUi(true); // TODO: ADD
      close(); // TODO: REMOVE

      clearTimeout(chestTimout);
    }, 3000);
  }

  const onWrong = (e, left) => {
    if (isWrong) return;

    setIsWrong (`Attempts left: ${left}`);
    e.stopPropagation ();

    setTimeout(() => {
      setIsWrong (null);
    }, 1250);
  }
  

  const handleSubmit = async (e) => {
    if (attemptsLeft === 0) return;
    const a = String(slider1.details().relativeSlide); 
    const b = String(slider2.details().relativeSlide); 
    const c = String(slider3.details().relativeSlide); 
    const d = String(slider4.details().relativeSlide); 
    const code = a + b + c + d;

    console.log(code);

    try {
      const response = await apiPost("/game/chest", {code});
      setAttemptsLeft(response.data.attemptsLeft);

      if (response.data.solved === true) {
        onUnlock();
      } else {
        onWrong(e, response.data.attemptsLeft);
      }
    } catch (err) {
      onWrong(e, "0");
    }
  }

  

  return (
    <CombinationLockWrapper className={`combination-lock ${hideUI ? "hide" : ""}`}>
      <OverlayWrapper>
      <div className='header'>
        <div className='back'>
          <CircularButton onClick={close} size='50'>
            <img className='arrow-icon' src={nextArrowImg}/>
          </CircularButton>
        </div>
      </div>
      <div className={(isWrong || attemptsLeft === 0) ? 'main wrong' : 'main'}>
        <h2>
          {(() => {
            if (isWrong)
              return isWrong;
            else if (isCorrect)
              return "You did it!";
            else if (attemptsLeft === 0)
              return "No attempts left"
            else 
              return "Crack the code";
          })()}
        </h2>
        <div className='lock'>
          <AnimatePresence>
            <div className='lockImages'>
                <motion.img 
                    key='unlocked-body' 
                    src={unlockedBodyImg}
                    variants={unlockedBodyVariants}
                    animate={isCorrect ? 'unlocked' : 'locked'}
                  />
                <motion.img 
                    key='locked-body' 
                    src={lockedBodyImg}
                    variants={lockedBodyVariants}
                    animate={isCorrect ? 'unlocked' : 'locked'}
                  />
                <motion.img className='key' 
                      key='key' 
                      src={keyImg} 
                      variants={keyVariants}
                      animate={isCorrect ? 'unlocked' : 'locked'}
                />
                <motion.img className='lock' 
                      key='lock' 
                      src={lockImg}
                      variants={lockVariants}
                      animate={isCorrect ? 'unlocked' : 'locked'}
                />
            </div>
            <motion.div className='lockSliders'
                      key='sliders' 
                      variants={slidersVariants}
                      animate={isCorrect ? 'unlocked' : 'locked'}
            >
              <div ref={sliderRef1}  className='lockSlider keen-slider'>
                <p className='keen-slider__slide'>0</p>
                <p className='keen-slider__slide'>1</p>
                <p className='keen-slider__slide'>2</p>
                <p className='keen-slider__slide'>3</p>
                <p className='keen-slider__slide'>4</p>
                <p className='keen-slider__slide'>5</p>
                <p className='keen-slider__slide'>6</p>
                <p className='keen-slider__slide'>7</p>
                <p className='keen-slider__slide'>8</p>
                <p className='keen-slider__slide'>9</p>
              </div>
              <div ref={sliderRef2}  className='lockSlider keen-slider'>
                <p className='keen-slider__slide'>0</p>
                <p className='keen-slider__slide'>1</p>
                <p className='keen-slider__slide'>2</p>
                <p className='keen-slider__slide'>3</p>
                <p className='keen-slider__slide'>4</p>
                <p className='keen-slider__slide'>5</p>
                <p className='keen-slider__slide'>6</p>
                <p className='keen-slider__slide'>7</p>
                <p className='keen-slider__slide'>8</p>
                <p className='keen-slider__slide'>9</p>
              </div>
              <div ref={sliderRef3}  className='lockSlider keen-slider'>
                <p className='keen-slider__slide'>0</p>
                <p className='keen-slider__slide'>1</p>
                <p className='keen-slider__slide'>2</p>
                <p className='keen-slider__slide'>3</p>
                <p className='keen-slider__slide'>4</p>
                <p className='keen-slider__slide'>5</p>
                <p className='keen-slider__slide'>6</p>
                <p className='keen-slider__slide'>7</p>
                <p className='keen-slider__slide'>8</p>
                <p className='keen-slider__slide'>9</p>
              </div>
              <div ref={sliderRef4}  className='lockSlider keen-slider'>
                <p className='keen-slider__slide'>0</p>
                <p className='keen-slider__slide'>1</p>
                <p className='keen-slider__slide'>2</p>
                <p className='keen-slider__slide'>3</p>
                <p className='keen-slider__slide'>4</p>
                <p className='keen-slider__slide'>5</p>
                <p className='keen-slider__slide'>6</p>
                <p className='keen-slider__slide'>7</p>
                <p className='keen-slider__slide'>8</p>
                <p className='keen-slider__slide'>9</p>
              </div>
            </motion.div>
          </AnimatePresence>
        </div>
      </div>
      <div className='footer'>
        {!isCorrect && <SquareButton onClick={handleSubmit}>TRY IT</SquareButton>}
        <p>Stuck? Look for clues in each world</p>
        <img src={ornateImg}/>
      </div>
      </OverlayWrapper>
    </CombinationLockWrapper>
)
}

export default CombinationLock
