import React, { useEffect } from 'react'
import ReactGA from 'react-ga'

function Camera() {
  useEffect(() => {
    ReactGA.event({
      category: 'Error',
      action: 'Camera error'
    });
  }, []);

  return (
    <div className="fullscreen f-col f-center">
      <p>The camera permission were denied on launch. Please refresh your browser and press allow on the pop-up.</p>
    </div>
  )
}

export default Camera
