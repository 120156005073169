import React, { useContext } from 'react'
import styled from 'styled-components'

import Camera from './Camera'
import Motion from './Motion'
import Browser from './Browser'
import Desktop from './Desktop'
import OverlayWrapper from '../../../components/layout/OverlayWrapper'
import DelicousLogoWrapper from '../../../components/layout/DelicousLogoWrapper'
import WhitlockLogoWrapper from '../../../components/layout/WhitlockLogoWrapper'

import { ConfigContext } from '../../../global/ConfigState'


const ErrorScreensWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background: #ffffff;
  text-align: center;
  z-index: 99999;
  pointer-events: auto;
  overflow: scroll;
  width: 100%;
  min-height: 100%;

  .overlay-wrapper {
    padding-left: 2em;
    padding-right: 2em;
    p {
      font-size: 1em;
      font-family: var(--copyFont);
      line-height: 1.5;
    }
  }

  .whitlocks-wrapper {
    position: absolute;
    left: 0;
    bottom: 1.5em;
    z-index: 5;
  }

  .delicous-wrapper {
    position: absolute;
    left: 0;
    top: 2em;
    z-index: 5;
  }

`

function Error() {
  const { error } = useContext(ConfigContext);

  return (
    <ErrorScreensWrapper className="error">

      {(error === 'camera' || error === 'motion' || error === 'browser') && (
        <div className="fullscreen f-col f-center">
          <DelicousLogoWrapper ornate="true" />
          <OverlayWrapper opacity={0.3}>
            {error === 'camera' && <Camera />}
            {error === 'motion' && <Motion />}
            {error === 'browser' && <Browser />}
          </OverlayWrapper>
          <WhitlockLogoWrapper />
        </div>
      )}

      {error === 'desktop' && <Desktop />}
    </ErrorScreensWrapper>
  )
}

export default Error