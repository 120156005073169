import React, { useEffect } from 'react'
import ReactGA from 'react-ga'

function Motion() {
  useEffect(() => {
    ReactGA.event({
      category: 'Error',
      action: 'Motion error'
    });
  }, []);

  return (
    <div className="fullscreen f-col f-center">
      <p>The motion and orientation permissions were denied on launch. Please refresh your browser and press allow on the pop-up.</p>
    </div>
  )
}

export default Motion
