import React, { useEffect } from 'react'
import styled from 'styled-components'
import ReactGA from 'react-ga'

import QrCode from '../../../components/helpers/QrCode'
import PaperBG from '../../../assets/images/error-screens/bg.jpg'
import TextureOverlay from '../../../assets/images/error-screens/texture-overlay.png'
import WhitlockLogo from '../../../assets/images/logos/whitlocks-logo.png'
import LeftChilli from '../../../assets/images/error-screens/chili.png'
import RightChilli from '../../../assets/images/error-screens/fatboy.png'
import or from '../../../assets/images/error-screens/or.svg'
import DelishDemiseLogo from '../../../assets/images/delicious-demise-logo.svg'

const DesktopWrapper = styled.div`
  height: 100vh;
  text-transform: uppercase;
  position: relative;
  overflow: scroll;
  background-image: url(${PaperBG});

  .paper-bg {
    min-height: 980px;
    height: 100%;
    width: 100%;
    font-family: var(--mainFont);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px;
    position: relative;

    &::after {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background-image: url(${TextureOverlay});
      background-size: cover;
      z-index: 1;
    }

    .prompt {
      font-size: 2.125em;
      text-transform: uppercase;
      max-width: 976px;
      margin: 42px auto 66px;

      span {
        font-size: 2.750rem;
      }

      @media screen and (max-width: 1024px) {
        font-size: 1.625rem;
        margin: 22px auto 26px;

        span {
          font-size: 2.25rem;
        }
      }
    }

    .whitlock-logo {
      width: 250px;

      @media screen and (min-width: 1024px) {
        width: 380px;
      }
    }
  }

  .qr-code-section {
    width: 70%;
    max-width: 880px;
    padding: 1rem;
    background-repeat: no-repeat;
    position: relative;
    background-color: rgba(65, 65, 65, 0.8);
    background-size: cover;
    background-position: 50% 50%;

    backdrop-filter: blur(2px);
    margin: 0 auto 80px auto;
    display: flex;
    justify-content: center;
    align-items: center;


    @media screen and (max-width: 1024px) {
      flex-direction: column;
    }

    .left {
      flex: 1 1 40%;
      padding: 1rem;

      p {
        font-size: 1.65rem;
        color: #fff;
        margin-bottom: 26px;
      }
    }
    .center {
      flex: 1 1 20%;
    }
    .right {
      flex: 1 1 40%;
      font-size: 1.875rem;
      p {
        margin: 0;
        color: #fff;
        line-height: 44px;

      }
      p:nth-child(2) {
        font-size: 2.5rem;
      }
    }
  }

  .portals-logo {
    position: absolute;
    bottom: 14px;
    right: 20px;
    max-width: 260px;
    width: 60%;

    @media screen and (max-height: 1000px) {
      position: relative;
      align-self: flex-end;
      bottom: auto;
      right: auto;
    }
  }

  .left-chilli, .right-chilli {
    position: absolute;
    @media screen and (max-width: 1024px) {
      display: none;
    }
  }

  .left-chilli {
    max-width: 142px;
    bottom: -60px;
    left: -100px;
  }

  .right-chilli {
    max-width: 252px;
    bottom: -70px;
    right: -130px;
  }

  .copy-wrapper {
    margin-bottom: 2rem;
    .error-heading {
      margin-bottom:0.75rem;
    }
  }

  .qr-code {
    width: 250px;
    height: 250px;
    margin: 0 auto;
    padding: 1rem;
    background-color: #fff;
    border-radius: .5rem;
  }
`

function Desktop() {
  useEffect(() => {
    ReactGA.event({
      category: 'Error',
      action: 'Desktop triggered'
    });
  }, []);

  return (
    <DesktopWrapper className='desktop-error-wrapper'>
      <div className="paper-bg">
        <img src={WhitlockLogo} alt="Whitlock Logo" className="whitlock-logo" />
        <p className='prompt'><span>Hey! You!</span><br/>Looks like you're on desktop,  whip out your mobile and scan the QR code to play the game</p>

        <div className="qr-code-section">
          {/* <img src={QrCodeSectionBG} alt="Section BG" className="qr-code-bg" /> */}
          <img src={LeftChilli} alt="Left chilli character" className="left-chilli" />
          <img src={RightChilli} alt="Right chilli character" className="right-chilli" />

          <div className='left'>
            <p>Scan the code with your mobile device to open the experience</p>
            <QrCode
              fg="#000000"
              bg="#FFFFFF"
              url={window.location.href}
            />
          </div>
          <div className='center'>
            <img src={or} alt="" className="or" />
          </div>
          <div className='right'>
            <p>Visit</p>
            <p>{window.location.hostname}</p>
            <p>on your mobile device</p>
          </div>

        </div>

        <img src={DelishDemiseLogo} alt="Portals of Delicious Demise logo" className="portals-logo" />
      </div>
    </DesktopWrapper>
  )
}

export default Desktop
