import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { apiGet } from '../../api/axios';
import { AnimatePresence, motion } from 'framer-motion';
import { useTimeout } from '@unbnd-operations/react-hooks';
import axios from 'axios';

import { UserContext } from '../../global/UserState';
import prizes from '../../data/prizes';
import { validators } from './redeem-validators';
import { RedeemFormPages } from '../../data/RedeemFormData';

import MultiPageForm from '../../components/form/MultiPageForm';
import PaperWrapper from '../../components/layout/PaperWrapper';
import DelicousLogoWrapper from '../../components/layout/DelicousLogoWrapper';
import WhitlockLogoWrapper from '../../components/layout/WhitlockLogoWrapper';
import OverlayWrapper from '../../components/layout/OverlayWrapper';
import SuccessTitle from '../../components/typography/SuccessTitle';
import CardTitle from '../../components/typography/CardTitle';

import { titleDisplay, formDisplay, thanksDisplay } from './redeem-animations';

import PaperBG from '../../assets/images/error-screens/desktop-error-paper-bg.png';
import ProductTexture from '../../assets/images/products/rub-sauce-marinade-chilli.png';

const RedeemWrapper = styled.div`
  pointer-events: auto;
  overflow: hidden;

  .redeem-bg {
    position: absolute;
    z-index: -1;
    background-image: url(${PaperBG});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    pointer-events: none;
  }

  .terms-conditions {
    width: 100%;
    text-align: center;

    a {
      display: block;
      margin: 0 auto;
      font-family: var(--copyFont);
      font-style: italic;
      font-weight: bold;
      font-size: 10px;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .intro-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .product-display {
    position: relative;
    width: 80%;
    max-width: 440px;
    margin: 0 auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .delicous-wrapper {
      position: absolute;
      top: 30px;
    }

    .product-display-inner {
      position: relative;
      flex: 0 0;
    }

    img {
      position: relative;
      display: block;
      width: 100%;
      z-index: 5;
    }
  }

  .product-shadow {
    position: absolute;
    bottom: -165px;
    left: -56px;
    width: 100%;
    height: 200px;
    background: rgba(0, 0, 0, 0.15);
    transform: skew(-30deg);
    filter: blur(26px);
  }

  .prize {
    position: absolute;
    width: 100%;
    top: 300px;
    text-align: center;
    text-transform: uppercase;

    &-major {
      font-size: 3.1rem;
      font-family: var(--altFont);
      line-height: 1.25;
      margin-bottom: 0.25em;
      font-weight: 400;
    }

    &-minor {
      margin-bottom: 0;
      font-family: var(--mainFont);
      font-size: 1.5rem;
    }
  }

  .no-prize {
    position: absolute;
    width: 100%;
    top: 150px;
    text-align: center;
    text-transform: uppercase;
  }

  .form-wrapper {
    width: 100%;
    height: 100%;
    top: 160px;
    z-index: 3;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    .whitlocks-wrapper {
      position: relative;
      bottom: 0;
      margin-top: 15px;
    }

    .top-ornate {
      display: none;
    }

    .paper-inner-wrapper .paper-body {
      padding-top: 0;
    }
  }

  .slide-nav .dots {
    padding: 5px 0 !important;
  }

  .whitlocks-wrapper {
    position: absolute;
    bottom: 30px;
  }

  .paper-wrapper {
    padding: 15px 0;
    height: calc(100% - 100px);
    min-height: 552px;

    .paper-inner-wrapper {
      min-height: 100%;
    }
    .paper-body {
      min-height: 350px;
    }
  }

  .thanks-wrapper {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    top: 40px;
    width: 100%;
  }

  .redeem-card-title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
  }
`;

function Redeem() {
  const { currentUser } = useContext(UserContext);

  const [showFormSubmitting, setShowFormSubmitting] = useState(false);
  const [showThanks, setShowThanks] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [prizeData, setPrizeData] = useState(null);
  const [showProducts, setShowProducts] = useState(true);
  const [showPrize, setShowPrize] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [combinedData, setCombinedData] = useState(null);
  const [shirtSizes, setShirtSizes] = useState(null);

  useTimeout(() => {
    setShowProducts(false);
    setShowPrize(true);
    const prizeTimeout = setTimeout(() => {
      setShowPrize(false);
      setShowForm(true);
      clearTimeout(prizeTimeout);
    }, 2500);
  }, 5000);

  const handleSubmit = (args) => {
    setShowFormSubmitting(true);

    try {
      axios.post('/prizes/redeem', args);
    } catch (error) {
      console.log(`error`, error);
    }

    setShowThanks(true);
  };

  const getPrize = async (pid, rid, user) => {
    try {
      let prize;
      if (pid === 'grand') {
        prize = await apiGet(`/prizes/grand/${rid}`);
      } else {
        prize = await apiGet(`/prizes/get/${pid}`);
      }

      const image = prizes.find((item) =>
        prize.data.type === 'grand' || prize.data.type === 'cash'
          ? item.value === prize.value
          : prize.data.type === 'product'
            ? item.value === prize.data.value
            : item.type === prize.data.type
      ).img;

      const additionals = prize.data.type === 'grand' ? 
          prize.data.additionals.reduce(
            (prev, curr, idx, arr) => 
                `${prev}${curr.type}-${curr.value}${idx === arr.length - 1 ? "" : ", "}`
          , "") : null;


      console.log(`additionals`, additionals)

      setPrizeData({
        ...prize.data,
        id: prize.data.type !== 'grand' ? pid : null,
        redeemCode: rid,
        image,
        additionals 
      });

      if ((additionals && additionals.includes('shirt')) || prize.data.type === 'shirt') {
        const shirtResponse = await apiGet('/prizes/shirts');
        setShirtSizes(shirtResponse.data.sizes);
      }

      const newData = {
        ...user,
        type: prize.data.type,
        redeemCode: rid,
        id: pid,
        additionals
      };

      setCombinedData(newData);
    } catch (err) {
      console.log(err);

      setErrorMessage(err?.response?.data?.message);
    }
  };

  // Startup effect authenticates token with API, co-ordinates animation
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const pid = urlParams.get('pid');
    const rid = urlParams.get('rid');

    let newUser = {};

    if (currentUser) {
      console.log(`currentUser`, currentUser);
      const {
        displayName,
        uid: user,
        email,
        phoneNumber: mobile,
      } = currentUser;
      const [firstName, lastName] = displayName.split('_');

      const normalNumber = mobile.replace('+61', '0');

      newUser = {
        firstName,
        lastName,
        email,
        mobile: normalNumber,
        user,
      };
    }

    getPrize(pid, rid, newUser);
  }, [currentUser]);

  return (
    <RedeemWrapper className='redeem-wrapper fullscreen'>
      <OverlayWrapper>
        <AnimatePresence exitBeforeEnter>
          {showProducts && (
            <motion.div
              className='product-display'
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <motion.div
                className='logo-wrapper'
                initial={titleDisplay.initial}
                animate={titleDisplay.animate}
              >
                <DelicousLogoWrapper />
              </motion.div>

              <motion.div
                initial={titleDisplay.initial}
                animate={titleDisplay.animate}
              >
                <WhitlockLogoWrapper />
              </motion.div>
              <div className='product-display-inner'>
                <img
                  src={ProductTexture}
                  alt='Louisiana Style Rub, Chipotle BBQ Sauce, Texas BBQ Style Marinade, Killer Beans Fuego Chilli'
                />
                <div className='product-shadow'></div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>

        <AnimatePresence>
          {prizeData != null && showPrize && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              {errorMessage ? (
                <h4 className='error-message'>{errorMessage}</h4>
              ) : (
                <CardTitle className='redeem-card-title'>
                  <h2 className='prize-minor'>{prizeData.copy.minor}</h2>
                  <h3 className='prize-major'>{prizeData.copy.major}</h3>
                </CardTitle>
              )}
            </motion.div>
          )}
        </AnimatePresence>

        <AnimatePresence>
          {showForm && combinedData != null && (
            <motion.div
              key='form'
              className='form-wrapper'
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <WhitlockLogoWrapper />
              <PaperWrapper>
                <motion.div
                  animate={showFormSubmitting ? formDisplay.exit : undefined}
                >
                  <MultiPageForm
                    heading='Congratulations'
                    subheading='Enter your details to claim your prize.'
                    data={RedeemFormPages}
                    prefill={combinedData}
                    submit={handleSubmit}
                    validators={validators}
                    formCondition={prizeData?.type}
                    shirtSizes={shirtSizes}
                  />
                  <div className='terms-conditions'>
                    <a
                      href='/terms-and-conditions'
                      title='Terms and Conditions'
                    >
                      T&C's
                    </a>
                  </div>
                </motion.div>

                {showThanks && (
                  <motion.div
                    className='thanks-wrapper fullscreen'
                    animate={thanksDisplay.animate}
                  >
                    <SuccessTitle title='Thanks!'>
                      <p>You've redeemed your prize!</p>
                    </SuccessTitle>
                  </motion.div>
                )}
              </PaperWrapper>
            </motion.div>
          )}
        </AnimatePresence>
      </OverlayWrapper>
    </RedeemWrapper>
  );
}

export default Redeem;
