import React, { useState, useEffect, useContext } from 'react'
import ReactGA from 'react-ga'
import styled from 'styled-components'
import { AnimatePresence, motion } from 'framer-motion';

import MuteButton from './MuteButton'
import LeaveConfirmation from './LeaveConfirmation';

import dividerImg from './../../../../assets/images/divider.svg';
import ornateImg from './../../../../assets/images/ornate.svg';
import CircularButton from '../../../../components/buttons/CircularButton'

import restartIcon from './../../../../assets/images/pause-menu/restart.svg';
import shareIcon from './../../../../assets/images/pause-menu/share.svg';
import facebookImg from './../../../../assets/images/pause-menu/facebook.svg';
import { GameContext } from '../../../../global/GameState';

const PauseMenuWrapper = styled.div`
  padding-top: 0.5em;

  .header {
    width: 100%;
    display: flex;
    padding: 0 0.5em;
    align-items: center;
    margin-bottom: 2em;

    &::after {
      content: '';
      flex: 0 0 30px;
      height: 30px;
    }

    .mute {
      flex: 0 0 30px;
    }

    p {
      flex: 1 1 100%;
      text-align: center;
      font-family: var(--altFont);
      font-style: italic;
      letter-spacing: 3px;
      font-size: 20px;
      margin: 0;
    }
  }

  .content-buttons {
    height: calc(100% - 180px);
    justify-content: space-between;
    font-family: var(--mainFont);

    .conversation {
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        margin-right: 10px;
        width: 22px;

      }
    }

    button {
      background-color: transparent;
      font-size: 32px;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: var(--black);
    }

    .divider,
    .ornate {
      width: 50px;
      align-self: center;
    }

    .ornate {
      width: 60px;
    }
  }

  .footer {
    height: 85px;
    display: flex;
    justify-content: space-around;
    position: absolute;
    bottom: 0;
    width: 60%;
    left: 50%;
    transform: translateX(-50%);
  }
`;

function PauseMenu({
  onProgressClick,
  onFAQClick,
  onTalesClick,
}) {
  const [showLeaveConfirmation, setShowLeaveConfirmation] = useState(false);
  const [showBtns, setShowBtns] = useState(false)
  const { currentPortal } = useContext(GameContext)

  const onConversationClick = () => {
    window.open('https://www.facebook.com/WhitlocksANZ/', '__blank');
  }

  const onRestartClick = () => {
    // TODO: Handle restart click
    window.location.reload();
  }

  const onLeaveClick = () => {
    setShowLeaveConfirmation(true);
  }

  const onShareClick = () => {
    if (!navigator.share) return;

    ReactGA.event({
      category: 'User',
      action: 'Share',
      label: 'Pause Menu'
    });

    navigator.share({
      url: 'https://deliciousdemise.com?utm_medium=native_share&utm_source=referral&utm_campaign=ar',
      title: 'F. Whitlocks & Sons',
    });
  }

  const onLeaveCancel = () => {
    setShowLeaveConfirmation(false);
  }

  const onLeaveConfirm = () => {
    setShowLeaveConfirmation(false);
  }

  useEffect(() => {
    if (currentPortal !== null) {
      setShowBtns(true)
    }
  }, [currentPortal])

  return (
    <PauseMenuWrapper
      className="pause-menu-wrapper fullscreen"
      as={motion.div}
      initial={{ x: "-100%" }}
      animate={{ x: "0%" }}
      exit={{ x: "-100%" }}
      transition={{ type: "Tween" }}
    >

      <div className="header">
        <div className="mute">
          <MuteButton></MuteButton>
        </div>
        <p>Menu</p>
        <div></div>
      </div>

      <div className="content-buttons f-col">
        <button onClick={onProgressClick}>Your progress</button>

        <img className="divider" src={dividerImg} alt="Divider" />

        <button onClick={onFAQClick}>Faq's</button>

        <img className="divider" src={dividerImg} alt="Divider" />

        <button onClick={onTalesClick}>Tales of delicious demise</button>

        <img className="divider" src={dividerImg} alt="Divider" />

        <div className="conversation">
          <img src={facebookImg} />
          <button onClick={onConversationClick}>Join the conversation</button>
        </div>

        <img className="ornate" src={ornateImg} alt="Ornate" />
      </div>

      <div className="footer">
        {showBtns && (
          <>
            <CircularButton onClick={onRestartClick} size={45} label="Restart">
              <img src={restartIcon} alt="Restart icon"  alt="Restart" />
            </CircularButton>
            {/* <CircularButton onClick={onLeaveClick} size={45} label="Leave">
              <img src={leaveIcon} alt="Leave icon" />
            </CircularButton> */}
          </>
        )}

        <CircularButton onClick={onShareClick} label="Share" size={45}>
          <img src={shareIcon} alt="Share icon" />
        </CircularButton>
      </div>

      <AnimatePresence>
        {showLeaveConfirmation && (
          <LeaveConfirmation
            onConfirmClick={onLeaveConfirm}
            onCancelClick={onLeaveCancel}
          />
        )}
      </AnimatePresence>
    </PauseMenuWrapper>
  )
}

export default PauseMenu
