export const validators = [
  (values) => {
    const errors = {};

    if (!values.firstName) {
      errors.firstName = "First Name is required";
    } else if (
      !/^[^±!@£$%^&*_+§¡€#¢§¶•ªº«\\/<>?:;|=.,]+$/.test(values.firstName)
    ) {
      errors.firstName = "Enter a valid first name";
    }

    if (!values.lastName) {
      errors.lastName = "Last Name is required";
    } else if (
      !/^[^±!@£$%^&*_+§¡€#¢§¶•ªº«\\/<>?:;|=.,]+$/.test(values.lastName)
    ) {
      errors.lastName = "Enter a valid last name";
    }

    if (!values.email) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(values.email)) {
      errors.email = "Email address is invalid";
    }

    if (!values.mobile) {
      errors.phone = "Mobile number is required";
    } else if (values.mobile.length < 10 || values.mobile.length > 10) {
      errors.phone = "Mobile needs to be 10 numbers long";
    }

    return errors;
  },
  (values) => {
    const errors = {};

    if (cashTypes.includes(values.type)) {
      if (!values.bsb.length) {
        errors.bsb = "BSB is required (no separators)";
      } else if (!/^\d{6}$/.test(values.bsb)) {
        errors.bsb = "BSB must be 6 digits (no separators)";
      }

      if (!values.accountNumber.length) {
        errors.accountNumber = "Account number is required (no separators)";
      } else if (!/\(|\)|\d{6}/.test(values.accountNumber)) {
        errors.accountNumber = "Account number must be at least 6 digits (no separators)";
      }
    }

    if (addressTypes.includes(values.type)) {
      // TODO Actual address validation
      if (!values.address.length) {
        errors.address = "Address is required";
      }

      if (!values.city.length) {
        errors.city = "City is required";
      }

      if (!values.state.length) {
        errors.state = "State is required";
      }

      if (!values.postcode.length) {
        errors.postcode = "Postcode is required";
      }
    }

    if ((values.type === prizeTypes.shirt ||
          values.additionals.includes(prizeTypes.shirt)
      ) && !values.size.length) {
      errors.size = "Shirt size is required";
    }

    return errors;
  }
];

export const prizeTypes = {
  cash: "cash",
  product: "product",
  shirt: "shirt",
  cap: "cap",
  grand: "grand",
};

const cashTypes = [
  prizeTypes.cash,
  prizeTypes.grand
];

const addressTypes = [
  prizeTypes.product,
  prizeTypes.shirt,
  prizeTypes.cap,
  prizeTypes.grand,
];
