import React, {
  createContext,
  useReducer,
  useContext,
  useState,
  useEffect,
} from 'react';
import { usePC } from '@unbnd-operations/react-hooks';
import ReactGA from 'react-ga';

import { UserContext } from './UserState';
import { ConfigContext } from './ConfigState';
import { tutorials } from '../data/tutorials';

import { apiGet } from '../api/axios';

import { getDocument, updateDocument } from '../firebase';

import portalReducer from './reducers/portal-reducer';
import { portals } from '../data/portals';

import {
  COMPLETE_PORTAL,
  COMPLETE_SCENE,
  MOVE_TO_SCENE,
  // COMPLETE_CHECKPOINT,
  UPDATE_INVENTORY,
  ENTER_PORTAL,
  // COMPLETE_CHALLENGE,
  GAME_READY,
  GAME_ERROR,
  CLAIM_PRIZE,
  BACK_TO_AR,
} from './action-types';

export const GameContext = createContext();

function GameState(props) {
  // Portal reducer
  function initalisePortals() {
    return {
      currentPortal: null,
      portals: portals.map((portal) => ({
        ...portal,
        inventory: [],
        completed: false,
        currentScene: 0,
        scenes: portal.scenes.map((scene) => ({
          ...scene,
          completed: false,
        })),
      })),
      prize: null,
      gameDataReady: false,
      gameDataFailed: false,
    };
  }

  const [state, dispatch] = useReducer(
    portalReducer,
    { portals },
    initalisePortals
  );

  // States
  const [currTutorialSection, setCurrTutorialSection] = useState(-1);
  const [tutorialStep, setTutorialStep] = useState(0);
  const [currTutorial, setCurrTutorial] = useState(null);
  const [tutorialCompleted, setTutorialCompleted] = useState(true); // TRUE for internal demo
  const [showZoomBtn, setShowZoomBtn] = useState(false);
  const [showHighlightCrosshair, setShowHighlightCrosshair] = useState(false);
  const [firstNewItemFound, setFirstNewItemFound] = useState(false);
  const [firstInventoryInteract, setFirstInventoryInteract] = useState(false);
  const [audioMuted, setAudioMuted] = useState(false);
  const [showPrizeScreen, setShowPrizeScreen] = useState(false);
  const [prizeClaimed, setPrizeClaimed] = useState(false);

  // User context
  const { currentUser, triggerSignUp, isUserReady } = useContext(UserContext);
  const { setupDone } = useContext(ConfigContext);

  // Debug
  useEffect(() => {
    console.log(state);
  }, [state]);

  /**
   * Complete portal
   */
  function completePortal() {
    const updatedPortals = state.portals.map((portal, idx) =>
      idx === state.currentPortal
        ? {
            id: portal.id,
            name: portal.name,
            currentScene: 0,
            completed: portal.completed,
          }
        : {
            id: portal.id,
            name: portal.name,
            currentScene: portal.currentScene,
            completed: portal.completed,
          }
    );

    dispatch({
      type: COMPLETE_PORTAL,
    });

    if (!currentUser) return;

    updateDocument('user_progress', currentUser.uid, {
      portals: updatedPortals,
      dailyPrize: state.prize,
    });
  }

  usePC({
    event: 'portal:complete',
    callback: completePortal,
  });

  /**
   * Update inventory
   */
  function updateInventory({ items }) {
    dispatch({
      type: UPDATE_INVENTORY,
      payload: items,
    });
  }

  usePC({
    event: 'inventory:update',
    callback: updateInventory,
  });

  /**
   * Move to scene
   */
  const moveToScene = ({ name }) => {
    // Complete previous scene if all checkpoints are reached
    const updatedPortals = state.portals.map((portal, idx) =>
      idx === state.currentPortal
        ? {
            id: portal.id,
            name: portal.name,
            currentScene: portal.scenes.findIndex(
              (scene) => scene.name === name
            ),
            completed: portal.completed,
          }
        : {
            id: portal.id,
            name: portal.name,
            currentScene: portal.currentScene,
            completed: portal.completed,
          }
    );

    dispatch({
      type: COMPLETE_SCENE,
      payload: state.portals[state.currentPortal].currentScene,
    });

    dispatch({
      type: MOVE_TO_SCENE,
      payload: name,
    });

    ReactGA.event({
      category: "Game",
      action: "Move to scene",
      label: `${state.portals[state.currentPortal].name}-${name}`
    });

    if (!currentUser) return;

    updateDocument('user_progress', currentUser.uid, {
      portals: updatedPortals,
      dailyPrize: state.prize,
      tutorialCompleted: tutorialCompleted ? true : false,
    });
  };

  // useEffect(() => {
  //   if (state.currentPortal === null) return;
  //   if (state.portals[state.currentPortal].currentScene !== 1) return;

  //   triggerSignUp();
  // }, [state]);

  const [homePortal] = usePC({
    event: 'scene:portal',
  });

  function backToHome() {
    dispatch({
      type: BACK_TO_AR,
    });

    homePortal();
  }

  // const [nextPortal] = usePC({
  //   event: 'scene:switch',
  // });

  // Enter the portal
  const enterPortal = ({index}) => {
    console.log(index);
    
    dispatch({
      type: ENTER_PORTAL,
      payload: index,
    });

    ReactGA.event({
      category: "Game",
      action: "Enter portal",
      label: state.portals[index].name
    });
  };

  useEffect(() => {
    if (!setupDone) return;

    if (state.currentPortal === null) {
      document.getElementById('application-canvas').style.pointerEvents =
        'auto';
    } else {
      document.getElementById('application-canvas').style.pointerEvents =
        'none';
    }
  }, [state.currentPortal, setupDone]);

  usePC({
    event: 'portal:exit',
    callback: enterPortal,
    debug: true
  });

  /**
   * Setup the game/get previous game data for the user
   */
  async function setupGame() {
    try {
      await apiGet('/game/user');

      const userProgress = await getDocument('user_progress', currentUser.uid);

      if (!userProgress) {
        return;
      }

      const userProgressFix = {
        ...userProgress,
        portals: userProgress.portals.map((portal) => ({
          ...portal,
          currentScene: 0,
        })),
      };

      if (userProgress.dailyPrize.isClaimed) {
        setPrizeClaimed(true);
      }

      // TODO: Fix with Zan's tutorial
      // Update tutorialProgress
      if (userProgress.tutorialCompleted) {
        setTutorialCompleted(true);
      }

      dispatch({
        type: GAME_READY,
        payload: userProgressFix,
      });
    } catch (err) {
      console.log(err);

      dispatch({
        type: GAME_ERROR,
      });
    }
  }

  /**
   * Handle all prize logic
   */
  useEffect(() => {
    if (!currentUser) return;
    if (!isUserReady) return;
    if (state.gameDataReady) return;

    setupGame();
  }, [currentUser, isUserReady]);

  const [setPrize] = usePC({
    event: 'prize:set',
  });

  function showPrize() {
    if (prizeClaimed) return;
    setShowPrizeScreen(true);
  }

  function hidePrize() {
    setShowPrizeScreen(false);
  }

  async function claimPrize() {
    if (prizeClaimed) return;

    if (state.prize.id === 'recipe') {
      await apiGet('/prizes/recipe')
        .then((response) => {
          setPrizeClaimed(true);
          dispatch({
            type: CLAIM_PRIZE,
          });
        })
        .catch((err) => {
          console.log(err);
          return false;
        });
    } else {
      const updated = await updateDocument('prizes', state.prize.id, {
        claimed: true,
      });

      if (!updated) {
        return false;
      }
    }

    setPrizeClaimed(true);
    return true;
  }

  usePC({
    event: 'prize:trigger',
    callback: showPrize,
  });

  // TODO: Fix this with Ben
  // const [sendProgress] = usePC({
  //   event: 'user:progress',
  //   once: true,
  // })

  const getCodeData = async () => {
    const data = await getDocument('game_data', 'chest_code');

    if (!data) {
      console.log('Chest has already been claimed');
      return;
    }

    const chestCode = data.str;
    window.pc.app.code = chestCode;
  };

  // useEffect(() => {
  //   if (!setupDone) return;

  //   const tutorialDone = window.localStorage.getItem('tutorialDone');

  //   if (tutorialDone) {
  //     setTutorialCompleted(true);
  //   }

  //   getCodeData();
  // }, [setupDone]);

  useEffect(() => {
    if (!state.gameDataReady) return;
    if (!state.prize || state.prize.id === null) return;
    if (!setupDone) return;

    setPrize({ name: state.prize.location.entity });

    // TODO: Fix this with Ben
    // const pcPortalData = state.portals.map(portal => ({
    //   id: portal.id,
    //   name: portal.name,
    //   currentScene: portal.currentScene
    // }));

    // sendProgress({portals: pcPortalData});
  }, [state.gameDataReady, setupDone]);

  /**
   * Toggle Mute
   */
  const toggleMute = () => {
    setAudioMuted(!audioMuted);
  };

  /**
   * Tutorial Functions
   */
  const triggerNextTutorial = () => {
    setTutorialStep(tutorialStep + 1);
  };

  const setZoomTutorial = () => {
    setTutorialStep(0);
    setCurrTutorialSection(1);
    setShowZoomBtn(true);
  };

  // usePC({
  //   event: 'tutorial:zoom',
  //   callback: setZoomTutorial
  // });

  const setInventoryTutorial = () => {
    setTutorialStep(0);
    setCurrTutorialSection(2);
  };

  useEffect(() => {
    if (currTutorialSection !== 0) return;
    if (tutorialStep < 4) return;

    setZoomTutorial();
  }, [currTutorialSection, tutorialStep]);

  const [unlockPortalTwo] = usePC({
    event: 'portal:unlock',
  });

  useEffect(() => {
    if (!tutorialCompleted) return;
    if (!setupDone) return;

    window.localStorage.setItem('tutorialDone', true);

    unlockPortalTwo();
  }, [tutorialCompleted, setupDone]);

  useEffect(() => {
    let tutorialTimeOut;
    if (state.currentPortal === 0 && !tutorialCompleted) {
      tutorialTimeOut = setTimeout(() => {
        setCurrTutorialSection(0);
      }, 3000);
    }

    return () => clearTimeout(tutorialTimeOut);
  }, [state.currentPortal, tutorialCompleted]);

  useEffect(() => {
    if (tutorialCompleted) return;
    if (currTutorialSection === 2 && tutorialStep > 1) {
      setTutorialCompleted(true);
    }
  }, [currTutorialSection, tutorialStep]);

  useEffect(() => {
    if (tutorialCompleted) return;
    if (currTutorialSection < 0) return;
    setCurrTutorial(tutorials[currTutorialSection]);
  }, [currTutorialSection]);

  // TODO: Fix this with Ben
  useEffect(() => {
    if (tutorialCompleted) return;
    if (currTutorialSection !== 0) return;
    if (tutorialStep === 1) {
      setShowHighlightCrosshair(true);
    }
    if (tutorialStep > 1 && currTutorialSection === 0) {
      setShowHighlightCrosshair(false);
    }
  }, [tutorialStep, currTutorialSection]);

  return (
    <GameContext.Provider
      value={{
        ...state,
        toggleMute,
        audioMuted,

        tutorialStep,
        currTutorialSection,
        currTutorial,
        triggerNextTutorial,
        tutorialCompleted,
        setInventoryTutorial,
        showZoomBtn,
        setShowZoomBtn,

        setShowHighlightCrosshair,
        showHighlightCrosshair,

        moveToScene,
        enterPortal,
        backToHome,

        firstNewItemFound,
        setFirstNewItemFound,

        firstInventoryInteract,
        setFirstInventoryInteract,

        hidePrize,
        claimPrize,
        showPrizeScreen,
        prizeClaimed,
      }}
    >
      {props.children}
    </GameContext.Provider>
  );
}

export default GameState;
