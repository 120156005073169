import React from 'react'
import { motion } from 'framer-motion'
import Form from '../../../../components/form/Form'
import PaperWrapper from '../../../../components/layout/PaperWrapper'

const TFAOBJ = [
  {
    title: 'Code',
    placeholder: "Code",
    name: 'code',
    type: 'number',
    value: '',
    borderBottom: true
  }, {
    title: 'Submit',
    type: 'submit',
    borderBottom: false
  }
]

const validateTFA = values => {
  let errors = {};

  if (!values.code) {
    errors.code = 'Two-Factor Code is required';
  }

  return errors;
};

function TFA({ initial, animate, exit, submit, authCodeErr }) {
  console.log(`authCodeErr`, authCodeErr)
  return (
    <motion.div
      className="tfa-wrapper fullscreen"
      initial={initial}
      animate={animate}
      exit={exit}
    >
      <PaperWrapper>
        <Form
          heading="Enter your six digit code"
          data={TFAOBJ}
          validation={validateTFA}
          submit={submit}
        />
        {authCodeErr && (
          <span className="error-message-code">{authCodeErr}</span>
        )}
      </PaperWrapper>
    </motion.div>
  )
}

export default TFA
