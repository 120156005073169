import React from 'react'
import styled from 'styled-components'

const ProgressBarWrapper = styled.div`
  display: flex;

  width: 100%;

  justify-content: center;
  align-items: center;

  p {
    margin: 0;
    margin-right: 15px;

    font-family: var(--altFont);
    font-size: 24px;
  }

  img {
    width: 60vw;
  }

  .progress-wrapper {
    position: relative;
    width: 162px;
    height: 8px;
  }
  
  .bar {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    background-color: #fff;
    border-radius: 4px;
  }

  .bg-bar {
    z-index: 1;
    background-color: #fff;
    width: 100%;
  }

  .fg-bar {
    z-index: 2;
    background-color: #C32033;
    width: ${props => props.progressVal}%;
    transition: width 300ms linear;
    ${'' /* animation: fillBar 300ms linear;
    animation-iteration-count: 1; */}
  }

  ${'' /* @keyframes fillBar {
    0% {
      width: 0;
    }
    100% {
      width: ${props => props.progressVal}%;
    }
  } */}
`;

function ProgressBar({ value, completed, className }) {
  return (
    <ProgressBarWrapper className={className} progressVal={completed ? "100" : value}>
      <p>{completed ? "100" : value}%</p>
      <div className='progress-wrapper'>

        <div className="bar bg-bar"></div>
        <div className="bar fg-bar"></div>

      </div>

    </ProgressBarWrapper>
  )
}

export default ProgressBar
