import React, { useState, useContext } from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import 'keen-slider/keen-slider.min.css'
import { useKeenSlider } from 'keen-slider/react'
import { GameContext } from '../../../../../global/GameState';

import Header from '../Header/Header';
import ProgressBar from './ProgressBar';
import PaperWrapper from './../../../../../components/layout/PaperWrapper';

import ornateImg from './../../../../../assets/images/ornate.svg';

const ProgressWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;

  .pages {
    pointer-events: auto;
    width: 100%;
    height: calc(100% - 220px);

    .keen-slider__slide {
      height: 100%;

      .paper-wrapper {
        background-color: transparent;
        filter: drop-shadow( 0px 0px 5px rgba(0, 0, 0, .4));

        height: 100%;

        .paper-body {
          height: 100%;
        }

        .paper-inner-wrapper {
          height: 100%;
        }

        .paper-inner-wrapper img {
          height: 100%;
        }
      }
    }

    .card-img {
      height: 100%;
      width: 100%;
    }
  }

  .footer {
    position: absolute;
    width: 100%;
    height: 140px;
    bottom: 0px;
    justify-content: space-around;
    align-items: center;

    .ornate {
      width: 60px;
    }
  }
`;

function Progress({ onBackClick }) {
  const {portals} = useContext(GameContext);
  const [cPortal, setCPortal] = useState(portals[0]); 
  const [details, setDetails] = useState(null);

  const [sliderRef] = useKeenSlider({
    slides: portals.length,
    move(s) {
      setDetails(s.details()) 
    },
    slideChanged: slider => {
      const slide = slider.details().absoluteSlide;
      setCPortal(portals[slide]); 
    },
    initial: 0,
  });

  const currentProgress = Math.floor((cPortal.scenes.filter(scene => scene.completed).length / cPortal.scenes.length) * 100);

  function positionStyle(idx) {
    if (!details) return {}

    const position = details.positions[idx]
    const x = details.widthOrHeight * position.distance
    const scale_size = 0.6
    const scale = 1 - (scale_size - scale_size * position.portion)

    return {
      transform: `translate3d(${x}px, 0px, 0px) scale(${scale})`,
      WebkitTransform: `translate3d(${x}px, 0px, 0px) scale(${scale})`,
    }
  }

  return (
    <ProgressWrapper
      className="fullscreen"
      as={motion.div}
      key="progress"
      initial={{ x: "100%" }}
      animate={{ x: "0%" }}
      exit={{ x: "100%" }}
      transition={{ type: "Tween" }}
    >

      <Header onBackClick={onBackClick} title="Your progress" />

      <div ref={sliderRef} className="keen-slider zoom-out pages">
        {portals.map((portal, idx) => (
          <div
            key={idx}
            style={positionStyle(idx)}
            className="keen-slider__slide zoom-out__slide"
          >
            <PaperWrapper ornate={false} className="progress-paper">
              <img className="card-img" src={portal.cardImage} alt="Portal thumbnail" />
            </PaperWrapper>
          </div>
        ))}
      </div>

      <div className="footer f-col">
        <ProgressBar value={currentProgress} completed={cPortal.completed}></ProgressBar>
        <img className="ornate" src={ornateImg} />
      </div>
    </ProgressWrapper>
  )
}

export default Progress
