import React from 'react'
import styled from 'styled-components'

import SpeechBubbleBG from '../../assets/images/speech-bubble/speech-bubble.svg'
import SpeechBubbleBGNoTail from '../../assets/images/speech-bubble/speech-bubble-no-tail.svg'
import ThreeWhiteDots from '../../assets/images/speech-bubble/three-white-dots.svg'

const SpeechBubbleWrapper = styled.div `
  position: absolute;

  .speech-bubble-inner {
    position: relative;
    background: transparent;
    color: var(--white);
    font-family: var(--mainFont);
    padding: 16px 14px 20px;

    .background-shape {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
      width: 100%;
      height: 100%;
    }
    .speech-copy {
      font-family: var(--copyFont);
      color: var(--white);
      z-index: 1;
      text-align: center;
      margin: 0;
      font-size: 0.75em;
      flex: 1 1 100%;
    }
    .three-white-dots{
      flex: 0 1 20px;
    }
  }
  .three-white-dots {
    z-index: 1;
  }
`

function SpeechBubble({children, tail = true, className}) {
  return (
    <SpeechBubbleWrapper className={`speech-bubble-wrapper ${className ? className : ''}`}>
      <div className="speech-bubble-inner fullscreen f-col f-center">
        <img className="background-shape" src={tail ? SpeechBubbleBG : SpeechBubbleBGNoTail} alt="Shape BG" />
        <p className="speech-copy">{children}</p>
        <img className="three-white-dots" src={ThreeWhiteDots} alt="Three White Dots" />
      </div>
    </SpeechBubbleWrapper>
  )
}

export default SpeechBubble
