import { initializeApp } from "firebase/app";
import { 
  getFirestore, 
  Timestamp, 
  connectFirestoreEmulator, 
  getDoc,
  updateDoc,
  doc 
} from "firebase/firestore";
import { getAuth, connectAuthEmulator } from 'firebase/auth'

const app = initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID
});

// Firebase
export default app;

// Firestore
export const db = getFirestore(app);
export const timeStamp = Timestamp;

export const getDocument = async (collection, docId) => {
  try {
    const docRef = doc(db, collection, docId);
    const snapshot = await getDoc(docRef);

    if (!snapshot.exists()) {
      return null;
    }

    return snapshot.data();
  } catch (err) {
    console.log(err);
  }
}

export const updateDocument = async (collection, docId, data) => {
  try {
    const docRef = doc(db, collection, docId);
    await updateDoc(docRef, {
      ...data,
    });

    return true;
  } catch (err) {
    console.log(err);

    return false;
  }
}

// Auth
export const auth = getAuth();
auth.useDeviceLanguage();

// if (window.location.hostname.includes("localhost")) {
//   connectAuthEmulator(auth, "http://localhost:9099");
//   connectFirestoreEmulator(db, "http://localhost:8080");
// }
