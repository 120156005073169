import React, { useContext, useState, useEffect } from 'react'
import styled from 'styled-components'
import {motion, AnimatePresence } from 'framer-motion'
import { useAnglePlacement, useTimeout } from '@unbnd-operations/react-hooks';

import { ConfigContext } from '../../../../global/ConfigState'

import PlacementOrnate from '../../../../assets/images/general-icons/placement-ornate.svg'
import CircularButton from '../../../../components/buttons/CircularButton';
import OverlayWrapper from '../../../../components/layout/OverlayWrapper';
import CardTitle from '../../../../components/typography/CardTitle'

import Tick from '../../../../assets/images/circular-btn-icons/tick.svg'
import NextArrow from '../../../../assets/images/circular-btn-icons/next-arrow.svg'
import { setupFade } from '../../../../animation';
import ButtonLabel from '../../../../components/misc/ButtonLabel';

const SetupWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  justify-content: flex-end;
  text-align: center;
  pointer-events: none;
  background: rgba(0,0,0,0.25);

  .overlay-wrapper {
    pointer-events: auto;

    .inner {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
    }

    .circular-btn-wrapper {
      position: absolute;
      left: 50%;
      bottom: 30px;
      transform: translateX(-50%);
    }
  }


  .card-title-wrapper {
    .title {
      font-family: var(--mainFont);
      text-transform: uppercase;
      font-size: 2.675em;
      letter-spacing: 0.5px;
      margin-bottom: 2px;
    }

    p {
      font-style: italic;
      font-size: 1.25em;
    }

    span {
      font-weight: 700;
    }
  }

  .place-controller {
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    pointer-events: auto;
    z-index: 20;
  }

  .instruction {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
    align-items: center;
    padding-bottom: 1.5em;

    p {
      color: #ffffff;
      font-family: var(--mainFont);
      text-transform: uppercase;
      font-size: 1.5625em;
      margin-bottom: .25em;

      &.small {
        font-size: 1.3125em;
      }
    }

    .circular-btn-wrapper {
      pointer-events: auto;
      margin-bottom: 1em;
    }
  }
  .button-label-wrapper {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    p{
      margin: 0;
    }
  }
  .safety-screen {
    background-color: #fff;
  }
`;


function Setup() {
  const {
    arFound,
    arPlaced,
    placeAR,
    completeSetup
  } = useContext(ConfigContext);

  const [showSafetyPrompt, setShowSafetyPrompt] = useState(false);
  const [safetyPromptAccepted, setSafetyPromptAccepted] = useState(false);
  // const [showCheckbox, setShowCheckbox] = useState(false);

  const [isPlaceable, isUnder] = useAnglePlacement({min: 15, max: 50})

  const {reset: resetCheckbox, clear: clearCheckbox} = useTimeout(() => {
    // setShowCheckbox(true);
    handleCompleteClick();
  }, 2000);

  const handlePlace = () => {
    if(!arFound) return;

    placeAR(true);
    resetCheckbox();
  }

  const safetyPromptClick = () => {
    setShowSafetyPrompt(false);
    setSafetyPromptAccepted(true)
  }

  const handleCompleteClick = () => {
    completeSetup();
  }

  useTimeout(() => {
    setShowSafetyPrompt(true);
  }, 500);

  useEffect(() => {

    clearCheckbox();
  }, []);

  // useEffect(() => {
  //   console.log(`isPlaceable`, isPlaceable)
  //   console.log(`isUnder`, isUnder)
  // }, [isPlaceable, isUnder])

  return (
    <SetupWrapper id="setup-screen" className="fullscreen f-col">

      <AnimatePresence>
      {!safetyPromptAccepted ? (
        <>
          {showSafetyPrompt && (
            <motion.div
              className="fullscreen safety-screen"
              initial={false}
              animate={setupFade.visible}
              exit={setupFade.hidden}
            >
              <OverlayWrapper>
                <CardTitle>
                  <h2 className="title">Safety first</h2>
                  <p className="content">Find a <span>3m x 3m</span> clear area to place the portal door infront of you.</p>
                </CardTitle>

                <CircularButton size={42} onClick={safetyPromptClick}>
                  <img src={NextArrow} alt="Right arrow"/>
                </CircularButton>
              </OverlayWrapper>
            </motion.div>
          )}
        </>
      ) : (
        <>
          {arFound && !arPlaced && (
            <>
              <div className="place-controller" onClick={handlePlace}></div>

              {isPlaceable && (
                <ButtonLabel variant="green">
                  <p>Perfect</p>
                </ButtonLabel>
              )}

              {!isPlaceable && (
                <ButtonLabel variant={isPlaceable ? "green" : "red"}>
                  <>
                    {isUnder && (
                      <p>Too low, tilt your phone higher</p>
                    )}
                    {!isUnder && (
                      <p>Too high, tilt your phone lower</p>
                    )}
                  </>
                </ButtonLabel>
              )}
            </>
          )}

          <motion.div
            initial={setupFade.hidden}
            animate={setupFade.visible}
            exit={setupFade.hidden}
            className="instruction"
          >
            <img src={PlacementOrnate} alt="Ornate" />
            <AnimatePresence>
              {!arFound && (
                <motion.div
                  initial={setupFade.hidden}
                  animate={setupFade.visible}
                  exit={setupFade.hidden}
                >
                  <p>Looking for a flat surface</p>
                  <p className="small">Keep panning</p>
                </motion.div>
              )}
            </AnimatePresence>

            <AnimatePresence>
            {arFound && !arPlaced && (
              <motion.div
                initial={setupFade.hidden}
                animate={setupFade.visible}
                exit={setupFade.hidden}
              >
                {isPlaceable ? (
                  <>
                    <p>Perfect, now tap to place</p>
                    <p className="small">the portal in front of you</p>
                  </>
                ) : (
                  <>
                    <p>Stand up and point</p>
                    <p className="small">the camera at the floor</p>
                  </>
                )}
              </motion.div>
            )}
            </AnimatePresence>

            <AnimatePresence>
            {arFound && arPlaced && (
              <motion.div
                initial={setupFade.hidden}
                animate={setupFade.visible}
                exit={setupFade.hidden}
              >
                <p>Pinch, drag and move</p>
                <p className="small">To resize</p>
              </motion.div>
            )}
            </AnimatePresence>

            {/* <AnimatePresence>
            {arFound && arPlaced && (
              <motion.div
                initial={setupFade.hidden}
                animate={setupFade.visible}
                exit={setupFade.hidden}
              >
                <CircularButton size={50} onClick={handleCompleteClick}>
                  <img src={Tick} alt="Tick Icon"/>
                </CircularButton>
              </motion.div>
            )}
            </AnimatePresence> */}

          </motion.div>
        </>
      )}
      </AnimatePresence>
    </SetupWrapper>
  )
}

export default Setup
