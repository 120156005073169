import React from 'react'
import styled from 'styled-components'

import ButtonLabel from '../misc/ButtonLabel'

import Black1 from '../../assets/images/circular-outline-btn/type1-black.svg'
import Red1 from '../../assets/images/circular-outline-btn/type1-red.svg'
import Black2 from '../../assets/images/circular-outline-btn/type2-black.svg'
import Red2 from '../../assets/images/circular-outline-btn/type2-red.svg'
import Black3 from '../../assets/images/circular-outline-btn/type3-black.svg'
import Red3 from '../../assets/images/circular-outline-btn/type3-red.svg'

const blackShapes = [
  Black1,
  Black2,
  Black3
];

const redShapes = [
  Red1,
  Red2,
  Red3
];

const CircularOutlineButtonWrapper = styled.div`
  text-align: center;
    button {
      width: ${props => props.size ? `${props.size}px` : '100%'};
      height: ${props => props.size ? `${props.size}px` : 'auto'};
      aspect-ratio: 1 / 1;
      position: relative;
      background: transparent;

      > img {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        width: 100%;
        height: 100%;
        z-index: 0;
      }

      > div {
        position: relative;
        z-index: 1;
        width: 100%;
        height: 100%;
        display: grid;
        place-items: center;
        color: #fff;
        text-transform: uppercase;
        font-size: 1.750rem;
      }
    }
  .button-label-wrapper {
    margin-bottom: 16px;
  }
`

function CircularOutlineButton({
  children,
  variant,
  onClick,
  label,
  size,
  className,
  id,
  type = 1
}) {
  return (
    <CircularOutlineButtonWrapper
      size={size}
      className={`btn circular-outline-btn ${className ? className : ''}`}
      id={id}
      onClick={onClick}
    >

      {label && (
        <ButtonLabel>
          {label}
        </ButtonLabel>
      )}

      <button>
        <img src={variant === "black"
          ? blackShapes[type - 1]
          : redShapes[type - 1]
        } alt="Shape Background" />

        <div>{children}</div>
      </button>

    </CircularOutlineButtonWrapper>
  )
}

export default CircularOutlineButton
