import React from 'react'
import styled from 'styled-components'

import BlackSquareShape from '../../assets/images/square-btn-shapes/black-square-shape.svg'
import RedSquareShape from '../../assets/images/square-btn-shapes/red-square-shape.svg'

const StyledButton = styled.button `
  width: auto;
  height: auto;
  position: relative;
  background: transparent;
  ${'' /* padding: 0.5rem 1rem; */}
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white);
  font-family: var(--mainFont);
  text-transform: uppercase;
  font-size: ${props => props.isLarge ? 1.714 : 1.43}em;
  padding: ${props => props.isLarge ? "0.6em 1.25em" : "0.4em 2em"};

  .background-shape {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    width: 100%;
    height: 100%;
  }

  > span {
    z-index: 1;
    position: relative;
  }
  .icon {
    margin-right: 12px;
  }
`

function SquareButton({children, onClick, variant, isLarge, icon}) {
  return (
    <StyledButton isLarge={isLarge} className="btn square-btn" onClick={onClick}>
      <img className='background-shape' src={variant === 'red' ? RedSquareShape : BlackSquareShape} alt="Shape BG" />
      <span>
        {icon && (
          <img className='icon' src={icon} alt='Button Icon' />
        )}
        {children}
      </span>
    </StyledButton>
  )
}

export default SquareButton
