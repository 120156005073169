import axios from 'axios'

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;
axios.defaults.headers.post['Content-Type'] = 'application/json';

export async function apiPost(url, data) {
  return new Promise((resolve, reject) => {
    axios({method: 'post', url, data})
        .then(response => resolve(response))
        .catch(error => reject(error));
  }) 
}

export async function apiGet(url) {
  return new Promise((resolve, reject) => {
    axios({method: 'get', url})
        .then(response => resolve(response))
        .catch(error => reject(error));
  });
}