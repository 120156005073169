import React from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion';
import { useTimeout } from '@unbnd-operations/react-hooks'

// import CardTitle from '../../../../components/typography/CardTitle';
// import CircularButton from '../../../../components/buttons/CircularButton';
// import OverlayWrapper from '../../../../components/layout/OverlayWrapper';

import { setupFade } from '../../../../animation';

// import NextArrow from '../../../../assets/images/circular-btn-icons/next-arrow.svg'
import blackBG from '../../../../assets/images/tutorial/tutorial-bg.png'

const WelcomeScreen = styled.div`
  .overlay-wrapper {
    pointer-events: auto;
    
    .inner {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
    }

    .circular-btn-wrapper {
      position: absolute;
      left: 50%;
      bottom: 30px;
      transform: translateX(-50%);
      opacity: 0;
      pointer-events: none;
      transition: opacity 800ms linear;

      &.active {
        opacity: 1;
        pointer-events: auto;
      }
    }

    ${'' /* h2 {
      font-family: var(--mainFont); 
      font-size: 2.75em;
      margin-bottom: 0.125em;
    }

    p {
      font-style: italic;
      font-size: 1.1875em;
      line-height: 1.2;

      span {
        display: block;
        font-weight: 700;
      }
    } */}
  }
  .inner-wrapper {
    position: absolute;
    bottom: 20px;
    padding: 0 20px;
    > img {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
      width: 90%;
      height: 100%;
    }

    .copy {
      color: #fff;
      font-family: var(--mainFont);
      text-transform: uppercase;
      font-size: 1.5em;
      padding: 1.625em 0.625em;
      text-align: center;
      position: relative;
      z-index: 1;
      margin: 0;
    }
  }
`;

function Welcome({handleClose, prompt}) {
  useTimeout(() => {
    handleClose();
  }, 4000);

  return (
    <WelcomeScreen
      as={motion.div}
      className="fullscreen"
      initial={setupFade.hidden}
      animate={setupFade.visible}
      exit={setupFade.hidden}
    >
      <div className="inner-wrapper">
        <img src={blackBG} alt="black BG" />
        <p className='copy'>{prompt}</p>
      </div>
    </WelcomeScreen>
  )
}

export default Welcome
