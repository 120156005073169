import React from 'react'
import styled from 'styled-components'

import loaderImage from '../../assets/images/loading-screen/loader.png'


const IsLoadingWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 999;

  .loader {
    backdrop-filter: blur(5px);
    position: relative;
  }
  .loading-image {
    animation: rotate 1s infinite;
    animation-timing-function: linear;
  }

  .loading-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    .loading-image {
      margin-bottom: 42px;
    }
    p {
      font-family: var(--altFont);
      text-transform: uppercase;
      font-size: 1.5rem;
    }
  }

`

function IsLoading() {
  return (
    <IsLoadingWrapper className='loader fullscreen'>
      <div className='loading-wrapper'>
        <img className="loading-image" src={loaderImage} alt="Loading Image" />
        <p>Submitting...</p>
      </div>
    </IsLoadingWrapper>
  )
}

export default IsLoading
