import React from 'react'
import styled from 'styled-components'

import Empty from '../../assets/images/inventory-icons/empty-icon.png'
import Frame from '../../assets/images/inventory-icons/frame.svg'

const InvtentoryWrapper = styled.div`
  width: ${props => props.width ? `${props.width}px` : '100%'};
  height: auto;
  aspect-ratio: 1 / 1;
  padding: .75em;
  background: var(--black);
  position: relative;

  .frame {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    min-width: 68px;
    min-height: 68px;
  }

  .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
  }

  &.empty-slot {
    .frame {
      opacity: 0.3;
    }

    .icon {
      width: 60%;
      opacity: 0.3;
    }
  }
`;

function InventoryItem({children, width}) {
  return (
    <InvtentoryWrapper className={!children ? 'empty-slot' : ''} width={width}>
      <img className="frame" src={Frame} alt="Inventory frame" />

      <div className="icon">
        {children ? children : (
          <img src={Empty} alt="Empty item slot" />
        )}
      </div>
    </InvtentoryWrapper>
  )
}

export default InventoryItem
