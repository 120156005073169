import React from 'react'

function InputField({
  type,
  placeholder,
  name,
  title,
  handleChange,
  error,
  currentValue,
  className,
  shirtSizes
}) {
  return (
    <div className={`
      form-group 
      ${className ? className : ''} 
      ${type === "hidden" ? "hidden" : ""}
    `}>
      {(type === 'checkbox' || type === 'radio') ? (
        <>
          {{
            "checkbox": (
              <div className="checkbox-wrapper">
                <input
                  type={type}
                  placeholder={placeholder}
                  name={name}
                  onChange={e => handleChange(e)}
                  value={currentValue}
                />
                <div className='custom-checkbox'></div>
                <label className='checkbox-label'>{title}</label>
              </div>
            ),
            "radio": (
              <div className="radio-wrapper">
                {shirtSizes?.map((size, index) => (
                  <div className='radio-inner-wrapper' key={index}>
                    <input 
                      type={type} 
                      name={name} 
                      id={size.id} 
                      value={size.id} 
                      onChange={e => handleChange(e)} 
                    />
                    <div className='custom-radio'></div>
                    <label className='radio-label'>{size.short}</label>
                  </div>
                ))}
              </div>
            )
          }[type]}
        </>
      ) : (
        <input
          type={type}
          placeholder={placeholder}
          name={name}
          onChange={e => handleChange(e)}
          value={currentValue}
        />
      )}

      <span></span>
      {error && <span className="error-message">{error}</span>}
    </div>
  )
}

export default InputField
