import React, { useState, useContext } from 'react'
import { usePC } from '@unbnd-operations/react-hooks';
import { ConfigContext } from '../../../global/ConfigState';
import ProgressBar from '../Experience/Pause/Progress/ProgressBar';

function ProgressBarLoader() {
  const { sceneReady } = useContext(ConfigContext)
  const [progressVal, setProgressVal] = useState(0)
  usePC({
    event: 'preload:progress',
    callback: progress => {
      setProgressVal(progress);
    }
  });

  return (
    <ProgressBar className='loading-progress-bar' value={Math.floor(progressVal * 100)} completed={sceneReady}></ProgressBar>
  )
}

export default ProgressBarLoader
