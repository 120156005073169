export const tutorials = [
    [
      "Hey Chip, ready to take the road less travelled and join the grim reapers? It's time to initiate your delicious demise!",
      "Point and aim at the footprint icons to <span>MOVE</span> <img src='/assets/images/footprint-icon.svg' alt='footprint icon'/> location, use the large button to <span>PICKUP</span> <img src='/assets/images/hand-icon.svg' alt='hand icon'/> items",
      "<span>INTERACT</span> <img src='/assets/images/speech-bubble-icon.svg' alt='mag icon'/> with characters and objects for clues on how to reach your delicious demise.",
      "Time to start, find your motorcycle keys"
    ], [
      `Use the Magnify Glass <img src='/assets/images/mag-icon.svg' alt='mag icon'/> to zoom in to objects that are far away. `
    ], [
      "Each time you find an item it is stored here.",
      "Feel lost? View the challenge list to help you find your way."
    ]
]