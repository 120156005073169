// Set innerHTML
export function setHTML(content) {
  return {
    __html: content
  }
}

// Handle link outs
export function linkOut(url, sameTab = false) {
  window.open(url, sameTab ? '_self' : '_blank');
}

// Have a coffee break
export function coffeeBreak (time) {
	return new Promise(resolve => {
	setTimeout(() => {
			resolve();
	}, time);
	});
}

export function normalizeNumber(number) {
	number = number.replace(/[^\d+]+/g, ''); // Normalize

	// Australia
	number = number.replace(/^0061/, '+61');
	number = number.replace(/^00614/, '+614');
	number = number.replace(/^04/, '+614');
	number = number.replace(/^\+610/, '+61');

	// New Zealand
	// number = number.replace(/^0064/, '+64');
	// number = number.replace(/^00642/, '+642');
	// number = number.replace(/^02/, '+642');
	// number = number.replace(/^\+640/, '+64');

	return number;
}