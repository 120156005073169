import React, { useContext, useState, useEffect } from 'react'
import ReactGA from 'react-ga'
import styled from 'styled-components'

import SquareButton from '../../../components/buttons/SquareButton';

import ExclamationMark from "../../../assets/images/general-icons/exclamation-mark.svg"
import { ConfigContext } from '../../../global/ConfigState';

const BrowserWrapper = styled.div `
  .browsers {
    font-size: 0.75em;
    font-style: italic;
    font-weight: 600;
  }

  .square-btn-wrapper {
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 15vh;
    display: flex;
    justify-content: center;
  }
`

function Browser() {
  const { deviceInfo } = useContext(ConfigContext);
  const [isCopied, setIsCopied] = useState(false);
  const url = window.location.href;
  const { vendor } = deviceInfo;

  const copyToClipboard = () => {
    if (!url) return;

    window.navigator.clipboard.writeText(url)
      .then(() => setIsCopied(true))
      .catch((err) => console.warn("Copy to clipboard failed.", err));
  }

  useEffect(() => {
    ReactGA.event({
      category: 'Error',
      action: 'Browser error'
    });
  }, []);

  return (
    <BrowserWrapper className="fullscreen f-col f-center">
      <p>Boo! Looks like your browser does not support the features required for WebAR. Click the button below to copy the link, then paste it to a supported browser.</p>
      <p className="browsers">{
        vendor === "Apple" 
          ? "Safari, Google Chrome (iOS 14.3 and above)"
          : `Google Chrome, Firefox${vendor === "Samsung" ? ", Samsung Browser" : ""}`
        }</p>
      <div className="square-btn-wrapper">
        <SquareButton 
          isLarge={true} 
          icon={ExclamationMark}
          onClick={copyToClipboard}
        >
          {isCopied ? "Link Copied" : "Copy Link"}
        </SquareButton>
      </div>
      
    </BrowserWrapper>
  )
}

export default Browser
