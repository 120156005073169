import React, { useContext } from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'

import { GameContext } from '../../../../global/GameState';

import ChallengeItem from './ChallengeItem';
import InventoryItem from '../../../../components/misc/InventoryItem';

import TopBg from '../../../../assets/images/inventory-top-bg.svg'
import Border from '../../../../assets/images/form/border-bottom.svg'
import ProgressLine from '../../../../assets/images/progress-line.svg'
import Texture from '../../../../assets/images/paper-style-wrapper/paper-texture-alt.png'

const InventoryWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 90;
  pointer-events: auto;

  .inventory-bg {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    overflow: hidden;

    img {
      top: 1px;
      position: relative;
      width: 100%;
    }

    .bottom {
      width: 100%;
      background: #000000;
      height: 100%;
      position: relative;
    }
  }

  .inventory-content {
    position: relative;
    z-index: 3;
    padding: 5em 4em 3em 4em;
    overflow: hidden;
  }

  .inventory-items {
    display: flex;
    gap: 20px;
    margin-bottom: 2em;
  }

  .title {
    color: var(--white);
    font-family: var(--mainFont);
    font-size: 1.5em;
    line-height: 1.4;
    position: relative;
    display: block;
    margin-bottom: .5em;

    &::after {
      content: '';
      background: url(${Border});
      background-size: contain;
      width: 80%;
      height: 2px;
      position: absolute;
      left: 0;
      bottom: 0;
    }
  }

  .challenges-list {
    padding: 0;
    position: relative;
    margin: 1.5em 0;

    li {
      margin: 2em 1em 2em 0;

      &:first-of-type {
        margin-top: 0;
      }

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  .progress-line {
    position: absolute;
    left: 8px;
    top: 50%;
    height: calc(100% - 16px);
    width: auto;
    z-index: -1;
    transform: translate(-50%, -50%);
  }

  .overlay-texture {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    transform: rotate(-80deg);
    z-index: 5;
    pointer-events: none;
  }
`;

function Inventory() {
  const { portals, currentPortal } = useContext(GameContext);

  const inventory = portals[currentPortal].inventory;
  const scenes = portals[currentPortal].scenes;

  return (
    <InventoryWrapper
      className=""
      as={motion.div}
      initial={{}}
      animate={{}}
      exit={{}}
    >
      <div className="inventory-bg fullscreen f-col">
        <img src={TopBg} alt="Background" />
        <div className="bottom"></div>
      </div>

      <div className="inventory-content">
        <img className="overlay-texture" src={Texture} alt="Paper texture" />

        <div className="inventory-items">
          <InventoryItem>
            {inventory[0] 
              ? <img src={inventory[0].icon} alt={inventory[0].id} /> 
              : null
            }
          </InventoryItem>

          <InventoryItem>
            {inventory[1] 
              ? <img src={inventory[1].icon} alt={inventory[1].id} /> 
              : null
            }
          </InventoryItem>

          <InventoryItem>
            {inventory[2] 
              ? <img src={inventory[2].icon} alt={inventory[2].id} /> 
              : null
            }
          </InventoryItem>
        </div>

        <div className="challenges-wrapper">
          <h2 className="title">Challenges</h2>
          <ul className="challenges-list">
            {scenes.map((scene, index) => (
              <ChallengeItem key={index} text={scene.text} completed={scene.completed} />
            ))}

            <img className="progress-line" src={ProgressLine} alt="progress-line" />
          </ul>
        </div>
      </div>
      
      
    </InventoryWrapper>
  )
}

export default Inventory
