import { createGlobalStyle } from 'styled-components';

import BorderImage from "../assets/images/form/border-bottom.svg";
import BorderImageFocus from "../assets/images/form/border-bottom-focus.svg";

export const GlobalStyles = createGlobalStyle`
  :root {
    --copyFont: ${props => props.theme.typography.copy};
    --mainFont: ${props => props.theme.typography.main};
    --mainCondFont: ${props => props.theme.typography.mainCond};
    --altFont: ${props => props.theme.typography.alternative};
    --primaryColour: ${props => props.theme.color.primary};
    --black: ${props => props.theme.color.dark};
    --white: ${props => props.theme.color.light};
  }

  html {
    ${'' /* height: -webkit-fill-available; */}
  }

  body {
    min-height: 100vh;
    overflow: hidden;
    font-size: 100%;
    position: relative;
    min-height: 100vh;
    padding: 0;
    margin: 0;
    min-height: calc(var(--vh, 1vh) * 100); /* corrected viewport height using css custom variables */

  }

  a {
    &:hover,
    &:focus {
      color: var(--primaryColour);
    }
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: var(--headingFont);
    line-height: 1.25;
    margin-bottom: .25em;
    font-weight: 400;
    text-transform: uppercase;
  }

  p {
    font-size: 1em;
  }

  .main-heading {
    font-size: 2em;
  }

  .heading {
    font-size: 1.5em;
  }

  .sub-heading {
    font-size: 1em;
  }

  .content {
    font-family: var(--copyFont);
  }

  .fade-to-black {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #000;
    z-index: 99;
  }

  .fullscreen {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .f-col {
    display: flex;
    flex-direction: column;
  }

  .f-row {
    display: flex;
    flex-direction: row;
  }

  .f-center {
    justify-content: center;
    align-items: center;
  }


  // Keen-Slider extras
  .navigation-wrapper {
    position: relative;
  }

  .dots {
    display: flex;
    padding: 20px 0;
    justify-content: center;
  }

  .dot {
    border: none;
    width: 20px;
    height: 20px;
    background: #FFFFFF;
    border-radius: 50%;
    margin: 0 10px;
    padding: 5px;
    cursor: pointer;

    -webkit-filter: drop-shadow( 0px 0px 15px rgba(0, 0, 0, .2));
    filter: drop-shadow( 0px 0px 15px rgba(0, 0, 0, .2));
  }

  .dot:focus {
    outline: none;
  }

  .dot.active {
    background: #C12032;
  }

  .arrow {
    width: 30px;
    height: 30px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    fill: #fff;
    cursor: pointer;
  }

  .arrow--left {
    left: 5px;
  }

  .arrow--right {
    left: auto;
    right: 5px;
  }

  .arrow--disabled {
    fill: rgba(255, 255, 255, 0.5);
  }

  .zoom-out {
    perspective: 1000px;
    height: 50vw;
  }

  @media (min-width: 768px) {
    .zoom-out {
      height: 300px;
    }
  }

  .zoom-out__slide {
    position: absolute;
  }

  .zoom-out__slide img {
    background-color: transparent;
    width: 100%;
    height: auto;
    position: absolute;
    // top: 50%;
    // left: 50%;
    // transform: translateY(-50%) translateX(-50%);
    // -webkit-transform: translateY(-50%) translateX(-50%);
  }

  .scrollbar-remove {
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    &::-webkit-scrollbar {
      display: none; /* for Chrome, Safari, and Opera */
    }
  }

  .form {
    padding: 1rem;
    pointer-events: auto;
    font-family: var(--mainFont);

    .inner-form-wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
    }

    input, select {
      width: 100%;
      height: 40px;
      display: inline-block;
      border: none;
      border-radius: .25rem;
      text-align: center;
      text-transform: uppercase;
      background-color: transparent;
    }

    .heading-section {
      .top-ornate {
        width: 46px;
      }

      .heading {
        font-family: var(--altFont);
        font-size: 2em;
        text-align: center;
        text-transform: uppercase;
      }
      .sub-heading {
        font-family: var(--copyFont);
        font-size: 1em;
      }
    }

    .form-group {
      flex: 1 1 100%;
      padding: 0.5rem 0.5rem 0rem;
      display: flex;
      flex-direction: column-reverse;
      justify-content: center;
      position: relative;

      &.hidden {
        padding: 0;
      }

        span {
          display: none;
        }

      &.border-bottom {
        span {
          display: block;
          width: 100%;
          height: 3px;
          background-image: url(${BorderImage});
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          background-repeat: no-repeat;
          background-position: center;
        }
      }

      &.first-checkbox {
        margin-top: 1em;
      }

      @media screen and (min-width: 768px) {
        flex: 50%;
      }

      label {
        width: 100%;
        font-size: 1rem;
        margin-bottom: 0.5rem;
        font-family: var(--primaryFont);
      }

      input:placeholder {
        color: rgba(#000, 0.6);
      }

      input:focus {
        outline: none;

        + span {
          background-image: url(${BorderImageFocus});
          opacity: 0.8;
        }
      }

      input[type="number"] {
        -moz-appearance: textfield;
      }

      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      .checkbox-wrapper,
      .radio-inner-wrapper {
        position: relative;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 0.5em;

        .custom-checkbox,
        .custom-radio {
          background-color: #fff;
          border: 1px solid #ccc;
          border-radius: 50%;
          cursor: pointer;
          height: 12px;
          width: 12px;
          position: absolute;
          left: 0;
          top: 0;
        }
        .custom-checkbox:after,
        .custom-radio:after {
          border: 1px solid #fff;
          border-top: none;
          border-right: none;
          content: "";
          width: 7px;
          height: 3px;
          top: 40%;
          left: 50%;
          opacity: 0;
          position: absolute;
          transform: translate(-50%, -50%) rotate(-45deg);
        }
        input[type="checkbox"],
        input[type="radio"] {
          position: absolute;
          opacity: 0;
          cursor: pointer;
        }
        input[type="checkbox"]:checked + .custom-checkbox,
        input[type="radio"]:checked + .custom-radio {
          background-color: var(--primaryColour);
          border-color: var(--primaryColour);
        }
        input[type="checkbox"]:checked + .custom-checkbox:after,
        input[type="radio"]:checked + .custom-radio:after {
          opacity: 1;
        }
      }

      .checkbox-label {
        margin-left: 20px;
        margin-bottom: 0;
        font-size: 12px;
        font-family: var(--copyFont);
        font-style: italic;
        line-height: 1.3;
      }

    }
  }
  .error-message {
    font-size: 0.675rem;
    color: var(--primaryColour);
    font-style: italic;
    position: absolute;
    top: 5px;
    left: 50%;
    transform: translateX(-50%);
    background-image: none!important;
  }

  @keyframes pulse {
    0% {
      transform: translate(-50%, -50%) scale(1.5);
    }
    50% {
      transform: translate(-50%, -50%) scale(0);
    }
    100% {
      transform: translate(-50%, -50%) scale(1.5);
    }
  }
  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;