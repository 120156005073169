export const SignUpFormData = [
  {
    title: 'First Name*',
    placeholder: "First Name",
    name: 'firstName',
    type: 'text',
    value: '',
    borderBottom: true
  }, {
    title: 'Last Name*',
    placeholder: "Last Name",
    name: 'lastName',
    type: 'text',
    value: '',
    borderBottom: true
  }, {
    title: 'Postcode',
    placeholder: 'Postcode',
    name: 'postcode',
    type: 'number',
    value: '',
    borderBottom: true
  }, {
    title: 'Mobile Number*',
    placeholder: "Mobile",
    name: 'mobile',
    type: 'tel',
    value: '',
    borderBottom: true
  }, {
    title: 'Email*',
    placeholder: 'email',
    name: 'email',
    type: 'email',
    borderBottom: true
  }, {
    title: "Please check this box to confirm you have read our Terms & Conditions",
    placeholder: "",
    name: 'terms',
    type: 'checkbox',
    value: '',
    borderBottom: false,
    className: "first-checkbox"
  }, {
    title: "Please check this box to confirm you have read our Privacy Policy",
    placeholder: "",
    name: 'privacy',
    type: 'checkbox',
    value: '',
    borderBottom: false,
  }, {
    title: 'Submit',
    type: 'submit',
    id: 'signup-submit',
    borderBottom: false
  }
];