import React, { Fragment, useEffect, useState } from "react";
import { useForm } from "@unbnd-operations/react-hooks";
import styled from "styled-components";

import InputField from "./InputField";
import CircularButton from "../buttons/CircularButton";

import TickIcon from "../../assets/images/circular-btn-icons/tick.svg";
import NextSlideIcon from "../../assets/images/circular-btn-icons/next-arrow.svg";

import { useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";

const FormWrapper = styled.div`
  .form-group {
    max-width: 400px;
    margin: 0 auto;
  }

  .top-ornate {
    margin-bottom: 18px;
  }

  .circular-btn-wrapper {
    margin-top: 14px;

    button {
      display: block;
      margin: 0 auto;
    }
  }

  .keen-slider {
    width: 100%;
  }

  .slide-nav {
    width: 100%;
  }

  .dot {
    width: 11px;
    height: 11px;
    border-radius: 6px;
    background-color: black;
    margin: 8px;

    &.active {
      background-color: var(--primaryColour);
    }
  }

  .submit-wrapper {
    width: 100%;
  }

  .radio-wrapper {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 140px;
    margin: 5px auto 0 auto;

    .radio-inner-wrapper {
      width: 100%;
      height: 100%;
      ${'' /* text-align: center; */}
      display: block;
      input[type=radio] {
        height: 20px;
      }
      .custom-radio {
        left: 16px;
      }
    }
  }
`;

function MultiPageForm({
  data,
  prefill,
  formCondition,
  submit,
  validators,
  heading,
  subheading,
  shirtSizes
}) {
  const [prefilled, setPrefilled] = useState(false);

  // Keen Slider setup
  const [currentSlide, setCurrentSlide] = useState(0);
  const [sliderRef, slider] = useKeenSlider({
    initial: 0,
    controls: false,
    slideChanged(s) {
      setCurrentSlide(s.details().relativeSlide);
    },
  });

  // Multi-page mapper flattens all pages into single object
  const multiPageMapper = (pages, prefill) => {
    const result = {};
    for (const page of pages) {
      for (const field of page) {
        // TODO Include check for duplicate field names here if this is reused
        if (prefill && prefill[field.name] !== undefined) {
          result[field.name] = prefill[field.name];
        } else {
          result[field.name] = field.value !== undefined ? field.value : "";
        }
      }
    }

    return result;
  };

  // Multi-page evalidator validates a single page or entire dataset
  const multiPageValidator = (pageValidators) => {
    return (values, pageIndex) => {
      if (pageIndex === undefined) {
        const errs = {};
        for (const validator of pageValidators) {
          Object.assign(errs, validator(values));
        }
        return errs;
      } else {
        if (pageIndex < 0 || pageIndex > pageValidators.length) {
          throw new Error("'pageIndex' is out of bounds.");
        }
        return pageValidators[pageIndex](values);
      }
    };
  };

  // Unbnd form utility functions
  const { handleChange, handleSubmitPage, handleSubmit, values, errors } =
    useForm(
      multiPageMapper(data, prefill),
      submit,
      multiPageValidator(validators)
    );

  // Click handler for validation and invoking next form page
  const handleClickNextPage = (e) => {
    const isLastPage = currentSlide === data.length - 1;

    // console.log("Clicked next page on page ", currentSlide);
    if (isLastPage) {
      handleSubmit(e);
      return;
    }

    const errors = handleSubmitPage(e, currentSlide);
    // console.log("Single page validation errors", errors)

    if (!Object.keys(errors).length && !isLastPage) {
      // Validation passed - move to next page
      setTimeout(slider.moveToSlideRelative(currentSlide + 1), 0);
    }
  };

  // Prefill form when data becomes available
  useEffect(() => {
    return () => {
      if (prefilled) return;

      for (const key in prefill) {
        values[key] = prefill[key];
      }
      setPrefilled(true);
    };
  }, [prefill, prefilled, values]);

  useEffect(() => {
    console.log(`errors`, errors)
  }, [errors])

  return (
    <FormWrapper className="form fullscreen" noValidate>
      <div className="heading-section f-col f-center">
        <h2 className="heading">{heading}</h2>
        {subheading && <p className="sub-heading">{subheading}</p>}
      </div>
      <div className="inner-form-wrapper">
        <div ref={sliderRef} className="keen-slider">
          {data.map((page, pageIndex) => (
            <Fragment key={pageIndex}>
              <div className="keen-slider__slide">
                {page.map((field, index) => (
                  <Fragment key={index}>
                    {field.type !== "dropdown" &&
                      field.type !== "submit" &&
                      field.type !== "textarea" &&
                      field.type !== "radio" &&
                      formCondition &&
                      (!field.conditions ||
                        field.conditions.includes(formCondition)) && (
                        <InputField
                          type={field.type}
                          placeholder={field.placeholder}
                          name={field.name}
                          title={field.title}
                          handleChange={handleChange}
                          error={errors[field.name]}
                          currentValue={values[field.name]}
                          className={field.borderBottom ? "border-bottom" : ""}
                        />
                      )}

                    {field.type === "radio" &&
                      (!field.conditions ||
                        field.conditions.includes(formCondition)) && (
                        <InputField
                          type={field.type}
                          placeholder={field.placeholder}
                          options={field.options}
                          shirtSizes={shirtSizes?.filter(size => size.amount > 0)}
                          name={field.name}
                          title={field.title}
                          handleChange={handleChange}
                          error={errors[field.name]}
                          currentValue={values[field.name]}
                          className={field.borderBottom ? "border-bottom" : ""}
                        />
                      )}
                  </Fragment>
                ))}
              </div>
            </Fragment>
          ))}
        </div>

        <div className="slide-nav">
          {slider && (
            <div className="dots">
              {[...Array(slider.details().size).keys()].map((idx) => {
                return (
                  <button
                    key={idx}
                    disabled={idx >= currentSlide}
                    onClick={() => {
                      slider.moveToSlideRelative(idx);
                    }}
                    className={"dot" + (currentSlide === idx ? " active" : "")}
                  />
                );
              })}
            </div>
          )}
        </div>

        <div className="submit-wrapper">
          {currentSlide < data.length - 1 ? (
            <CircularButton
              onClick={handleClickNextPage}
              className="next-slide"
              type="submit"
              size={40}
            >
              <img src={NextSlideIcon} alt="Next Slide Icon" />
            </CircularButton>
          ) : (
            <CircularButton
              onClick={handleClickNextPage}
              className="submit"
              type="submit"
              variant="red"
              size={40}
            >
              <img src={TickIcon} alt="Tick Icon" />
            </CircularButton>
          )}
        </div>
      </div>
    </FormWrapper>
  );
}

export default MultiPageForm;
