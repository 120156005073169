import React from 'react'
import styled from 'styled-components'

import Background from '../../assets/images/backgrounds/overlay-background.png'

const StyledOverlay = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  padding: 1em;

  .background-texture {
    position: absolute;
    top: 0;
    left: 0;
    background-image: url(${Background});
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    opacity: ${props => props.opacity};
  }

  .inner {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100%;
  }
`;

function OverlayWrapper({children, opacity = 1}) {
  return (
    <StyledOverlay opacity={opacity} className="overlay-wrapper">
      <div className="inner">{children}</div>
      <div className="background-texture"></div>
    </StyledOverlay>
  )
}

export default OverlayWrapper
