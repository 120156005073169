import React from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'

import CircularButton from '../buttons/CircularButton'

import tutorialBG from '../../assets/images/tutorial/tutorial-bg.png'
import NextArrow from '../../assets/images/circular-btn-icons/next-arrow.svg'
import CloseX from '../../assets/images/circular-btn-icons/x-icon.svg'

const TutorialModalWrapper = styled.div `
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: auto;
  opacity: 1;
  backdrop-filter: blur(5px);
  z-index: 90;

  .tutorial-inner-wrapper {
    position: relative;
    top: 12%;
    background: transparent;
    margin: 0 1em;
    display: flex;
    justify-content: center;
    align-items: center;

    > img {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
      width: 100%;
      height: 100%;
    }

    .tutorial-copy {
      z-index: 1;
      color: #fff;
      font-family: var(--mainFont);
      text-transform: uppercase;
      font-size: 1.5em;
      padding: 1.625em 0.625em;
      text-align: center;
      span {
        font-weight: bold;
        color: var(--primaryColour);
      }
      img {
        height: 22px;
      }
    }
    .circular-btn-wrapper {
      position: absolute;
      bottom: -26px;
      .circular-btn {
        > div {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
`

function TutorialModal({children, onClick, isClose}) {
  return (
    <TutorialModalWrapper
      as={motion.div}
      initial={{opacity: 0}}
      animate={{opacity: 1}}
      exit={{opacity: 0}}
      className="tutorial-modal-wrapper"
    >
      <div className="tutorial-inner-wrapper">
        <img src={tutorialBG} alt="Shape BG" />
        <div className='tutorial-copy' dangerouslySetInnerHTML={{ __html: children }}></div>
      <CircularButton size={44} onClick={onClick}>
          <img src={isClose ? CloseX : NextArrow} alt="Next Arrow" />
      </CircularButton>
      </div>

    </TutorialModalWrapper>
  )
}

export default TutorialModal
