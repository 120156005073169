import {
  COMPLETE_PORTAL,
  COMPLETE_SCENE,
  MOVE_TO_SCENE,
  // COMPLETE_CHECKPOINT,
  UPDATE_INVENTORY,
  ENTER_PORTAL,
  // COMPLETE_CHALLENGE,
  GAME_READY,
  GAME_ERROR,
  CLAIM_PRIZE,
  BACK_TO_AR
} from '../action-types';

const portalReducer = (state, action) => {
  const { type, payload } = action;

  switch(type) {
    case CLAIM_PRIZE: 
      return {
        ...state,
        prise: {
          ...state.prize,
          isClaimed: true
        }
      }
    case COMPLETE_PORTAL:
      return {
        ...state,
        portals: state.portals.map((portal, p_index) => (
          p_index === state.currentPortal
            ? ({
                ...portal,
                completed: true,
                currentScene: 0,
              })
            : portal
          )
        )
      }
    case COMPLETE_SCENE:
      return {
        ...state,
        portals: state.portals.map((portal, p_index) => (
          p_index === state.currentPortal
            ? ({
              ...portal,
              scenes: portal.scenes.map((scene, index) => (
                index === payload
                  ? ({
                    ...scene,
                    completed: true
                  })
                  : scene
              ))
            })
            : portal
        ))
      }
    case MOVE_TO_SCENE:
      return {
        ...state,
        portals: state.portals.map((portal, p_index) => (
          p_index === state.currentPortal
            ? ({
              ...portal,
              currentScene: portal.scenes.findIndex(scene => (
                scene.name === payload
              ))
            })
            : portal
        ))
      }
    // case COMPLETE_CHECKPOINT:
    //   return {
    //     ...state,
    //     portals: state.portals.map((portal, p_index) => (
    //       p_index === state.currentPortal
    //         ? ({
    //           ...portal,
    //           scenes: portal.scenes.map((scene, index) => (
    //             index === portal.currentScene
    //               ? ({
    //                 ...scene,
    //                 checkpoints: scene.checkpoints.filter(checkpoint => (
    //                   checkpoint !== payload
    //                 ))
    //               })
    //               : scene
    //           ))
    //         })
    //       : portal
    //     ))
    //   }
    // case COMPLETE_CHALLENGE:
    //   return {
    //     ...state,
    //     portals: state.portals.map((portal, p_index) => (
    //       p_index === state.currentPortal ? ({
    //         ...portal,
    //         challenges: portal.challenges.map((challenge) => (
    //           challenge.id === payload.id ? ({
    //             ...challenge,
    //             steps: challenge.steps.filter(step => step !== payload.step)
    //           }) : challenge
    //         ))
    //       }) : portal
    //     ))
    //   }
    case UPDATE_INVENTORY:
      const items = payload.map(item => (
        state.portals[state.currentPortal].items
            .find(obj => obj.id === item)
      ))

      return {
        ...state,
        portals: state.portals.map((portal, index) => (
          index === state.currentPortal
            ? ({
              ...portal,
              inventory: items
            })
            : portal
        ))
      }
    case BACK_TO_AR: 
      return {
        ...state,
        currentPortal: null
      }
    case ENTER_PORTAL:
      return {
        ...state,
        currentPortal: payload,
        portals: state.portals.map((portal, index) => (
          index === payload
            ? ({
              ...portal,
              currentScene: 0,
            })
            : portal
        ))
      }
    case GAME_READY: 
      return {
        ...state,
        prize: payload.dailyPrize,
        portals: state.portals.map((portal, index) => {
          // Check if the stored data has progresses further
          const fbCurrentScene = payload.portals[index].currentScene;
          
          return {
            ...portal,
            currentScene: fbCurrentScene > portal.currentScene 
              ? fbCurrentScene
              : portal.currentScene 
          }
        }),
        gameDataReady: true
      }
    case GAME_ERROR: 
      return {
        ...state,
        gameDataFailed: true
      }
    default:
      return state
  }
}

export default portalReducer;