import React, { useState, useEffect, useContext } from 'react'
import styled from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion'
import ReactGA from 'react-ga';

import { UserContext } from '../../../../global/UserState'
import { apiPost } from '../../../../api/axios'

import { SignUpFormData } from '../../../../data/SignUpFormData'
import { registerNowSlide, slideInOut } from '../../../../animation'
import { validateValues } from '../../../../components/form/Validator'
import Form from '../../../../components/form/Form'
import PaperWrapper from '../../../../components/layout/PaperWrapper'

import ChestIcon from '../../../../assets/images/signup/chest.png'
import SquareButton from '../../../../components/buttons/SquareButton'
import SuccessTitle from '../../../../components/typography/SuccessTitle'
import TFA from './TFA'
import IsLoading from '../../../../components/helpers/IsLoading'

const SignUpWrapper = styled.div`
  pointer-events: auto;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 99;

  .register-landing .paper-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .top-section {
    .content {
      margin-bottom: 80px;
      padding: 0 40px;

    }
  }
  .link {
    color: var(--primaryColour);
    text-decoration: underline;
  }

  .middle-section {
    padding: 0 42px;
    img {
      margin-bottom: 18px;
    }
    .content {
      margin-bottom: 80px;
    }
  }

  .title {
    font-size: 2rem;
    font-family: var(--altFont);
    text-transform: uppercase;
  }
  .content {
    font-size: 1rem;
  }
  .square-btn {
    font-size: 14px;
    padding: 1rem 2rem;
    letter-spacing: 0.5px;
    margin-bottom: 28px;
  }
  .maybe-later-btn {
    font-family: var(--mainFont);
    text-transform: uppercase;
    background: transparent;
    padding: 0.5rem 1rem;
    color: var(--primaryColour);
  }

  .register-landing, .form-wrapper, .tfa-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    .paper-body {
      text-align: center;
    }
  }

  .form-wrapper {

    .paper-inner-wrapper {
      align-items: center;
      height: auto;
    }

    .form {
      flex: 1 1 100%;
      height: auto;
    }
  }

  .bottom-links {
    display: flex;
    z-index: 1;
    gap: 1em;
    justify-content: center;
    padding: .25em 0 .5em;
    > * {
      font-family: var(--copyFont);
      font-size: 10px;
      font-style: italic;
      text-decoration: underline;
      padding: 0.25em 0.5em;
      background: transparent;
      font-weight: 600;
      color: var(--black);
    }
  }

  .tc-btn {
    font-family: var(--copyFont);
    font-size: 10px;
    font-style: italic;
    text-decoration: underline;
    padding: 0.25em 0.5em;
    background: transparent;
    font-weight: 600;
    color: var(--black);
  }

  .tc-paper-wrapper {
    .title {
      font-size: 1.5rem;
    }
    .paper-inner-wrapper {
      ${'' /* height: 94%; */}
      padding: 52px 1rem 1rem;
    }
    .paper-body {
      text-align: center;
      overflow-y: scroll;
      padding-top: 0;
    }
  }

  .tc-title {
    font-family: var(--mainFont);
  }

  .error-message-auth, .error-message-code {
    font-size: 0.675rem;
    color: var(--primaryColour);
    font-style: italic;
    background-image: none!important;
    font-family: var(--mainFont);
    text-align: center;
  }
`;

function SignUp({close}) {
  const { signIn, confirmSignIn, showTFA, currentUser, closeSignUp } = useContext(UserContext);
  const [showForm, setShowForm] = useState(false);
  const [submitCompletion, setSubmitCompletion] = useState(false);
  const [showTCs, setShowTCs] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [authMobileErr, setAuthMobileErr] = useState(null);
  const [authCodeErr, setAuthCodeErr] = useState(null)

  const formSubmission = async (data) => {
    setAuthMobileErr(null);
    setIsSubmitting(true)
    try {
      const result = await apiPost("/auth/user", data);
      console.log(`result`, result);

      ReactGA.event({
        category: "User",
        action: "Signed up"
      });
  

      const error = await signIn(String(data.mobile));

      if (error) {
        setAuthMobileErr(error)
      }
    } catch (err) {
      if (err?.response?.data) {
        const message = err.response.data.message.replace("Error: ", "");
        setAuthMobileErr(message);
      }
    }
    setIsSubmitting(false)
  }

  const submitTFA = async (data) => {
    setIsSubmitting(true);

    const error = await confirmSignIn(data.code);

    if (error) {
      setAuthCodeErr(error)
    }

    setIsSubmitting(false);
  }

  const opentcPage = () => {
    window.open("https://storage.googleapis.com/external-content/FullTCs_Portals%20of%20Delicious%20Demise-4.docx.pdf", "_blank")
  }

  const handleClose = () => {
    if (close) {
      close();
    } else {
      closeSignUp();
    }
  }

  const maybeLater = () => {
    ReactGA.event({
      category: "User",
      action: "Close sign up"
    });

    handleClose();
  }

  useEffect(() => {
    if (!showTFA) return;

    setShowForm(false);
  }, [showTFA]);

  useEffect(() => {
    if (!currentUser) return;

    setSubmitCompletion(true);
  }, [currentUser]);

  useEffect(() => {
    if (!submitCompletion) return;
    setTimeout(() => {
      handleClose();
    }, 2000);
  }, [submitCompletion]);

  return (
    <SignUpWrapper className="fullscreen">
      {isSubmitting && (
        <IsLoading />
      )}

      <AnimatePresence>
        {!showForm && !showTFA && !submitCompletion && !showTCs && (
          <motion.div
            className="register-landing fullscreen"
            initial={false}
            animate={registerNowSlide.visible}
            exit={registerNowSlide.hidden}
          >
            <PaperWrapper>
              <div className='top-section'>
                <h2 className='title'>Register Now</h2>
                <p className='content'>Register below to save your progress and unlock "prizes" along the way.</p>
              </div>
              <div className='middle-section'>
                <img src={ChestIcon} alt="Chest Icon" />
                <p className='content'>Cash, product, merch and recipes to be won.</p>
              </div>
              <div className='bottom-section'>
                <SquareButton variant='red' onClick={() => setShowForm(true)}>
                  Register Now
                </SquareButton>
                <button onClick={maybeLater} className='maybe-later-btn'>
                  Maybe Later
                </button>
              </div>
            </PaperWrapper>
          </motion.div>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {showForm && (
          <motion.div
            className="form-wrapper fullscreen"
            initial={slideInOut.initial}
            animate={slideInOut.animateIn}
            exit={slideInOut.exit}
          >
            <PaperWrapper>
              <Form
                heading="Enter your details"
                data={SignUpFormData}
                validation={validateValues}
                submit={formSubmission}
              />
              {authMobileErr && (
                <span className="error-message-auth">{authMobileErr}</span>
              )}
              <div className="bottom-links">
                <span className="back" onClick={handleClose}>
                  Back
                </span>
                <a href="https://www.fwhitlock.co.nz/auprivacypolicy" rel="noreferrer" target="_blank">
                  Privacy Policy
                </a>
                <button className="tc-btn" onClick={() => setShowTCs(true)}>
                  View T&C's
                </button>
              </div>
            </PaperWrapper>
          </motion.div>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {showTFA && (
          <TFA
            initial={slideInOut.initial}
            animate={slideInOut.animateIn}
            exit={slideInOut.exit}
            submit={submitTFA}
            authCodeErr={authCodeErr}
          />
        )}
      </AnimatePresence>

      <AnimatePresence>
        {submitCompletion && (
          <motion.div
            className="tfa-wrapper fullscreen"
            initial={slideInOut.initial}
            animate={slideInOut.animateIn}
            exit={slideInOut.exit}
          >
            <SuccessTitle title='Thanks!' />
          </motion.div>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {showTCs && (
          <motion.div
            className="TC-wrapper fullscreen"
            initial={slideInOut.initial}
            animate={slideInOut.animateIn}
            exit={slideInOut.exit}
          >
            <PaperWrapper className='tc-paper-wrapper'>
              <h2 className="title">
                Portals of Delicious Demise Promotion Abridged Terms
              </h2>

              <div className='content'>
                <h3>Online</h3>
                <p>
                AU18+ only. New Zealand residents are able to participate in the ‘Portals of Delicious Demise’ promotional game but are not eligible to win prizes in this Promotion. Ends 21/11/21 11:59 pm AEDT. Limit one (1) game registration & Grand Prize permitted per person and mobile phone number. Limit of 3 attempts to open the treasure chest each day per person. <a className='link' onClick={opentcPage}>T&Cs</a> apply.
                </p>
              </div>
              <button className="tc-btn" onClick={() => setShowTCs(false)}>
                Back
              </button>

            </PaperWrapper>
          </motion.div>
        )}
      </AnimatePresence>

    </SignUpWrapper>
  )
}

export default SignUp
