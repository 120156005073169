const theme = {
  typography: {
    copy: "adobe-garamond-pro, Didot, Georgia, serif",
    main: "league-gothic, Arial, sans-serif",
    mainCond: "league-gothic-condensed, sans-serif",
    alternative: "'Bernard MT Condensed', Courier, Georgia, serif",
  },
  color: {
    primary: "#C32033",
    dark: "#000000",
    light: "#FFFFFF",
  },
}

export default theme;
