import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import { usePC, useTimeout, useUpdateEffect } from '@unbnd-operations/react-hooks'

import SquareButton from '../../../components/buttons/SquareButton';
import OverlayWrapper from '../../../components/layout/OverlayWrapper';

import { ConfigContext } from '../../../global/ConfigState';
import { logoFade, loaderAnimation } from '../../../animation';

import WhitlocksLogo from '../../../assets/images/logos/whitlocks-logo.png'
import LoadingTitle from './LoadingTitle';
import ProgressBar from '../Experience/Pause/Progress/ProgressBar';
import ProgressBarLoader from './ProgressBarLoader';

const LoadingWrapper = styled.div`
  background: #ffffff;
  pointer-events: auto;

  .loading-progress-bar {
    position: absolute;
    bottom: 60px;
    left: 50%;
    transform: translateX(-50%);
  }
`;

const LogoWrapper = styled.div`
  padding: 0 3em;
  width: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  text-align: center;
  .slogan {
    margin-top: 44px;
    font-family: var(--mainFont);
    font-size: 24px;
    text-transform: uppercase;
  }
`;

const TitleWrapper = styled.div`
  position: relative;
  padding: 50px 0;

  .content {
    position: absolute;
    width: 90%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    font-size: 1.225em;

    p {
      line-height: 1.4;
    }
  }

  .square-btn {
    position: absolute;
    left: 50%;
    bottom: 60px;
    transform: translateX(-50%);
    pointer-events: auto;
  }


`;

// const titles = [
//   [
//     "You won’t find stories with happy endings here...in fact, quite the opposite.",
//   ],
//   [
//     "It’s uncommon knowledge to us but every veggie, fruit and herb knows their days are numbered.",
//   ],
//   [
//     "Only the bold and adventurous among them will become<br/>Whitlocks-worthy.",
//   ],
//   [
//     "Feed your adventure by solving the puzzle that will help our heroes reach their <b>Delicious Demise</b>.",
//   ],
//   [
//     "Are you Whitlock’s Worthy?"
//   ]
// ]
const titles = [
  [
    "Try to solve the AR Portals of Delicious Demise for your chance to win cash, Whitlock's products, merch and more.",
  ],
  [
    "Remember to register to be in with a chance."
  ]
]

function Loading({ logoDone, completeLogoAnim }) {
  const { arType, deviceInfo, isXR8Android, isXR8iOS, sceneReady, loadingDone } = useContext(ConfigContext);
  const [currentTitle, setCurrentTitle] = useState(-1);

  useTimeout(() => {
    completeLogoAnim();
  }, 2500);

  const { reset, clear } = useTimeout(() => {
    setCurrentTitle(currentTitle => currentTitle + 1);
  }, 5000);

  useUpdateEffect(() => {
    if (!logoDone) return;

    const startTitleTimeout = setTimeout(() => {
      setCurrentTitle(0);
    }, 1000);

    return () => clearTimeout(startTitleTimeout);
  }, [logoDone]);

  useUpdateEffect(() => {
    if (currentTitle < 0) return;
    if (currentTitle === titles.length) {
      loadingDone();
    } else {
      reset();
    };
  }, [currentTitle]);

  useEffect(() => {
    clear();
  }, [clear]);

  return (
    <LoadingWrapper
      as={motion.div}
      initial={false}
      animate={loaderAnimation.visible}
      exit={loaderAnimation.hidden}
      className="fullscreen"
    >
      <OverlayWrapper opacity={0.3}>

        <AnimatePresence>
          {logoDone && (
            <TitleWrapper
              className="fullscreen"
              as={motion.div}
              initial={loaderAnimation.hidden}
              animate={loaderAnimation.visible}
              exit={loaderAnimation.hidden}
            >

              <AnimatePresence>
                {currentTitle >= 0 && currentTitle !== titles.length && titles.map((title, index) => (
                  <motion.div
                    initial={loaderAnimation.hidden}
                    animate={loaderAnimation.visible}
                    exit={loaderAnimation.hidden}
                    className="content"
                    key={index}
                  >
                    <LoadingTitle lines={title} active={index === currentTitle} />
                  </motion.div>
                ))}
              </AnimatePresence>

              {sceneReady ? (
                <SquareButton onClick={loadingDone}>
                  Skip
                </SquareButton>
              ) : (
                <ProgressBarLoader />
              )}
            </TitleWrapper>
          )}
        </AnimatePresence>

        <AnimatePresence>
          {!logoDone && (
            <motion.div
              initial={logoFade.hidden}
              animate={logoFade.visible}
              exit={logoFade.hidden}
            >
              <LogoWrapper>
                <img src={WhitlocksLogo} alt="F.Whitlock & Sons" />
                <p className='slogan'>AR portals of delicious demise</p>
              </LogoWrapper>


              {(arType === '8thwall' || (deviceInfo.os === 'Android' && isXR8Android) || (deviceInfo.vendor === 'Apple' && isXR8iOS)) && (
                <img className="eighth-wall-logo" src="//cdn.8thwall.com/web/img/almostthere/v2/poweredby-horiz-white-2.svg" alt="8th Wall Logo" />
              )}

            </motion.div>
          )}
        </AnimatePresence>

      </OverlayWrapper>
    </LoadingWrapper>
  )
}

export default Loading


// function ProgressBarLoading() {
//   const { sceneReady } = useContext(ConfigContext);
//   const [progressVal, setProgressVal] = useState(0);

//   usePC({
//     event: 'preload:progress',
//     callback: progress => {
//       setProgressVal(progress);
//     }
//   });

//   return (
//     <ProgressBar value={Math.floor(progressVal * 100)} completed={sceneReady}></ProgressBar>
//   )
// }