import React from 'react'
import styled from 'styled-components'

import TopOrnate from '../../assets/images/success-title/ornate-title-top.svg'
import BottomOrnate from '../../assets/images/success-title/ornate-title-bottom.svg'

const SuccessTitleWrapper = styled.div `
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .top-ornate {
    margin-bottom: 20px;
    filter: invert(${props => props.isInverted ? '1' : '0'});
  }

  .bottom-ornate {
    ${'' /* margin-top: 36px; */}
    filter: invert(${props => props.isInverted ? '1' : '0'});
  }

  .title {
    font-family: var(--mainFont);
    font-size: ${props => props.fontSize}px;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    margin-bottom: 26px;
    color: ${props => props.isInverted ? '#fff' : '#000'};
  }

  .copy {
    font-family: var(--copyFont);
    font-style: italic;
    font-weight: 600;
    text-align: center;
    padding: 0 1em;
  }
`

function SuccessTitle({children, title, isInverted, className}) {
  return (
    <SuccessTitleWrapper
      fontSize={50 + 2 * (16 - title.length)}
      isInverted={isInverted}
      className={`success-title-wrapper ${className ? className : ''}`}
    >
      <img className='top-ornate' src={TopOrnate} alt="Top Ornate" />
      <div className="title">
        {title}
      </div>
      {children && (
        <div className="copy">
          {children}
        </div>
      )}
      <img className='bottom-ornate' src={BottomOrnate} alt="Bottom Ornate" />
    </SuccessTitleWrapper>
  )
}

export default SuccessTitle
