import React, { useContext, useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion'
import { usePC, useUpdateEffect } from '@unbnd-operations/react-hooks';

import CircularButton from '../../../../components/buttons/CircularButton';

import Crosshair from '../../../../assets/images/crosshair.svg'
import Inventory from '../../../../assets/images/circular-btn-icons/inventory.svg'
import MagnifyingGlass from '../../../../assets/images/circular-btn-icons/magnifying-glass.svg'
import CloseIcon from '../../../../assets/images/circular-btn-icons/x-icon.svg'
import CircularOutlineButton from '../../../../components/buttons/CircularOutlineButton';
import { GameContext } from '../../../../global/GameState';

const UIWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  z-index: 91;

  .crosshair {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    width: 34px;
    height: 34px;
    &.highlight:after {
      content: '';
      position: absolute;
      width: 55px;
      height: 55px;
      border: 2px solid var(--primaryColour);
      border-radius: 50%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      animation: pulse 2s infinite;
    }
  }

  .ui-buttons {
    position: absolute;
    right: 2em;
    bottom: 10vh;
    pointer-events: auto;
    z-index: 92;

    .circular-btn-wrapper:first-child {
      margin-bottom: 1em;
    }
  }

  .inventory-btn,
  .magnifier-btn {
    .ui-icon {
      height: 45%;
      width: auto;
    }

    .close {
      height: 35%;
    }

    &.hidden {
      opacity: 0;
      pointer-events: none;
    }

    &.highlight .btn::after {
      content: '';
      position: absolute;
      width: 55px;
      height: 55px;
      border: 2px solid var(--black);
      border-radius: 50%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      animation: pulse 2s infinite;
    }
  }

  .interaction-btn {
    position: absolute;
    left: 50%;
    bottom: 12vh;
    transform: translateX(-50%);
    pointer-events: auto;
  }

  .red-circle {
    position: absolute;
    right: 1px;
    top: 1px;
    animation: pulse 2s infinite;
  }

`;

function UI({
  toggleInventory,
  inventoryOpen,
  showZoomBtn,
  showInventorybtn,
  showHighlightCrosshair
}) {
  const [isZoomedIn, setIsZoomedIn] = useState(false)
  const [focusedObject, setFocusedObject] = useState(null)
  const {
    setFirstInventoryInteract,
    portals,
    currentPortal,
    tutorialStep,
    currTutorialSection
  } = useContext(GameContext);

  const activeScene = portals[currentPortal].currentScene
  const uiWrapperRef = useRef(null)
  const uiBtnsRef = useRef(null)

  usePC({
    event: 'interaction:focus',
    callback: (data) => {
      setFocusedObject(data);
    }
  });

  usePC({
    event: 'interaction:blur',
    callback: () => {
      setFocusedObject(null);
    }
  });

  const [interactWithObject] = usePC({
    event: 'interaction:picked'
  })

  const [zoomIn] = usePC({
    event: 'magnify:enable'
  });

  const [zoomOut] = usePC({
    event: 'magnify:disable'
  });

  useUpdateEffect(() => {
    if (isZoomedIn) {
      zoomIn();
    } else {
      zoomOut();
    }
  }, [isZoomedIn]);

  const handleZoom = () => {
    setIsZoomedIn(!isZoomedIn);
  }

  const handleInventoryClick = () => {
    toggleInventory()
    setFirstInventoryInteract(true)
  }

  useEffect(() => {
    if (!showHighlightCrosshair) {
      uiWrapperRef.current.style.zIndex = 90
    } else {
      uiWrapperRef.current.style.zIndex = 91
    }
  }, [showHighlightCrosshair])



  useEffect(() => {
    setFocusedObject(null)
  }, [activeScene])

  useEffect(() => {
    if (showZoomBtn && currTutorialSection === 1) {
      uiWrapperRef.current.style.position = 'static';
      uiBtnsRef.current.style.zIndex = 91
    } else {
      uiWrapperRef.current.style.position = 'absolute';
      uiBtnsRef.current.style.zIndex = 90
    }
  }, [showZoomBtn, tutorialStep, currTutorialSection]);

  return (
    <UIWrapper ref={uiWrapperRef} className="ui-wrapper fullscreen">

      <AnimatePresence>
        {!inventoryOpen && !focusedObject && (
          <motion.div
            className={`crosshair ${showHighlightCrosshair ? 'highlight' : ''}`}
            initial={{}}
            amimate={{}}
            exit={{}}
          >
            <img src={Crosshair} alt="Crosshair icon" />
          </motion.div>
        )}
      </AnimatePresence>


      <div className="ui-buttons" ref={uiBtnsRef}>
        {showInventorybtn && (
          <CircularButton
            size={54}
            className="inventory-btn"
            onClick={handleInventoryClick}
          >
            {/* {!firstInventoryInteract && (
              <svg className='red-circle' width="10" height="10" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M3.53543 0.00195808C5.36753 -0.053027 7.12434 1.05405 7.63824 2.81346C8.115 4.44568 6.97552 5.99093 5.55995 6.93305C4.33546 7.74798 2.76504 7.70925 1.5695 6.85242C0.34816 5.97709 -0.288023 4.48323 0.126466 3.03891C0.590577 1.42167 1.85368 0.0524309 3.53543 0.00195808Z" fill="#C32033" />
              </svg>
            )} */}
            {!inventoryOpen ? (
              <img className="ui-icon" src={Inventory} alt="Inventory icon" />
            ) : (
              <img className="ui-icon close" src={CloseIcon} alt="Close icon icon" />
            )}
          </CircularButton>
        )}

        {showZoomBtn && (
          <CircularButton
            size={54}
            className={`magnifier-btn ${inventoryOpen ? 'hidden' : ''} ${(tutorialStep === 0 && currTutorialSection === 1) ? 'highlight' : ''}`}
            onClick={handleZoom}
          >
            <img className="ui-icon" src={MagnifyingGlass} alt="Magnifying glass icon" />
          </CircularButton>
        )}
      </div>

      {focusedObject && !inventoryOpen && (
        <CircularOutlineButton
          onClick={interactWithObject}
          label={focusedObject.text}
          size={100}
          type={focusedObject.type}
          className='interaction-btn'
        >
          <img src={focusedObject.icon} alt={focusedObject.text} />
        </CircularOutlineButton>
      )}

    </UIWrapper>
  )
}

export default UI
