import React from 'react'
import styled from 'styled-components'

import CircularButton from '../../../../../components/buttons/CircularButton'

import nextArrowImg from '../../../../../assets/images/circular-btn-icons/next-arrow.svg';

const HeaderWrapper = styled.div`
  width: 100%;
  margin-bottom: 30px;
  padding: 0.25em 0.5em 0;
  display: flex;
  align-items: center;

  &::after {
    content: '';
    height: 40px;
    flex: 0 0 40px;
  }

  .back {
    flex: 0 0 40px;

    .arrow-icon {
      transform: rotate(180deg);
    }
  }

  p {
    flex: 1 1 100%;
    text-align: center;
    font-family: var(--altFont);
    font-style: italic;
    letter-spacing: 3px;
    font-size: 20px;
    margin: 0;
  }
`;

function Header({ title, onBackClick }) {
  return (
    <HeaderWrapper>
      <div className="back">
        <CircularButton onClick={onBackClick} size="40">
          <img className="arrow-icon" src={nextArrowImg} alt="Next arrow" />
        </CircularButton>
      </div>
      <p>{title}</p>
    </HeaderWrapper>
  )
}

export default Header
