import React from 'react'
import styled from 'styled-components'

import BlackShape1 from '../../assets/images/circular-btn-shapes/circular-shape-1.png'
import BlackShape2 from '../../assets/images/circular-btn-shapes/circular-shape-2.png'
import BlackShape3 from '../../assets/images/circular-btn-shapes/circular-shape-3.png'
import BlackShape4 from '../../assets/images/circular-btn-shapes/circular-shape-4.png'
import BlackShape5 from '../../assets/images/circular-btn-shapes/circular-shape-5.png'

import RedShape1 from '../../assets/images/circular-btn-shapes/circular-shape-1-red.png'
import RedShape2 from '../../assets/images/circular-btn-shapes/circular-shape-2-red.png'
import RedShape3 from '../../assets/images/circular-btn-shapes/circular-shape-3-red.png'
import RedShape4 from '../../assets/images/circular-btn-shapes/circular-shape-4-red.png'
import RedShape5 from '../../assets/images/circular-btn-shapes/circular-shape-5-red.png'

const blackShapes = [
  BlackShape1,
  BlackShape2,
  BlackShape3,
  BlackShape4,
  BlackShape5
];

const redShapes = [
  RedShape1,
  RedShape2,
  RedShape3,
  RedShape4,
  RedShape5
];

const StyledCircularWrapper = styled.div`
  text-align: center;

  button {
    width: ${props => props.size ? `${props.size}px` : '100%'};
    height: ${props => props.size ? `${props.size}px` : 'auto'};
    aspect-ratio: 1 / 1;
    position: relative;
    background: transparent;
    pointer-events: auto;

    > img {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
      width: 100%;
      height: 100%;
    }

    > div {
      position: relative;
      z-index: 1;
      width: 100%;
      height: 100%;
      display: grid;
      place-items: center;
    }
  }

  .circular-btn-label {
    margin-top: .25em;
    display: block;
    text-align: center;
    font-family: var(--mainFont);
    text-transform: uppercase;
    font-size: .875em;
  }
`;

const shapeIndex = Math.floor(Math.random() * 5);

function CircularButton({
  children,
  variant,
  onClick,
  label,
  size,
  className,
  id
}) {
  return (
    <StyledCircularWrapper size={size} className={`circular-btn-wrapper ${className ? className : ''}`}>
      <button
        className="btn circular-btn"
        onClick={onClick}
        id={id}
      >
        <img src={variant === 'red'
          ? redShapes[shapeIndex]
          : blackShapes[shapeIndex]
        } alt="Shape Background" />

        <div>{children}</div>
      </button>
      {label && (
        <span
          className="circular-btn-label"
        >
          {label}
        </span>
      )}
    </StyledCircularWrapper>
  )
}

export default CircularButton
