import React, { useEffect, useState } from 'react'
import ReactGA from 'react-ga'
import styled from 'styled-components'
import { AnimatePresence, motion } from 'framer-motion'

import OverlayWrapper from '../../../../components/layout/OverlayWrapper'
import PauseMenu from './PauseMenu';
import Progress from './Progress/Progress';
import FAQ from './FAQ/FAQ';
import Tales from './Tales/Tales';

const PauseWrapper = styled.div`
  pointer-events: auto;
  position: relative;
  z-index: 90;
`;

function Pause() {
  const [activeState, setActiveState] = useState('menu');

  const onProgressClick = () => {
    setActiveState ('progress');
  }

  const onFAQClick = () => {
    setActiveState ('FAQ');
  }

  const onTalesClick = () => {
    setActiveState ('tales');
  }

  const onBackClick = () => {
    setActiveState ('menu');
  }

  useEffect(() => {
    ReactGA.event({
      category: 'Menu',
      action: `Show ${activeState}`
    });
  }, [activeState]);

  useEffect(() => {
    ReactGA.event({
      category: 'Game',
      action: 'Show Menu'
    });
  }, []);

  return (
    <PauseWrapper className="fullscreen">
      <OverlayWrapper>

          <AnimatePresence>
            {activeState === 'menu' && (
              <PauseMenu
                onProgressClick={onProgressClick}
                onFAQClick={onFAQClick}
                onTalesClick={onTalesClick}
              />
            )}
          </AnimatePresence>

          <AnimatePresence>
            {activeState === 'progress' && (
              <Progress onBackClick={onBackClick} />
            )}
          </AnimatePresence>

          <AnimatePresence>
            {activeState === 'FAQ' && (
              <FAQ onBackClick={onBackClick} />
            )}
          </AnimatePresence>

          <AnimatePresence>
            {activeState === 'tales' && (
              <Tales onBackClick={onBackClick} />
            )}
          </AnimatePresence>

      </OverlayWrapper>
    </PauseWrapper>
  )
}

export default Pause
