import React, { useContext, useState } from 'react'
import { UserContext } from '../../../../global/UserState';

import CombinationLock from './CombinationLock';
import SignUp from '../SignUp/SignUp';
import ChestPrompt from './ChestPrompt';

function Code({close}) {
  const { currentUser } = useContext(UserContext);
  const [showPrompt, setShowPrompt] = useState(true);

  const closeSignUp = () => {
    if (!currentUser) {
      close();
    }
  }

  return (
    <div className="fullscreen">
        <>
          {showPrompt && (
            <ChestPrompt 
              closeChestPrompt={() => setShowPrompt(false)}
            />
          )}

          {!currentUser ? (
            <SignUp 
              close={closeSignUp}
            />
          ) : (
            <CombinationLock 
              close={close}
            />
          )}
        </>
    </div>
  )
}

export default Code
