import React, { useEffect, useState, createContext } from 'react'
import ReactGA from 'react-ga';
import { usePC } from '@unbnd-operations/react-hooks';
import { checkDevice } from '@unbnd-operations/utilities'

export const ConfigContext = createContext();

function ConfigState(props) {
  /**
   * Settings
   */
  const isXR8Android = true,
        isXR8iOS = true,
        portraitEnabled = true,
        landscapeEnabled = true;

  /**
   * Device Related
   */
  const [error, setError] = useState(null); // Error Screens
  const [arType, setArType] = useState(null); // AR Technology
  const [deviceInfo, setDeviceInfo] = useState(null); // Device information

  /**
   * PlayCanvas Loading
   */
  // const [loadingProgress, setLoadingProgress] = useState(0);  // Playcanvas loader
  const [sceneReady, setSceneReady ] = useState(false);       // Playcanvas ready
  const [sceneLoaded, setSceneLoaded ] = useState(false);     // Intro done 

  /**
   * Setup phase
   */
  const [arStarted, setArStarted] = useState(false);    // AR Started
  const [arFound, setArFound] = useState(false);        // Surface found (WebXR)
  const [arPlaced, setArPlaced] = useState(false);      // Object placed
  const [setupDone, setSetupDone] = useState(false);    // Checkbox ticked

  // * Error screen handling
  // *-------------------------------------* /
  // Handle permission errors (camera, motion)
  usePC({ 
    event: 'ar:error:permission', 
    callback: ({reason}) => {
      setError(reason);
    }
  });

  // Handle browser error
  usePC({ 
    event: 'ar:error:browser', 
    callback: () => {
      setError('browser');
    }
  });

  // Trigger a desktop error - {Available in context}
  const setDesktopError = () => {
    setError('desktop');
  };


  // * AR Type
  // *-------------------------------------* /
  // Check AR Type 
  usePC({ 
    event: 'ar:type', 
    callback: ({type}) => {
      setArType(type);
    }
  });


  // * PlayCanvas Loading
  // *-------------------------------------* /
  // Loading progress (from PlayCanvas)
  // usePC({ 
  //   event: 'preload:progress', 
  //   callback: progress => {
  //     setLoadingProgress(progress);
  //   }
  // });

  // Loading done function
  usePC({ 
    event: 'start', 
    callback: () => {
      setSceneReady(true);
      // setSceneLoaded(true);
      ReactGA.event({
        category: 'Debug',
        action: 'PlayCanvas ready'
      });
    }
  });

  const loadingDone = () => {
    setSceneLoaded(true);
  }


  // * Start AR
  // *-------------------------------------* /
  // Initialise AR - {Avaiable in context}
  const [initialiseAR] = usePC({ 
    event: 'ar:init',
    once: true
  });

  // AR Ready
  usePC({ 
    event: 'ar:started', 
    callback: () => {
      setArStarted(true);
      ReactGA.event({
        category: 'Game',
        action: 'Game Started'
      });
    }
  });


  // * Placement functions
  // *-------------------------------------* /
  // Handle Surface Found
  usePC({ 
    event: 'ar:surface:found', 
    callback: () => {
      setArFound(true)
    }
  });

  // Setup Done
  const [completeSetup] = usePC({ 
    event: 'ar:setup:done', 
    callback: () => {
      setSetupDone(true);
      ReactGA.event({
        category: 'Debug',
        action: 'Setup Done'
      });
    }
  });

  // Handle Place AR
  const [handleARPlace] = usePC({ 
    event: 'ar:placed', 
    callback: () => {
      setArPlaced(true);
      ReactGA.event({
        category: 'Debug',
        action: 'AR Place'
      });
    }
  });

  // Handle Putdown AR
  const [handleARPutdown] = usePC({ 
    event: 'ar:putdown', 
    callback: () => {
      setArPlaced(true)
    }
  });

  // PlaceAR Function - {Available in context}
  const placeAR = (firstPlace) => {
    if (firstPlace) {
      handleARPlace();
    } else {
      handleARPutdown();
    }  
  };

  // PickupAR Function - {Available in context}
  // const [pickupAR] = usePC({ 
  //   event: 'ar:pickup', 
  //   callback: () => {
  //     setArPlaced(false);
  //   }
  // });

   // RecenterAR Function - {Available in context}
  // const [recenterAR] = usePC({ event: 'ar:recenter' });


  // * Get device information
  // *-------------------------------------* /
  useEffect(() => {
    const thisDevice = checkDevice();

    setDeviceInfo(thisDevice);
  }, []);


  return (
    <ConfigContext.Provider value={{
      isXR8Android,
      isXR8iOS,
      portraitEnabled,
      landscapeEnabled,
      deviceInfo,
      error,
      arType,
      // loadingProgress,
      sceneReady,
      sceneLoaded,
      arStarted,
      arFound,
      arPlaced,
      setupDone,
      setDesktopError,
      initialiseAR,
      placeAR,
      completeSetup,
      // pickupAR,
      // recenterAR,
      loadingDone
    }}>
      {props.children}
    </ConfigContext.Provider>
  )
}

export default ConfigState
