import React, { useState, useEffect, useContext } from 'react'
import styled from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion'
import { useTimeout } from '@unbnd-operations/react-hooks'
import { slideInOut } from '../../animation'

import { GameContext } from '../../global/GameState'
import prizes from '../../data/prizes'

import OverlayWrapper from '../layout/OverlayWrapper'
import SuccessTitle from '../typography/SuccessTitle'
import CardTitle from '../typography/CardTitle'
import SquareButton from '../buttons/SquareButton'

import TickIcon from '../../assets/images/tick.svg'

const PrizeModalWrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: #fff;
  .prize-page {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    padding: 64px 24px 24px;
  }

  .congrats-page {
    ${'' /* padding: 1rem; */}
  }

  .pre-title {
    font-family: var(--mainFont);
    font-size: 1.5rem;
    margin: 0;
    text-transform: uppercase;
  }
  .prize-title {
    font-family: var(--altFont);
    font-size: 3.25rem;
    margin: 0;
    text-transform: uppercase;
  }

  .prize-image {
    margin-bottom: 24px;
  }

  .redeem-btns {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    pointer-events: auto;
  }

  .no-thanks {
    font-family: var(--mainFont);
    color: var(--primaryColour);
    padding: 0.5rem 1rem;
    background: transparent;
    text-transform: uppercase;
  }
`

function PrizeModal({ }) {
  const { prize, claimPrize, hidePrize, prizeClaimed } = useContext(GameContext);

  const [showPrize, setShowPrize] = useState(false);
  const [showThanks, setShowThanks] = useState(false);

  const prizeImage = prizes.find(item => prize.type === 'cash'
    ? item.value === prize.value
    : prize.type === 'product'
      ? item.value === prize.value
      : item.type === prize.type
  ).img;


  useTimeout(() => {
    setShowPrize(true);
  }, 2500)

  const { reset, clear } = useTimeout(() => {
    setShowThanks(false);
    hidePrize();
  }, 2000);

  const redeemNow = async () => {
    const prizeStatus = await claimPrize();

    if (!prizeStatus) return;

    setShowPrize(false);
    setShowThanks(true);
    reset();
  }

  useEffect(() => {
    clear();
  }, []);

  return (
    <PrizeModalWrapper>
      <OverlayWrapper>
        <AnimatePresence>
          {!showPrize && !showThanks && !prizeClaimed && (
            <motion.div
              className="congrats-page fullscreen"
              initial={false}
              animate={slideInOut.animateIn}
              exit={slideInOut.exit}
            >
              <SuccessTitle title='Congratulations!' />
            </motion.div>
          )}
        </AnimatePresence>

        <AnimatePresence>
          {showPrize && (
            <motion.div
              className="prize-page fullscreen"
              initial={slideInOut.initial}
              animate={slideInOut.animateIn}
              exit={slideInOut.exit}
            >
              <CardTitle>
                <p className='pre-title'>{prize.copy.minor}</p>
                <p className='prize-title'>{prize.copy.major}</p>
              </CardTitle>
              <img className='prize-image' src={prizeImage} alt="Prize Image" />
              {prize.type === 'recipe' && (
                <p className='image-subtitle'>Now get cooking good looking</p>
              )}
              <div className='redeem-btns'>
                <SquareButton isLarge={true} icon={TickIcon} variant='red' onClick={redeemNow}>
                  Redeem Now
                </SquareButton>
                <button className='no-thanks' onClick={hidePrize}>
                  No Thanks
                </button>
              </div>
            </motion.div>
          )}
        </AnimatePresence>

        <AnimatePresence>
          {showThanks && (
            <motion.div
              className="thanks-page fullscreen"
              initial={slideInOut.initial}
              animate={slideInOut.animateIn}
              exit={slideInOut.exit}
            >
              <SuccessTitle title='Thanks!'>
                <p>We'll send you an {prize.type === 'recipe' ? "email" : "SMS"} soon with details on how to claim.</p>
                <p>There are more prizes to be won, you just gotta keep looking...</p>
              </SuccessTitle>
            </motion.div>
          )}
        </AnimatePresence>

      </OverlayWrapper>
    </PrizeModalWrapper>
  )
}

export default PrizeModal
