import React, { useEffect, useRef, useContext } from 'react'
import styled from 'styled-components'
import { GameContext } from '../../../../global/GameState';

const CutsceneVideoWrapper = styled.video `
  width: 100%;
`

function CutsceneVideo({src, videoCompleted}) {
  const { audioMuted } = useContext(GameContext)
  const cutsceneVid = useRef(null);

  useEffect(() => {
    if (!cutsceneVid.current) return;
    const video = cutsceneVid.current;

    video.muted = audioMuted;
    video.play();
    video.addEventListener("ended", videoCompleted);

    return () => {
      video.removeEventListener("ended", videoCompleted);
    }
  }, []);

  useEffect(() => {
    return () => cutsceneVid.current = null;
  }, []);

  return (
    <CutsceneVideoWrapper
      ref={cutsceneVid}
      className="atl-cutaway"
      playsInline={true}
      autoPlay={true}
      controls={false}
      muted={audioMuted}
      src={src}
    >

    </CutsceneVideoWrapper>
  )
}

export default CutsceneVideo
