// Item Images
import Keys from "../assets/images/inventory-items/keys.png"
import Badge from "../assets/images/inventory-items/badge.png"
import Timber from "../assets/images/inventory-items/timber.png"
import Rope from "../assets/images/inventory-items/rope.png"
import Matches from "../assets/images/inventory-items/matches.png"
import Gunpowder from "../assets/images/inventory-items/gunpowder.png"
import Flowers from "../assets/images/inventory-items/flowers.png"
import Rammer from "../assets/images/inventory-items/rammer.png"

// Item Icons
import KeyIcon from '../assets/images/inventory-icons/key-icon.png'
import BadgeIcon from '../assets/images/inventory-icons/badge-icon.png'
import TimberIcon from '../assets/images/inventory-icons/timber-icon.png'
import RopeIcon from '../assets/images/inventory-icons/rope-icon.png'
import MatchesIcon from '../assets/images/inventory-icons/matches-icon.png'
import GunpowderIcon from "../assets/images/inventory-icons/gunpowder-icon.png"
import FlowerIcon from "../assets/images/inventory-icons/flower-icon.png"
import RammerIcon from "../assets/images/inventory-icons/rammer-icon.png"

// Product images
// import ChipotleCompletion from '../assets/images/completion/chipotle_bbq_img.png'
// import BeansCompletion from '../assets/images/completion/fuego_chilli_img.png'
import ChipotleCompletion from '../assets/images/completion/chipotlebbq_end_img.png'
import BeansCompletion from '../assets/images/completion/killer_beans_fuego_end_img.png'

// ATL Videos
import ArriveAtBarVid from '../assets/videos/atl/arriving-at-bar-portrait.mp4'
import EnterBarVid from '../assets/videos/atl/entering_bar-portrait.mp4'
import PirateChase from '../assets/videos/atl/pirates_chase-portrait.mp4'

// Portal Cards
import ChipotleCard from '../assets/images/pause-menu/progress/chipotle-card.svg'
import BeansCard from '../assets/images/pause-menu/progress/beans-card.svg'

export const portals = [
  {
    name: "Chipotle BBQ",
    title: "Chipotle BBQ",
    backstory: [
      "Chip is one hot-headed Chipotle. The kind that ventures far and wide and ends up in some fiery scenarios. And this time was no different.",
      "With the wind blowing in his stalk, Chip hits the open road he sets out to become a member of the Grim Reapers of Scoville; the infamous chilli outlaw motorcycle club. But unbeknownst to him, their precarious initiation ritual is not for the faint of heart.",
      "So when Chip comes knocking on their door, he soon realises he’s probably bitten off more than he can chew. In fact, this is one delicious demise that would make any chilli blush.",
      "Now it's time for you to become Chip and cause his delicious demise in this immersive 360 degree game."
    ],
    id: "chipotle_bbq",
    product_image: ChipotleCompletion,
    cardImage: ChipotleCard,
    items: [
      {
        id: "keys",
        title: "You found some keys",
        copy: "Find the keys and start your motorcycle.",
        image: Keys,
        icon: KeyIcon
      },
      {
        id: "badge",
        title: "You found a badge",
        copy: "Find the timber and the badge, and get into the bar",
        image: Badge,
        icon: BadgeIcon
      },
      {
        id: "timber",
        title: "You found some timber",
        copy: "Find the timber and the badge, and get into the bar",
        image: Timber,
        icon: TimberIcon
      },
      {
        id: "rope",
        title: "You found a rope",
        copy: "Find the rope and matches, and make your way to the fire pit",
        image: Rope,
        icon: RopeIcon
      },
      {
        id: "matches",
        title: "You found some matches",
        copy: "Find the rope and matches, and make your way to the fire pit",
        image: Matches,
        icon: MatchesIcon
      }
    ],
    scenes: [
      {
        name: "Desert",
        video: ArriveAtBarVid,
        text: "Find the keys and start your motorcycle"
      },
      {
        name: "Scoville",
        video: EnterBarVid,
        text: "Find the timber and the badge, and get into the bar"
      },
      {
        name: "Bar",
        text: "Find the rope and matches, and make your way to the fire pit"
      },
      {
        name: "Backyard",
        text: "Use the items for your delicious demise"
      }
    ]
  },
  {
    name: "Fuego Beans",
    title: "Killer Beans Fuego Chilli",
    backstory: [
      "Rojo & Oscar are swashbuckling outlaw bean-pirates who spend their days sailing up and down the Mexican coast from port to port, taverna to taverna, being generally rambunctious.",
      "And as fate would have it, their downfall was their penchant for the sauce and lady-beans. Including the sister of the infamous Capitán La Haba, who was, understandably, not pleased when word got back to him.",
      "Capitán La Haba and his not-so-merry pirates track down Rojo & Oscar up to their usual shenanigans and corner them after a brief pursuit. Forget walking the plank, these swindlers find themselves headed for a delicious demise unlike any other. Looks like they’re stuffed. Literally.",
      "Become Oscar & Rojo and adventure into this immersive 360 degree game to cause your own delicious demise!",
    ],
    id: "fuego_beans",
    product_image: BeansCompletion,
    cardImage: BeansCard,
    items: [
      {
        id: "gunpowder",
        title: "You've found some gunpowder",
        copy:  "Find the cannon stuffer, matchstick and gunpowder, and make your way to the ship.",
        image: Gunpowder,
        icon: GunpowderIcon
      },
      {
        id: "flowers",
        title: "You've found some flowers",
        copy: "Deliver the flowers to your favourite lady-bean",
        image: Flowers,
        icon: FlowerIcon
      },
      {
        id: "rammer",
        title: "You've found a cannon stuffer",
        copy: "Find the cannon stuffer, matchstick and gunpowder, and make your way to the ship" ,
        image: Rammer,
        icon: RammerIcon
      },
      {
        id: "match",
        title: "You've found some matches",
        copy: "Find the cannon stuffer, matchstick and gunpowder, and make your way to the ship",
        image: Matches,
        icon: MatchesIcon
      }
    ],
    scenes: [
      {
        name: "Dock",
        video: PirateChase,
        text: "Deliver the flowers to your favourite lady-bean"
      },
      {
        name: "BackDock",
        text: "Find the cannon stuffer, matchstick and gunpowder, and make your way to the ship"
      },
      {
        name: "Ship",
        text: "Use the items for your delicious demise"
      }
    ]
  }
]
