import React from 'react'
import styled from 'styled-components'

import PaperBG from '../../assets/images/paper-style-wrapper/paper-bg.png'
import PaperTexture from '../../assets/images/paper-style-wrapper/paper-texture.png'
import TopOrnate from '../../assets/images/top-ornate.svg'

const PaperStyleWrapper = styled.div`
  ${'' /* padding: 5em 1.5em; */}
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  .paper-inner-wrapper {
    position: relative;
    background: transparent;
    width: 94%;
    ${'' /* height: 85%; */}
    padding: 1rem;

    .paper-bg, .paper-texture {
      position: absolute;
      left: 0;
      top: 0;
    }

    .top-ornate {
      margin-bottom: 18px;
      position: absolute;
      left: 50%;
      top: 26px;
      transform: translateX(-50%);
      display: ${props => props.ornate ? 'inline' : 'none'};
    }

    .paper-bg {
      filter: drop-shadow(5px 5px 6px rgba(0,0,0,0.15));
    }

    .paper-body {
      z-index: 1;
      position: relative;
      padding-top: ${props => props.ornate ? 42 : 0}px;
    }
  }
`

function PaperWrapper({ children, ornate=true, className }) {
  return (
    <PaperStyleWrapper className={`paper-wrapper fullscreen ${className ? className : ''}`} ornate={ornate}>
      <div className="paper-inner-wrapper">
        <img className='paper-bg fullscreen' src={PaperBG} alt="Paper Card" />
        <img className='paper-texture fullscreen' src={PaperTexture} alt="Paper Texture" />
        <img className='top-ornate' src={TopOrnate} alt="Top Ornate" />
        <div className='paper-body fullscreen scrollbar-remove'>
          {children}
        </div>
      </div>
    </PaperStyleWrapper>
  )
}

export default PaperWrapper
