import React from 'react'
import { AnimatePresence, motion } from 'framer-motion'

import { loaderTitleAnimation } from '../../../animation';

function LoadingTitle({lines, active}) {
  // !FIX: Stagger animation
  return (
    <AnimatePresence>
      {active && (
        <motion.div
          initial="hidden"
          animate="visible"
          exit="hidden"
          variants={loaderTitleAnimation}
        >
          {lines.map((line, index) => (
            <motion.p 
              key={index} dangerouslySetInnerHTML={{ __html: line }}
            >
            </motion.p>
          ))}
        </motion.div>
      )}
    </AnimatePresence>
  )
}

export default LoadingTitle
