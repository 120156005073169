import React from 'react'
import styled from 'styled-components'

import BottomOrnate from '../../assets/images/card-title/card-title-ornate.svg'

const CardTitleWrapper = styled.div `
  text-align: center;
  padding: 12px 0;

  .ornate-bottom {
    margin-top: 10px;
  }
`

function CardTitle({children, className}) {
  return (
    <CardTitleWrapper className={`card-title-wrapper f-col f-center ${className ? className : ''}`}>
      {children}
      <img className='ornate-bottom' src={BottomOrnate} alt="Bottom Ornate" />
    </CardTitleWrapper>
  )
}

export default CardTitle
