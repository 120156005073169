import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import CookiePolicy from './CookiePolicy/CookiePolicy'
import Game from './Game/Game'
import Redeem from './Redeem/Redeem'
// import Playground from './Playground'

import ConfigState from '../global/ConfigState'
import GameState from '../global/GameState'

function Main() {
  return (
    <Router>
      <Switch>
        <Route exact path="/redeem" component={Redeem} />
        <Route exact path="/cookie-policy" component={CookiePolicy} />
        {/* <Route exact path="/playground" component={Playground} /> */}
        <Route exact path="/">
          <ConfigState>
            <GameState>
              <Game />
            </GameState>
          </ConfigState>
        </Route>
      </Switch>
    </Router>
  )
}

export default Main
