import React from 'react'
import styled from 'styled-components'

import Logo from '../../assets/images/delicious-demise-logo.svg'

const LogoWrapper = styled.div`
  width: 100%;
  padding: .75em 2em;
  display: flex;
  flex-direction: column;
  align-items: center;

  .ornate-wrapper {
    width: 46px;
    height: 24px;
    margin-bottom: 1.5em;
  }

  img {
    width: ${props => props.small ? '125px' : '244px'};
  }

`;

function DelicousLogoWrapper({ornate, small}) {
  return (
    <LogoWrapper className="delicous-wrapper" small={small}>
      {ornate && (
        <div className="ornate-wrapper">
          <svg viewBox="0 0 46 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21.8525 20.4192C21.8525 23.0511 22.3471 23.581 22.9654 23.581C23.5836 23.581 24.0782 23.0511 24.0782 20.4192C24.0551 18.7678 23.6755 17.1409 22.9654 15.6499C22.2552 17.1409 21.8756 18.7678 21.8525 20.4192Z" fill="black"/>
            <path d="M17.2961 18.1758C15.5297 20.1542 15.5297 20.8607 16.0243 21.267C16.5189 21.6733 17.2078 21.6379 18.9565 19.6772C20.0473 18.431 20.8495 16.9593 21.3058 15.3672C19.7813 16.0013 18.413 16.9597 17.2961 18.1758Z" fill="black"/>
            <path d="M15.2809 13C12.649 13 12.1367 13.4946 12.1367 14.1128C12.1367 14.7311 12.649 15.2257 15.2809 15.2257C16.9323 15.2026 18.5592 14.823 20.0502 14.1128C18.5592 13.4027 16.9323 13.0231 15.2809 13Z" fill="black"/>
            <path d="M24.6074 15.4027C25.075 16.9848 25.8761 18.4484 26.9567 19.6951C28.7231 21.6558 29.4297 21.7088 29.889 21.2848C30.3482 20.8609 30.3836 20.172 28.6172 18.2113C27.5064 16.9882 26.1365 16.0286 24.6074 15.4027Z" fill="black"/>
            <path d="M30.6493 13.0179C28.9921 13.0395 27.3591 13.4191 25.8623 14.1307C27.3591 14.8423 28.9921 15.2219 30.6493 15.2435C33.2812 15.2435 33.7934 14.7489 33.7934 14.1307C33.7934 13.5124 33.2812 13.0179 30.6493 13.0179Z" fill="black"/>
            <path d="M4.93019 14.0778C4.02027 13.9032 3.07878 14.009 2.23031 14.3812C1.38184 14.7534 0.66637 15.3745 0.178569 16.1622C0.0221861 16.54 -0.0328585 16.9522 0.0188785 17.3578C0.0706156 17.7635 0.22734 18.1486 0.473558 18.4751C0.719776 18.8016 1.04696 19.0582 1.42275 19.2195C1.79853 19.3807 2.20991 19.4411 2.6162 19.3947C2.81211 19.395 3.00572 19.3524 3.18343 19.27C3.36114 19.1875 3.51865 19.0671 3.64489 18.9173C3.77114 18.7675 3.86306 18.5919 3.91421 18.4027C3.96537 18.2136 3.97451 18.0156 3.941 17.8226C3.91938 17.581 3.83671 17.3489 3.70076 17.1481C3.5648 16.9472 3.38003 16.7843 3.16378 16.6744C2.98714 16.6744 2.05095 16.6744 2.06862 16.9924C2.06862 15.226 4.29428 15.226 5.60142 15.7029C6.13153 15.8423 6.651 16.0194 7.15585 16.2328C8.33828 16.855 9.67647 17.119 11.0066 16.9924C10.0267 16.5622 9.10168 16.0166 8.25102 15.3673C7.24315 14.7164 6.11322 14.2776 4.93019 14.0778Z" fill="black"/>
            <path d="M40.434 14.0775C39.2448 14.275 38.1087 14.7138 37.0955 15.367C36.2412 16.0202 35.3098 16.566 34.3223 16.992C35.658 17.118 37.0017 16.8542 38.1907 16.2325C38.6895 16.0191 39.2031 15.842 39.7275 15.7026C41.0699 15.2963 43.2603 15.261 43.2603 16.992C43.2603 16.6564 42.3417 16.6034 42.1651 16.6741C41.9488 16.7839 41.7641 16.9469 41.6281 17.1477C41.4922 17.3486 41.4095 17.5807 41.3879 17.8223C41.3511 18.0157 41.3579 18.2149 41.4078 18.4054C41.4577 18.5959 41.5494 18.7729 41.6763 18.9235C41.8032 19.074 41.962 19.1944 42.1413 19.2759C42.3206 19.3573 42.5158 19.3978 42.7127 19.3943C43.119 19.4408 43.5303 19.3804 43.9061 19.2191C44.2819 19.0578 44.6091 18.8013 44.8553 18.4747C45.1015 18.1482 45.2583 17.7631 45.31 17.3575C45.3617 16.9518 45.3067 16.5397 45.1503 16.1618C44.6648 15.3805 43.9551 14.7638 43.1138 14.3919C42.2724 14.0201 41.3386 13.9105 40.434 14.0775Z" fill="black"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M4.44295 2.91832C3.84058 2.96202 3.23568 2.8631 2.7236 2.63713C2.2177 1.92225 2.60604 1.27205 3.23541 0.506004C5.8132 1.52555 8.18896 0.439881 10.7177 0.239835C14.0483 0.0469269 17.3923 0.00878718 20.7279 0.125618C25.9908 0.216572 31.2623 0.228604 36.5074 0.604802C37.0373 0.604463 37.5579 0.69933 38.0203 0.880443C39.0685 1.36347 40.1088 1.4824 41.0389 0.818812C42.0188 1.04713 42.6632 1.35511 42.4638 2.13836C41.843 2.91046 40.7422 2.91304 39.6585 3.00658" fill="black"/>
          </svg>
        </div>
      )}

      <img src={Logo} alt="Delicious Demise logo" />
    </LogoWrapper>
  )
}

export default DelicousLogoWrapper
