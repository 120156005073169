export const logoFade = {
  visible: {
    opacity: 1,
    transition: {
      delay: 0.5,
      duration: 1
    }
  },
  hidden: {
    opacity: 0,
    transition: {
      delay: 0,
      duration: 1
    }
  }
}

export const loaderAnimation = {
  visible: {
    opacity: 1,
    transition: {
      delay: 1,
      duration: 0.5
    }
  },
  hidden: {
    opacity: 0,
    transition: {
      delay: 0,
      duration: 0.5
    }
  }
}

export const loaderTitleAnimation = {
  visible: {
    opacity: 1,
    transition: {
      delay: 1,
      duration: 0.5,
      when: "beforeChildren",
      staggerChildren: 0.3
    }
  },
  hidden: {
    opacity: 0,
    transition: {
      delay: 0,
      duration: 0.5,
      when: "afterChildren",
      staggerChildren: 0.3
    }
  }
}

export const fade = {
  visible: {
    opacity: 1,
    transition: {
      duration: 0.5
    },
  },
  hidden: {
    oapcity: 0,
    transition: {
      duration: 0.5
    },
  }
}

export const setupFade = {
  visible: {
    opacity: 1,
    transition: {
      delay: 0.5,
      duration: 0.3
    }
  },
  hidden: {
    opacity: 0,
    transition: {
      delay: 0,
      duration: 0.3
    }
  }
}

export const registerNowSlide = {
  visible: {
    x: "0%"
  },
  hidden: {
    x: "-100%"
  },
  transition: {
    duration: 1,
    type: "spring",
  }
}

export const slideInOut = {
  animateIn: {
    x: "0%"
  },
  initial: {
    x: "100%"
  },
  exit: {
    x: "-100%"
  },
  transition: {
    duration: 1,
    type: "spring",
  }
}