export const ENTER_PORTAL = "ENTER_PORTAL";
export const COMPLETE_PORTAL = "COMPLETE_PORTAL";
export const COMPLETE_SCENE = "COMPLETE_SCENE";
export const MOVE_TO_SCENE = "MOVE_TO_SCENE";
// export const COMPLETE_CHECKPOINT = "COMPLETE_CHECKPOINT";
// export const COMPLETE_CHALLENGE = "COMPLETE_CHALLENGE";
export const UPDATE_INVENTORY = "UPDATE_INVENTORY";
export const GAME_READY = "GAME_READY";
export const GAME_ERROR = "GAME_ERROR";
export const CLAIM_PRIZE = "CLAIM_PRIZE";
export const BACK_TO_AR = "BACK_TO_AR";