import React from 'react'
import styled from 'styled-components'

import Logo from '../../assets/images/logos/whitlocks-logo.png'

const LogoWrapper = styled.div`
  width: 100%;
  padding: .75em 2em;
  display: grid;
  place-items: center;

  img {
    width: 116px;
    height: auto;
  }
`;

function WhitlockLogoWrapper() {
  return (
    <LogoWrapper className="whitlocks-wrapper">
      <img src={Logo} alt="F.Whitlock logo" />
    </LogoWrapper>
  )
}

export default WhitlockLogoWrapper
