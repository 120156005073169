import React, { useState, useContext, useEffect } from 'react'
import styled from 'styled-components'
import { AnimatePresence, motion } from 'framer-motion'
import { useTimeout } from '@unbnd-operations/react-hooks';
import ReactGA from 'react-ga'
import { fade } from '../../../../animation';

import CardTitle from '../../../../components/typography/CardTitle';
import OverlayWrapper from '../../../../components/layout/OverlayWrapper';
import CircularButton from '../../../../components/buttons/CircularButton';

import InventoryIcon from '../../../../assets/images/circular-btn-icons/inventory.svg'
import ButtonLabel from '../../../../components/misc/ButtonLabel';
import { GameContext } from '../../../../global/GameState';

const ItemFoundWrapper = styled.div`
  pointer-events: auto;

  .overlay-wrapper {
    .inner {
      justify-content: center;
      display: flex;
      flex-direction: column;
    }
  }

  .card-title-wrapper {
    flex: 1 0 30%;
    padding: 0;

    .title {
      font-family: var(--mainFont);
      text-transform: uppercase;
      font-size: 2.425em;
      margin-bottom: 0;
      margin-top: 40px;
    }

    p {
      font-family: var(--copyFont);
      font-style: italic;
      font-size: 1.375em;
      margin-bottom: 1em;
    }
  }

  .item-image-wrapper {
    flex: 1 1 50%;
    position: relative;

    img {
      height: 100%;
      width: auto;
      position: absolute;
      left: 50%;
      top: 50%;
    }
  }

  .button-wrapper {
    text-align: center;
    flex: 1 0 20%;

    .content {
      font-style: italic;
    }

    .button-label-wrapper {
      margin-bottom: 10px;
    }

    svg {
      position: absolute;
      right: 1px;
      top: 1px;
      animation: pulse 2s infinite;
    }
  }

`;

function ItemFound({ item, close }) {
  const [showImage, setShowImage] = useState(false)
  const [itemAdded, setItemAdded] = useState(false)
  const {currentPortal, portals, firstNewItemFound, setFirstNewItemFound} = useContext(GameContext)

  const handleClick = async () => {
    setShowImage(false);
    setFirstNewItemFound(true)

    const firstTO = setTimeout(() => {
      setItemAdded(true);

      const secondTO = setTimeout(() => {
        clearTimeout(secondTO);
        clearTimeout(firstTO);
        close();
      }, 500);
    }, 300)

  }

  useTimeout(() => {
    setShowImage(true);
  }, 1000);

  useEffect(() => {
    ReactGA.event({
      category: 'Game',
      action: 'Item Found',
      label: `${portals[currentPortal].name}-${item.id}`
    });
  }, []);

  return (
    <ItemFoundWrapper
      as={motion.div}
      className="item-found-wrapper fullscreen"
      initial={fade.hidden}
      animate={fade.visible}
      exit={fade.hidden}
      onClick={handleClick}
    >
      <OverlayWrapper>
        <CardTitle>
          <h2 className="title">{item.title}</h2>
          <p>{item.copy}</p>
        </CardTitle>

        <div className="item-image-wrapper">
          <AnimatePresence>
          {showImage && (
            <motion.img
              initial={{
                opacity: 0,
                scale: 0.2,
                x: "-50%",
                y: "-50%",
              }}
              animate={{
                opacity: 1,
                scale: 1,
                x: "-50%",
                y: "-50%",
                transition: {
                  type: "spring",
                  duration: 0.3
                }
              }}
              exit={{
                opacity: 0,
                x: "-50%",
                y: "40%",
                scale: 0.1,
                transition: {
                  duration: 0.3
                }
              }}
              src={item.image}
              alt={item.id}
            />
          )}
          </AnimatePresence>
        </div>

        <div className="button-wrapper f-col f-center">
          <p className='content'>Use item at a later time</p>
          <ButtonLabel>Store in inventory</ButtonLabel>
          <CircularButton size={42} >
            <img src={InventoryIcon} alt="Invetory icon" />

            {!itemAdded && !firstNewItemFound && (
              <svg className='red-circle' width="10" height="10" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M3.53543 0.00195808C5.36753 -0.053027 7.12434 1.05405 7.63824 2.81346C8.115 4.44568 6.97552 5.99093 5.55995 6.93305C4.33546 7.74798 2.76504 7.70925 1.5695 6.85242C0.34816 5.97709 -0.288023 4.48323 0.126466 3.03891C0.590577 1.42167 1.85368 0.0524309 3.53543 0.00195808Z" fill="#C32033"/>
              </svg>
            )}
          </CircularButton>
        </div>

      </OverlayWrapper>
    </ItemFoundWrapper>
  )
}

export default ItemFound
