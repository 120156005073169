import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'

import Header from '../Header/Header';

import dividerImg from './../../../../../assets/images/divider.svg';
import ornateImg from './../../../../../assets/images/ornate.svg';

import faqData from '../../../../../data/faq';

import 'keen-slider/keen-slider.min.css'
import { useKeenSlider } from 'keen-slider/react'

const FAQWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;

  .dots {
    position: absolute;
    top: 75%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .content {
    pointer-events: auto;
    width: 100%;
    height: calc(100% - 100px);
    align-items: center;
    overflow: hidden;
    padding-bottom: 30px;

    .divider {
      width: 40px;
      margin-bottom: 32px;
    }

    .questions {
      width: 90%;
      overflow-y: scroll;
      .question {
        align-items: center;
        text-align: center;
        margin-bottom: 40px;

        h1 {
          font-family: var(--mainFont);
          font-size: 32px;
        }

        p {
          font-size: 16px;
          line-height: 1.3;
        }

        a {
          text-decoration: underline;
          color: var(--primaryColour);
        }
      }
    }
  }

  .footer {
    position: absolute;
    width: 100%;
    height: 60px;
    bottom: 0px;
    justify-content: space-around;
    align-items: center;

    .ornate {
      width: 60px;
    }
  }
`;

function FAQ({ onBackClick }) {
  const [currentSlide, setCurrentSlide] = useState(0);

  const [sliderRef, slider] = useKeenSlider({
    initial: 0,
    slideChanged(s) {
      setCurrentSlide(s.details().relativeSlide)
    },
  });

  useEffect(() => {
    return () => setCurrentSlide(0);
  }, []);

  return (
    <FAQWrapper
      className="fullscreen"
      as={motion.div}
      initial={{ x: "100%" }}
      animate={{ x: "0%" }}
      exit={{ x: "100%" }}
      transition={{ type: "Tween" }}
    >
      <Header onBackClick={onBackClick} title="FAQ's" />

      <div className="content f-col">
        <div className="questions f-col">
          <div className="faq-container">

            {faqData.map((data, index) => (
              <div key={index} className="question">
                <img className="divider" src={dividerImg} />
                <h1>{data.title}</h1>
                <p dangerouslySetInnerHTML={{ __html: data.description }}></p>
              </div>
            ))}

          </div>
        </div>
      </div>

      {/* {slider && (
        <div className="dots">
          {[...Array(slider.details().size).keys()].map((idx) => (
            <button
              key={idx}
              onClick={() => {
                slider.moveToSlideRelative(idx)
              }}
              className={`dot  ${currentSlide === idx ? " active" : ""}`}
            />
          ))}
        </div>
      )} */}



      <div className="footer f-col">
        <img className="ornate" src={ornateImg} />
      </div>
    </FAQWrapper>
  )
}

export default FAQ
