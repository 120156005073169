import React from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion';

import SpeechBubble from './../../../../components/typography/SpeechBubble';
import CircularButton from '../../../../components/buttons/CircularButton'

import tickImg from './../../../../assets/images/circular-btn-icons/tick.svg';
import crossImg from './../../../../assets/images/circular-btn-icons/cross.svg';
import facebookImg from './../../../../assets/images/pause-menu/facebook.svg';

const LeaveConfirmationWrapper = styled.div`
  position: absolute;
  top: -1em;
  left: -1em;
  height: calc(100% + 2em);
  width: calc(100% + 2em);
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.5);

  .speech-bubble-wrapper {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 85vw;

    .speech-bubble-inner {
      padding: 49px 14px 45px;
    }

    .fb-icon {
      position: absolute;
      height: 60px;
      width: 60px;
      transform: translate(-50%, -75%);
    }

    .three-white-dots {
      display: none;
    }

    p {
      marign: 0;
      font-family: var(--mainFont);
      font-size: clamp(22px, 7vw, 28px);
      margin-top: 25px;
      margin-bottom: 5px;
      margin-left: 15px;
      margin-right: 15px;
    }

    .buttons {
      height: 30px;
      display: flex;
      justify-content: center;
      transform: translateY(calc(50%));

      .circular-btn-wrapper {
        margin-left: 10px;
        margin-right: 10px;
      }
    }
  }
`;

function LeaveConfirmation({
  onConfirmClick,
  onCancelClick
}) {
  return (
    <LeaveConfirmationWrapper
      as={motion.div}
      initial={{ opacity: "0" }}
      animate={{ opacity: "1" }}
      exit={{ opacity: "0" }}
      transition={{ type: "Tween" }}
      className='leave-confirmation-wrapper'
    >
      <SpeechBubble tail={false}>
        <img className='fb-icon' src={facebookImg}/>
        <p>Do you relly want to leave<br/>the AR experience?</p>

        <div className='buttons'>
          <CircularButton onClick={onCancelClick} size='60'><img src={crossImg}/></CircularButton>
          <CircularButton onClick={onConfirmClick} size='60'><img src={tickImg}/></CircularButton>
        </div>
      </SpeechBubble>
    </LeaveConfirmationWrapper>
  )
}

export default LeaveConfirmation
