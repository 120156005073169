import PrizeCapImg from "../assets/images/prizes/prize-cap.png";
import PrizeCash4Img from "../assets/images/prizes/prize-cash-4.png";
import PrizeCash11Img from "../assets/images/prizes/prize-cash-11.png";
import PrizeCash16Img from "../assets/images/prizes/prize-cash-16.png";
import PrizeCash24Img from "../assets/images/prizes/prize-cash-24.png";
import PrizeCash49Img from "../assets/images/prizes/prize-cash-49.png";
import PrizeBBQImg from "../assets/images/prizes/prize-chipotle-bbq.png";
import PrizeBeansImg from "../assets/images/prizes/prize-fuego-beans.png";
import PrizeRecipeImg from "../assets/images/prizes/prize-recipe.png";
import PrizeTshirtImg from "../assets/images/prizes/prize-tshirt.png";

import HunderedImg from "../assets/images/prizes/prize-major-100.png"
import TwoHunderedImg from "../assets/images/prizes/prize-major-200.png"
import ThreeHunderedImg from "../assets/images/prizes/prize-major-300.png"
import FourHunderedImg from "../assets/images/prizes/prize-major-400.png"

export default [
  {
    type: "cash",
    value: "$49.73",
    img: PrizeCash49Img,
  },
  {
    type: "cash",
    value: "$24.27",
    img: PrizeCash24Img,
  },
  {
    type: "cash",
    value: "$16.55",
    img: PrizeCash16Img,
  },
  {
    type: "cash",
    value: "$11.45",
    img: PrizeCash11Img,
  },
  {
    type: "cash",
    value: "$4.37",
    img: PrizeCash4Img,
  },
  {
    type: "product",
    value: "chipotle",
    img: PrizeBBQImg,
  },
  {
    type: "product",
    value: "beans",
    img: PrizeBeansImg,
  },
  {
    type: "cap",
    img: PrizeCapImg,
  },
  {
    type: "shirt",
    img: PrizeTshirtImg,
  },
  {
    type: "recipe",
    img: PrizeRecipeImg,
  },
  {
    type: "grand",
    value: "100",
    img: HunderedImg
  },
  {
    type: "grand",
    value: "200",
    img: TwoHunderedImg
  },
  {
    type: "grand",
    value: "300",
    img: ThreeHunderedImg
  },
  {
    type: "grand",
    value: "400",
    img: FourHunderedImg
  },  
];