const phaseOneDuration = 6;
const tMidPhase = 0.6;
const oneSec = 1 / phaseOneDuration;
const seconds = (value) => value * oneSec;

export const prizeDisplay = {
  initial: { visibility: "none", opacity: 0 },
  animate: { opacity: 1 },
};

export const introFrame = {
  animate: {
    opacity: 1,
    transition: {
      duration: .5,
    }
  },
  exit: {
    opacity: 0,
    transition: { duration: 0.5 },
    transitionEnd: {
      display: "none",
    },
  },
};

export const background = {
  animate: {
    opacity: [0, 0, 1, 1, 0],
    transition: {
      duration: phaseOneDuration,
      times: [0, seconds(0.5), seconds(1), tMidPhase - seconds(0.5), tMidPhase],
    },
  },
};

export const titleDisplay = {
  initial: { opacity: 0 },
  animate: {
    opacity: 1,
    transition: {
      delay: 0,
      duration: .5,
    }
  },
  // exit: {
  //   display: "none",
  // },
};

// export const productDisplay = {
//   animate: {
//     opacity: [0, 0, 1, 1, 0],
//     transition: {
//       duration: phaseOneDuration,
//       times: [0, seconds(1.2), seconds(2), tMidPhase - seconds(0.5), tMidPhase],
//     },
//   },
// };

// export const prizeDisplay = {
//   animate: {
//     opacity: [0, 0, 1, 1, 0],
//     transition: {
//       duration: phaseOneDuration,
//       times: [0, tMidPhase, tMidPhase + seconds(0.5), 1 - seconds(0.5), 1],
//     },
//   },
// };

export const productImage = {
  hidden: {
    opacity: 0,
    transition: {
      delay: 0,
      duration: 1,
    },
  },
  visible: {
    opacity: 1,
    transition: {
      delay: 1,
      duration: 1,
    },
  },
};

export const formDisplay = {
  hidden: {
    opacity: 0,
    visibility: "hidden",
    transition: { duration: 0.5 },
  },
  showDelayed: {
    visibility: "visible",
    opacity: 1,
    transition: {
      delay: phaseOneDuration,
      duration: 0.5,
    },
  },
  exit: {
    visibility: "visible",
    opacity: 0,
    transition: { duration: 0.5 },
    transitionEnd: {
      display: "none",
    },
  },
};

export const thanksDisplay = {
  animate: {
    visibility: "visible",
    opacity: 1,
    transition: {
      delay: 1,
      duration: 1,
    },
  },
};
